
import DoublyLinkedList from './DoublyLinkedList';

class Queue extends DoublyLinkedList {

  enqueue(o) {
    return this.push(o);
  };

  dequeue() {
    return this.shift();
  };

  peek() {
    if (this.isEmpty()) {
      throw {
          "name": "EmptyQueueException",
          "message": "You can't peek at an empty queue",
      };
    }
    return this.a.next.value;
  };
};

export default Queue;
