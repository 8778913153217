import Vue from 'vue'
import Disabler from '../base/Disabler'

Vue.use({
  install(Vue, options) {
    let instance;

    Object.defineProperty(Vue.prototype, '$disabler', {
      get() {
        return instance;
      },
    });

    Vue.mixin({
      mounted() {
        const self = this;
        if (self === self.$root) {
          instance = new Disabler(self);
          instance
            .on(Disabler.EVENT_DISABLE, () => {
              this.recalculateLoading();
            })
            .on(Disabler.EVENT_ENABLE, () => {
              this.recalculateLoading();
            });
        }
        this.recalculateLoading();
      },
      methods: {
        recalculateLoading() {
          if (this.$disabler) {
            this.$root.loading = this.$disabler.isActive();
          }
        },
        enable(n = 1) {
          return this.$disabler.enable(n);
        },
        disable(n = 1, spinner = true) {
          return this.$disabler.disable(n, spinner);
        },
        forceEnable() {
          return this.$disabler.forceEnable();
        },
        isAppBusy() {
          return this.$disabler.isActive();
        },
      },
    });
  },
})
