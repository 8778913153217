import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions({
      doCreateUser: 'apiModels/user/post',
      doDeleteUser: 'apiModels/user/delete',
      doLockUser: 'apiModels/user/putLock',
      doUnlockUser: 'apiModels/user/putUnlock',
      doUpdateUser: 'apiModels/user/put',
    }),
    lockUser(user) {
      this.$swal({
        title: '¿Estás segur@?',
        text: 'Bloquerás al usuario y no podrá acceder al sistema',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, ¡adelante!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.doLockUser({
              id: user.id,
              params: {
                confirm: 1,
              },
            })
              .then(() => {
                this.$swal({
                  icon: 'success',
                  title: '¡Bloqueado!',
                  text: 'El usuario está bloqueado y ya no podrá acceder más al sistema',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          }
        })
    },
    unlockUser(user) {
      this.$swal({
        title: '¿Estás segur@?',
        text: 'Desloquerás al usuario y podrá acceder al sistema',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, ¡adelante!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.doUnlockUser({
              id: user.id,
            })
              .then(() => {
                this.$swal({
                  icon: 'success',
                  title: '¡Desbloqueado!',
                  text: 'El usuario está desbloqueado y ya puede acceder más al sistema',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          }
        })
    },
    deleteUser(user) {
      this.$swal({
        title: '¿Estás segur@?',
        text: 'El usuario será borrado definitivamente del sistema',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, ¡adelante!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.doDeleteUser(user.id)
              .then(() => {
                this.$router.go(-1)
                this.$swal({
                  icon: 'success',
                  title: '¡Eliminado!',
                  text: 'El usuario ha sido eliminado',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          }
        })
    },
  },
}
