/* eslint-disable */

class Utils {
  static instance() {
    return new this;
  }

  import(obj, path, value) {
    if (typeof is === 'string') {
      return index(obj, is.split('.'), value);
    } else if (is.length === 1 && value !== undefined) {
      return obj[is[0]] = value;
    } else if (is.length === 0) {
      return obj;
    } else {
      return index(obj[is[0]], is.slice(1), value);
    }
  }

  extract(obj, path, vDefault = undefined) {
    const value = path.split('.').reduce(function(prev, curr) {
      return prev ? prev[curr] : undefined;
    }, obj);

    return value === undefined ? vDefault : value;
  }

  objPathResolver(obj, path) {
    return path.split('.').reduce((prev, curr) => (prev ? prev[curr] : undefined), obj || self)
  }

  serializeToUrl(obj, prefix) {
    var str = [], p;
    for(p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "[" + p + "]" : p, v = obj[p];
        str.push((v !== null && typeof v === "object") ?
          this.serializeToUrl(v, k) :
          window.encodeURIComponent(k) + "=" + window.encodeURIComponent(v));
      }
    }

    return str.join("&");
  }

  objKeyResolver(obj, key) {
    let value;
    const typeOfKey = typeof key;
    if (typeOfKey === 'string') {
      value = this.objPathResolver(obj, key);
    } else if (typeOfKey === 'function') {
      value = key(obj);
    }
    return value;
  }

  arrayColumn(arr, key) {
    let i;
    const l = arr.length;
    const result = [];

    for (i = 0; i < l; i += 1) {
      result.push(this.objKeyResolver(arr[i], key));
    }

    return result;
  }

  arrayKeyBy(arr, key) {
    let i;
    const l = arr.length;
    const result = {};

    for (i = 0; i < l; i += 1) {
      result[key] = arr[i];
    }

    return result;
  }

  arrayDiff(arrA, arrB, comparator, removeOnMatch = false) {
    const result = [];
    let auxB = [];
    let copyB = arrB;
    let found = false;

    for (let i = 0; i < arrA.length; i += 1) {
      found = false;
      auxB = [];

      for (let j = 0; j < copyB.length; j += 1) {
        if (!found && comparator(arrA[i], copyB[j]) === true) {
          found = true;
          if (removeOnMatch === false) {
            auxB.push(copyB[j]);
          }
        } else {
          auxB.push(copyB[j]);
        }
      }

      if (!found) {
        result.push(arrA[i]);
      }

      copyB = [...auxB];
    }

    return result;
  }

  arrayIntersect(arrA, arrB, comparator, removeOnMatch = false) {
    const result = [];
    let auxB = [];
    let copyB = arrB;

    for (let i = 0; i < arrA.length; i += 1) {
      auxB = [];

      for (let j = 0; j < copyB.length; j += 1) {
        if (comparator(arrA[i], copyB[j]) === true) {
          result.push(arrA[i]);
          if (removeOnMatch === false) {
            auxB.push(copyB[j]);
          }
        } else {
          auxB.push(copyB[j]);
        }
      }

      copyB = [...auxB];
      if (copyB.length === 0) {
        break;
      }
    }

    return result;
  }

  openLocationInMaps(lat, lng, label = null) {
    const coords = `${lat},${lng}`;
    const sLabel = label ? `(${label})` : '';

    if (window.device.platform === 'iOS') {
      window.open(`maps://?q=${coords}`, '_system');
    } else if (window.device.platform === 'Android') {
      window.open(`geo:0,0?q=${coords}${sLabel}`, '_system');
    }

    return this;
  }
}

export default Utils;
