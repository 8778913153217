/* eslint-disable no-unused-vars */

import User from '@nollaSdk/models/User'
import mutations from '../core/mutations'
import { ADD_ITEM_TO_LIST, ADD_LIST, SET_ITEM } from '../core/mutation-types'

const state = {
  initialState() {
    return {
      list: [],
      item: null,
    }
  },
  list: [],
  item: null,
}

// actions
const actions = {
  get({ commit }, params) {
    return User.find(params.id, params)
      .then(model => {
        commit(SET_ITEM, model)
        if (model) {
          commit(ADD_ITEM_TO_LIST, model)
        }
        return model
      })
  },
  getByDocNum({ commit }, docNum) {
    return User.findByDocNum(docNum)
      .then(model => {
        commit(SET_ITEM, model)
        if (model) {
          commit(ADD_ITEM_TO_LIST, model)
        }
        return model
      })
  },
  getList({ commit }, params) {
    return User.search(params)
      .then(models => {
        commit(ADD_LIST, models)
        return models
      })
  },
  getProfileImage({ commit }, id) {
    return User.getProfileImageByUserId(id)
  },
  patch({ commit }, payload) {
    return User.patch(payload.id, payload.params)
      .then(model => {
        commit(SET_ITEM, model)
        if (model) {
          commit(ADD_ITEM_TO_LIST, model)
        }
        return model
      })
  },
  postRegister({ commit }, params) {
    return User.postRegister(params)
      .then(model => {
        commit(SET_ITEM, model)
        if (model) {
          commit(ADD_ITEM_TO_LIST, model)
        }
        return model
      })
  },
  post({ commit }, params) {
    return User.post(params)
      .then(model => {
        commit(SET_ITEM, model)
        if (model) {
          commit(ADD_ITEM_TO_LIST, model)
        }
        return model
      })
  },
  postInsurerList({ commit }, payload) {
    return User.postInsurerList(payload.id, payload.params)
      .then(model => {
        commit(SET_ITEM, model)
        if (model) {
          commit(ADD_ITEM_TO_LIST, model)
        }
        return model
      })
  },
  put({ commit }, payload) {
    return User.put(payload.id, payload.params)
      .then(model => {
        commit(SET_ITEM, model)
        if (model) {
          commit(ADD_ITEM_TO_LIST, model)
        }
        return model
      })
  },
  putLock({ commit }, payload) {
    return User.putLock(payload.id, payload.params)
      .then(model => {
        commit(SET_ITEM, model)
        if (model) {
          commit(ADD_ITEM_TO_LIST, model)
        }
        return model
      })
  },
  putUnlock({ commit }, payload) {
    return User.putUnlock(payload.id, payload.params)
      .then(model => {
        commit(SET_ITEM, model)
        if (model) {
          commit(ADD_ITEM_TO_LIST, model)
        }
        return model
      })
  },
}

export default {
  actions,
  mutations,
  namespaced: true,
  state,
}
