/* eslint-disable */

import ApiModel from '../base/ApiModel';

import Category from './Category';
import DocumentApiModel from '../base/DocumentApiModel';
import Email from './Email';
import ModelDataProvider from '../data/ModelDataProvider';
import moment from 'moment-timezone';
import Phone from './Phone';
import Place from './Place';
import Rating from './Rating';
import Role from './Role';
import UserDevice from './UserDevice';
import UserProfileImage from './UserProfileImage';
import UserStat from './UserStat';

let SESSION_USER_ID = null;

class User extends ApiModel {

  static get SESSION_USER_ID() {
    return SESSION_USER_ID;
  }

  constructor(data = null) {
    super(data);

    this.id = '';
    this.name = '';
    this.last_name = '';
    this.email = '';
    this.doc_num = '';
    this.username = '';

    this.phones = [];
    this.emails = [];
    this.roles = [];

    if (data !== null) {
      this.populate(data);
    }
  }

  static get className() {
    return 'User';
  }

  static get propertiesLabels() {
    return {};
  }

  get completeName() {
    const fullName = this.fullName
    let name = this.username;

    if (fullName) {
      name += ` (${fullName})`
    }

    return name
  }

  get fullName() {
    let name = this.name;
    const surnames = this.surname1;
    if (surnames) {
      name += ` ${surnames}`;
    }

    return name;
  }

  hasStatus(code) {
    return this.status.hasCode(code)
  }

  getLabel(prop) {
    let label = super.getLabel(prop);
    if (label === undefined) {
      label = this.location.getLabel(prop);
    }
    return label;
  }

  getFullName() {
    return this.fullName;
  }

  getShowName() {
    return this.name ? this.name : this.username;
  }

  static search(params) {
    return new Promise((resolve, reject) => {
      this.api()
      .call(['getUsers', params])
      .then((response) => {
        const models = this.populateCollectionFromResponse(response.data);
        resolve(models);
        return models;
      })
      .catch((error) => {
        reject(error);
      });
    });
  }

  static postRegister(data) {
    return new Promise((resolve, reject) => {
      this.api()
      .call('postRegisterUser', data, false)
      .then((response) => {
        let model = this._getModelPopulatedFromResponse(response.data, this)
        SESSION_USER_ID = model.id;
        resolve(model);
      })
      .catch((error) => {
        reject(error);
      });
    });
  }

  static putPassword(id, params) {
    return new Promise((resolve, reject) => {
      this.api()
      .call(['putUserPassword', { id }], params)
      .then((response) => {
        let model = this._getModelPopulatedFromResponse(response.data, this)
        resolve(model);
      })
      .catch((error) => {
        reject(error);
      });
    });
  }

  static create(data) {
    return new Promise((resolve, reject) => {
      this.api()
      .call(this.createUrl(), data, false)
      .then((response) => {
        let model = this._getModelPopulatedFromResponse(response.data, this)
        SESSION_USER_ID = model.id;
        resolve(model);
      })
      .catch((error) => {
        reject(error);
      });
    });
  }

  static find(id = this.SESSION_USER_ID) {
    return new Promise((resolve, reject) => {
      super.find(id)
      .then((model) => {
        if (this.SESSION_USER_ID === null && id === null) {
          SESSION_USER_ID = model.id;
        }
        resolve(model);
      })
      .catch((error) => {
        reject(error);
      });
    });
  }

  getProfileImage() {
    return this.constructor.getProfileImageByUserId(this.id);
  }

  static getProfileImageByUserId(id) {
    return new Promise((resolve, reject) => {
      this.api()
      .call(['getUserProfileImage', { id }])
      .then((response) => {
        const model = UserProfileImage._getModelPopulatedFromResponse(response.data);
        resolve(model);
        return model;
      })
      .catch((error) => {
        reject(error);
      });
    });
  }

  static setSessionUserId(id) {
    SESSION_USER_ID = id;
  }

  static removeSessionUserId() {
    SESSION_USER_ID = null;
  }

  static getFindUrl(id) {
    let endpoint = 'sessionUserInfo';
    if (id !== this.SESSION_USER_ID) {
      endpoint = ['userInfo', {id: id}];
    }
    return endpoint;
  }

  static getSearchUrl() {
    return 'getUsers';
  }

  static createUrl() {
    return 'postUser';
  }

  static updateUrl(id) {
    return ['putUser', { id }];
  }

  static patchUrl(id) {
    return ['patchUser', { id }];
  }

  static putUrl(id) {
    return ['putUser', { id }];
  }

  static get relatedProps() {
    return {
      emails: Email,
      main_user_device: UserDevice,
      phones: Phone,
      placesAsAuthor: Place,
      profile_image: UserProfileImage,
      ratingsAsUser: Rating,
      roles: Role,
      status: Category,
      user_stat: UserStat,
    };
  }

  static getPlaceAsAuthorDataProvider() {
    return new ModelDataProvider({
      endpoint: 'getUserPlaces',
      model: Place,
    });
  }

  static getRatingAsUserDataProvider() {
    return new ModelDataProvider({
      endpoint: 'getUserRatings',
      model: Rating,
    });
  }

  static postProfileImage(id, params) {
    return new Promise((resolve, reject) => {
      this.api()
      .call(['postUserProfileImage', { id }], params)
      .then((response) => {
        let model = this._getModelPopulatedFromResponse(response.data, this)
        resolve(model);
      })
      .catch((error) => {
        reject(error);
      });
    });
  }

  findPlaceAsAuthor(params = {}, refresh = false) {
    return this.findRelateds('placesAsAuthor', Place, [
      'getUserPlaces', {
        id: this.id,
        ...params,
      }
    ], refresh);
  }

  findRatingsAsUser(params = {}, refresh = false) {
    return this.findRelateds('ratingsAsUser', Rating, [
      'getUserRatings', {
        id: this.id,
        ...params,
      }
    ], refresh);
  }

  updatePassword(params) {
    return this.constructor.putPassword(this.id, params)
    .then((model) => {
      this.populateFromModel(model);
      return model;
    });
  }

  updateProfileImage(params) {
    return this.constructor.postProfileImage(this.id, params)
    .then((model) => {
      this.populateFromModel(model);
      return model;
    });
  }

  static putLock(id, params) {
    return new Promise((resolve, reject) => {
      this.api()
      .call(['putUserLock', { id }], params)
      .then((response) => {
        const model = this._getModelPopulatedFromResponse(response.data, this);
        resolve(model);
        return model;
      })
      .catch((error) => {
        reject(error);
      });
    });
  };

  static putUnlock(id, params) {
    return new Promise((resolve, reject) => {
      this.api()
      .call(['putUserUnlock', { id }], params)
      .then((response) => {
        const model = this._getModelPopulatedFromResponse(response.data, this);
        resolve(model);
        return model;
      })
      .catch((error) => {
        reject(error);
      });
    });
  };
};

export default User;
