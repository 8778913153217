import Domain from '@nollaSdk/models/Domain'
import { SET_ITEM } from '../core/mutation-types'
import mutations from '../core/mutations'

const state = {
  initialState() {
    return {
      list: [],
      item: null,
    }
  },
  list: [],
  item: null,
}

// getters
const getters = {}

// actions
const actions = {
  get({ commit }) {
    return Domain.find()
      .then(model => {
        commit(SET_ITEM, model)
        return model
      })
  },
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
