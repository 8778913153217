import contactStateMixin from './contactStateMixin'

export default {
  mixins: [contactStateMixin],
  data() {
    return {
      contactId: this.$route.params.contactId,
    }
  },
  mounted() {
    this.loadContact(this.contactId)
  },
}
