/* eslint-disable */

import ApiModel from '../base/ApiModel';

import Category from './Category';
import Place from './Place';
import User from './User';

class Rating extends ApiModel {

  static get className() {
    return 'Rating';
  }

  constructor(data = null) {
    super(data);

    this.id = '';
    this.comment = '';
    this.created_at = '';
    this.place_id = '';
    this.status_id = '';
    this.user_id = '';
    this.value = '';

    if (data !== null) {
      this.populate(data);
    }
  }

  hasStatus(code) {
    return this.status.hasCode(code)
  }

  static get propertiesLabels() {
    return {};
  }

  static deleteUrl(id) {
    return ['deletRating', { id }];
  }

  static getSearchUrl() {
    return 'getRatings';
  }

  static getFindUrl(id) {
    return ['getRating', { id }];
  }

  static createUrl() {
    return 'postRating';
  }

  static deleteUrl(id) {
    return ['deleteRating', { id }];
  }

  static putUrl(id) {
    return ['putRating', { id }];
  }

  static updateUrl(id) {
    return ['putRating', { id }];
  }

  static get relatedProps() {
    return {
      place: Place,
      status: Category,
      user: User,
    };
  }

  static putPublish(id, params) {
    return new Promise((resolve, reject) => {
      this.api()
      .call(['putRatingPublish', { id }], params)
      .then((response) => {
        const model = this._getModelPopulatedFromResponse(response.data, this);
        resolve(model);
        return model;
      })
      .catch((error) => {
        reject(error);
      });
    });
  };

  static putRemove(id, params) {
    return new Promise((resolve, reject) => {
      this.api()
      .call(['putRatingRemove', { id }], params)
      .then((response) => {
        const model = this._getModelPopulatedFromResponse(response.data, this);
        resolve(model);
        return model;
      })
      .catch((error) => {
        reject(error);
      });
    });
  };

}

export default Rating;
