/* eslint-disable */

import moment from 'moment-timezone';

import Model from './Model';
import ModelDataProvider from '../data/ModelDataProvider';
import api from '../core/api';

const configCache = {};

class ApiModel extends Model {
  constructor(data = null) {
    super(data);
    this.constructor._init();
  }

  static api() {
    return api
  }

  populate(obj, exclude = []) {
    super.populate(obj, exclude);
    for (const prop of this.constructor._relatedProps) {
      if (this.hasOwnProperty(prop) && this[prop] && obj.hasOwnProperty(prop) && obj[prop] && !Array.isArray(this[prop])) {
        this[prop].populate(obj[prop], exclude);
      }
    }

    return this;
  }

  populateFromModel(model, exclude = []) {
    super.populate(model, exclude);
    for (let prop of this.constructor._relatedProps) {
      if (model.hasOwnProperty(prop) && model[prop]) {
        if (!this.hasOwnProperty(prop) || !this[prop] || Array.isArray(model[prop])) {
          this[prop] = model[prop];
        } else {
          this[prop].populate(model[prop], exclude);
        }
      }
    }

    return this;
  }

  populateFromResponse(responseData, exclude = []) {
    super.populate(responseData, exclude);
    this.populateRelateds(responseData);
    this.clearErrors();

    return this;
  }

  populateRelateds(responseData) {
    for (let prop of this.constructor._relatedProps) {
      let related = responseData[prop];
      let data;
      if (related === undefined || related === null) {
        data = related;
      } else {
        data = related.data;
      }
      this.populateRelated(prop, data);
    }

    return this;
  }

  populateRelated(prop, data) {
    let classConstructor = this.getRelatedClassConstructor(prop, data);
    if (data !== undefined) {
      if (Array.isArray(data)) {
        this[prop] = classConstructor.populateCollectionFromResponse(data);
      } else if (data === null) {
        this[prop] = null;
      } else {
        this[prop] = classConstructor._getModelPopulatedFromResponse(data);
      }
    }

    return this;
  }

  hasRelateds(related) {
    return this[related] !== undefined && this[related].length > 0;
  }

  getRelatedClassConstructor(prop, data) {
    return this.constructor.relatedProps[prop];
  }

  getSaveObject() {
    let _data = {};
    let excludedProps = this.constructor._excludedProps;
    for (let prop in this) {
      if (this.hasOwnProperty(prop) && excludedProps.indexOf(prop) === -1) {
        _data[prop] = this[prop];
      }
    }
    _data["_ucu"] = moment().format('x');
    return _data;
  }

  isNew() {
    return this.id === undefined || this.id === null || this.id === "";
  }

  save() {
    return new Promise((resolve, reject) => {
      let isNew = this.isNew();
      this.constructor.api()
      .call(isNew === true ? this.constructor.createUrl() : this.constructor.updateUrl(this.id), this.getSaveObject())
      .then((response) => {
        this.clearErrors();
        if (isNew === true) {
          this.id = response.data.id;
        }
        resolve(response);
      })
      .catch((error) => {
        let errors = $app.utils.extract(error, 'response.data.errors');
        if (errors !== undefined) {
          this._errors = errors;
          reject(false);
        } else {
          reject(error);
        }
      })
    })
  }

  delete() {
    return new Promise((resolve, reject) => {
      this.constructor.api()
      .call(this.constructor.deleteUrl(this.id))
      .then((response) => {
        this.clearErrors();
        resolve(response);
      })
      .catch((error) => {
        let errors = $app.utils.extract(error, 'response.data.errors');
        if (errors !== undefined) {
          this._errors = errors;
          reject(false);
        } else {
          reject(error);
        }
      })
    })
  }

  refresh() {
    return new Promise((resolve, reject) => {
      let endpoint = this.constructor.getFindUrl(this.id);
      this.constructor.api()
      .call(endpoint)
      .then((response) => {
        resolve(this.populateFromResponse(response.data));
      })
      .catch((error) => {
        reject(error);
      });
    });
  }

  findRelateds(prop, classDef, endpoint, refresh = false) {
    return new Promise((resolve, reject) => {
      if (refresh === false && this[prop]) {
        resolve(this[prop]);
      } else {
        this.constructor.api()
        .call(endpoint)
        .then((response) => {
          this.populateRelated(prop, $app.utils.extract(response, 'data'));
          resolve(this[prop]);
        })
        .catch((error) => {
          reject(error);
        })
      }
    })
  }

  static search(params, auth = true) {
    return new Promise((resolve, reject) => {
      let endpoint = this.getSearchUrl(params);
      this.api()
      .call([endpoint, params], {}, auth)
      .then((response) => {
        const models = this.populateCollectionFromResponse(response.data);
        resolve(models);
        return models;
      })
      .catch((error) => {
        reject(error);
      });
    });
  };

  static delete(id, params) {
    return new Promise((resolve, reject) => {
      let endpoint = this.deleteUrl(id);
      this.api()
      .call(endpoint, params)
      .then((response) => {
        resolve(true);
        return true;
      })
      .catch((error) => {
        reject(error);
      });
    });
  };

  static patch(id, params) {
    return new Promise((resolve, reject) => {
      let endpoint = this.patchUrl(id);
      this.api()
      .call(endpoint, params)
      .then((response) => {
        const model = this._getModelPopulatedFromResponse(response.data, this);
        resolve(model);
        return model;
      })
      .catch((error) => {
        reject(error);
      });
    });
  };

  static post(params) {
    return new Promise((resolve, reject) => {
      let endpoint = this.createUrl();
      this.api()
      .call(endpoint, params)
      .then((response) => {
        const model = this._getModelPopulatedFromResponse(response.data, this);
        resolve(model);
        return model;
      })
      .catch((error) => {
        reject(error);
      });
    });
  };

  static put(id, params) {
    return new Promise((resolve, reject) => {
      let endpoint = this.putUrl(id);
      this.api()
      .call(endpoint, params)
      .then((response) => {
        const model = this._getModelPopulatedFromResponse(response.data, this);
        resolve(model);
        return model;
      })
      .catch((error) => {
        reject(error);
      });
    });
  };

  static find(id, params = null) {
    return new Promise((resolve, reject) => {
      let endpoint = this.getFindUrl(id);

      if (params) {
        endpoint[1] = {id, ...params};
      }

      this.api()
      .call(endpoint)
      .then((response) => {
        const model = this._getModelPopulatedFromResponse(response.data, this);
        resolve(model);
        return model;
      })
      .catch((error) => {
        reject(error);
      });
    });
  };

  static populateCollectionFromResponse(data) {
    let i;
    const collection = [];
    for (i = 0; i < data.length; i += 1) {
      collection.push(this._getModelPopulatedFromResponse(data[i]));
    }
    return collection;
  };

  static _getModelPopulatedFromResponse(data) {
    let model = new this(data)
    model.populateFromResponse(data)
    return model
  };

  static _init() {
    const className = this._className
    if (configCache[className] === undefined) {
      let prop
      let value = []
      configCache[className] = {}
      for (prop in this.relatedProps) {
        value.push(prop)
      }
      configCache[className].relatedPropsNames = value
      configCache[className].excludedProps = this.excludedProps.concat(value)
    }
  }

  static apiItem(endpoint, data = {}, needAuth = true, fullResponse = false, upload = false, connection = null) {
    return this.api()
    .call(endpoint, data, needAuth, fullResponse, upload, connection)
    .then((response) => {
      const model = this._getModelPopulatedFromResponse(response.data, this);
      return model;
    });
  };

  static apiCollection(endpoint, data = {}, needAuth = true, fullResponse = false, upload = false, connection = null) {
    return this.api()
    .call(endpoint, data, needAuth, fullResponse, upload, connection)
    .then((response) => {
      const models = this.populateCollectionFromResponse(response.data);
      return models;
    });
  };

  static getDataProvider(options = {}) {
    return new ModelDataProvider({
      endpoint: this.getSearchUrl(),
      model: this,
      ...options,
    });
  }

  static get _relatedProps() {
    return configCache[this._className].relatedPropsNames
  }

  static get _excludedProps() {
    return configCache[this._className].excludedProps
  }

  static get relatedProps() {
    return []
  }

  static getSearchUrl() {
    return '';
  };

  static getFindUrl(id) {
    return [];
  };

  static createUrl() {
    return '';
  };

  static patchUrl(id) {
    return '';
  };

  static putUrl(id) {
    return '';
  };

  static updateUrl() {
    return '';
  };

  static deleteUrl() {
    return '';
  };
};

export default ApiModel;
