<template>
  <b-form autocomplete="chrome-off">
    <b-row>
      <b-col
        cols="12"
        xl="8"
        lg="6"
      >
        <b-form-group
          label="Usuario"
          label-for="user-form-username"
        >
          <b-form-input
            id="user-form-username"
            v-model="form.username"
            name="user-form-username"
            placeholder="(e.g. alamalajambaje)"
            autocomplete="chrome-off"
            required
            @input="onInput"
          />
        </b-form-group>

        <b-form-group
          label="Email"
          label-for="user-form-email"
        >
          <b-form-input
            id="user-form-email"
            v-model="form.email"
            name="user-form-email"
            placeholder="(e.g. alamalajambaje@gmail.com)"
            autocomplete="chrome-off"
            required
            @input="onInput"
          />
        </b-form-group>

        <b-form-group
          label="Nombre"
          label-for="user-form-name"
        >
          <b-form-input
            id="user-form-name"
            v-model="form.name"
            name="user-form-name"
            placeholder="(e.g. Pepe)"
            autocomplete="chrome-off"
            required
            @input="onInput"
          />
        </b-form-group>

        <b-form-group
          label="Apellidos"
          label-for="user-form-last-name"
        >
          <b-form-input
            id="user-form-last-name"
            v-model="form.last_name"
            name="user-form-last-name"
            placeholder="(e.g. Lorente)"
            autocomplete="chrome-off"
            required
            @input="onInput"
          />
        </b-form-group>

        <b-form-group
          label="Roles"
          label-for="user-form-role-ids"
        >
          <role-selector
            id="user-form-role-ids"
            v-model="form.roles_ids"
            :multiple="true"
            placeholder="Selecciona roles"
            @input="onInput"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import roleSelector from './roleSelector.vue'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    roleSelector,
  },
  props: {
    form: {
      type: Object,
      default: () => ({
        username: '',
        email: '',
        name: '',
        last_name: '',
        roles_ids: [],
      }),
    },
  },
  methods: {
    onInput() {
      this.$emit('input', this.form)
    },
  },
}
</script>
