<template>
  <section class="users-view-content">
    <b-row
      v-if="user"
      class="invoice-preview"
    >
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <user-view-user-info-card :user="user" />
        <user-view-place-published :user="user" />
        <user-view-rating-published :user="user" />
      </b-col>

      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <b-button
            v-if="user.hasStatus(['active'])"
            variant="primary"
            class="mb-75"
            block
            @click="onLock"
          >
            <feather-icon
              icon="LockIcon"
              class="mr-50"
            />
            Bloquear
          </b-button>

          <b-button
            v-if="user.hasStatus(['locked'])"
            variant="primary"
            class="mb-75"
            block
            @click="onUnlock"
          >
            <feather-icon
              icon="UnlockIcon"
              class="mr-50"
            />
            Desbloquear
          </b-button>

          <b-button
            variant="outline-secondary"
            class="mb-75"
            block
            @click="onEdit"
          >
            <feather-icon
              icon="Edit2Icon"
              class="mr-50"
            />
            Editar
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import {
  BRow, BCol, BCard, BCardBody, BCardText, BButton, BAlert, BLink, BBadge,
} from 'bootstrap-vue'
import userActions from './mixins/userActions'
import userModelMixin from './mixins/userModelMixin'
import userViewUserInfoCard from './userViewUserInfoCard.vue'
import userViewPlacePublished from './userViewPlacePublished.vue'
import userViewRatingPublished from './userViewRatingPublished.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BBadge,
    userViewUserInfoCard,
    userViewPlacePublished,
    userViewRatingPublished,
  },
  mixins: [userActions, userModelMixin],
  methods: {
    onLock() {
      this.lockUser(this.user)
    },
    onUnlock() {
      this.unlockUser(this.user)
    },
    onEdit() {
      this.$router.push({
        name: 'users-update',
        params: {
          placeId: this.user.id,
        },
      })
    },
  },
}
</script>

<style lang="scss">
.users-view-content {
  .user-view-uplodaded {
    color: #353535;
  }

  .user-main-image {
    width: 100%;
    height: 45vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .user-view-gallery {
    margin-top: 15px;
  }

  .user-description-container {
    margin-top: 50px;
  }

  .user-view-map-card {
    height: calc(100% - 2rem);

    @media (max-width: 992px) {
      min-height: 350px;
    }

    .user-view-map-card-container {
      flex: 1;
    }
  }

  .vue-star-rating {
    .vue-star-rating-rating-text {
      order: 1;
      margin-left: 0;
      margin-right: 7px;
    }

    .vue-star-rating-star {
      display: flex;
      order: 2;
    }
  }
}
</style>
