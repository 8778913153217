import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions({
      doCreatePlace: 'apiModels/place/post',
      doDeletePlace: 'apiModels/place/delete',
      doPublishPlace: 'apiModels/place/putPublish',
      doRemovePlace: 'apiModels/place/putRemove',
      doUpdatePlace: 'apiModels/place/put',
    }),
    publishPlace(place) {
      this.$swal({
        title: '¿Estás segur@?',
        text: 'Publicarás el mosaico y será visible en el mapa general',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, ¡adelante!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.doPublishPlace({
              id: place.id,
            })
              .then(() => {
                this.$swal({
                  icon: 'success',
                  title: '¡Publicado!',
                  text: 'El mosaico ya está disponible en la app y la web',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          }
        })
    },
    removePlace(place) {
      this.$swal({
        title: '¿Estás segur@?',
        text: 'El mosaico se archivará y no estará disponible ni en la app, ni en la web. Escribe una razón que darle al usuario para rechazar el mosaico.',
        input: 'textarea',
        inputAttributes: {
          autocapitalize: 'off',
          rows: 3,
          autocomplete: 'chrome-off',
          style: 'resize: none; margin-top: 20px;',
          max: 500,
        },
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, ¡adelante!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          console.log(result)
          if (result.value) {
            this.doRemovePlace({
              id: place.id,
              params: {
                reason: result.value,
              },
            })
              .then(() => {
                this.$swal({
                  icon: 'success',
                  title: '¡Archivado!',
                  text: 'El mosaico ha sido archivado',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          } else {
            this.$swal({
              icon: 'error',
              title: '¡Mosaico NO archivado!',
              text: 'Debes dar una razón por la cual archivar el mosaico',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            })
          }
        })
    },
    deletePlace(place) {
      this.$swal({
        title: '¿Estás segur@?',
        text: 'El mosaico será borrado definitivamente del sistema',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, ¡adelante!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.doDeletePlace(place.id)
              .then(() => {
                this.$router.go(-1)
                this.$swal({
                  icon: 'success',
                  title: '¡Eliminado!',
                  text: 'El mosaico ha sido eliminado',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          }
        })
    },
  },
}
