/* eslint-disable */

import ApiModel from '../base/ApiModel'
import Selection from './Selection'

class Option extends ApiModel {

  constructor(data = null) {
    super(data)

    this.id = ''
    this.code = ''
    this.statement = ''

    this.selections = []
    this._selected = false

    if (data !== null) {
      this.populate(data)
    }
  }

  static get className() {
    return 'Option';
  }

  init() {
    this._selected = false
    return this
  }

  isSelected() {
    return this._selected
  }

  select() {
    this._selected = true
    return this
  }

  unselect() {
    this._selected = false
    return this
  }

  static findPollableSelections(pollable, refresh = false) {
    return findRelateds('selections', Selection, [
      'getSelections'
      , {
          option_id: this.id
          , pollable_id: pollable.id
          , pollable_type: pollable.type
      }
    ], refresh)
  }

  static get relatedProps() {
    return {
      selections: Selection
    }
  }
}

export default Option
