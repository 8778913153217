/* eslint-disable */

import ApiModel from '../base/ApiModel';


class Contact extends ApiModel {

  constructor(data = null) {
    super(data);

    this.id = '';
    this.name = '';
    this.email = '';
    this.type_id = '';
    this.user_id = '';
    this.subject = '';
    this.message = '';

    if (data !== null) {
      this.populate(data);
    }
  }

  static get className() {
    return 'Contact';
  }

  static get propertiesLabels() {
    return {};
  }

  static createUrl() {
    return 'postContact';
  }

  static deleteUrl(id) {
    return ['deleteContact', { id }];
  }


  static getSearchUrl() {
    return 'getContacts';
  }

  static getFindUrl(id) {
    return ['getContact', { id }];
  }
}

export default Contact;
