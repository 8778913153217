<template>
  <section class="user-update-content">
    <b-row
      v-if="user"
      class="user-update"
    >
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-overlay
          :show="isOverlayActive"
          rounded="sm"
        >
          <b-card
            class="user-update-data"
            title="Datos"
          >
            <user-form :form="form" />
          </b-card>
        </b-overlay>
      </b-col>

      <b-col
        cols="12"
        xl="3"
        md="4"
        class="user-update-actions"
      >
        <b-card>
          <b-button
            variant="primary"
            class="mb-75"
            block
            @click="onSave"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            variant="outline-secondary"
            class="mb-75"
            block
            @click="onBack"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              class="mr-50"
            />
            Volver
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BButton,
} from 'bootstrap-vue'
import overlayDisablerMixin from '@/mixins/overlayDisablerMixin'
import userModelMixin from './mixins/userModelMixin'
import userForm from './userForm.vue'
import userActions from './mixins/userActions'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    userForm,
  },
  mixins: [userModelMixin, userActions, overlayDisablerMixin],
  data() {
    return {
      form: {
        username: '',
        email: '',
        name: '',
        last_name: '',
        roles_ids: [],
      },
    }
  },
  methods: {
    onSave() {
      if (this.isAppBusy()) {
        return
      }

      this.disable()

      this.doUpdateUser({
        id: this.user.id,
        params: this.populateSaveObjectWithForm(),
      })
        .then(() => {
          this.$router.go(-1)

          this.$swal({
            icon: 'success',
            title: '¡Modificado!',
            text: 'El usuario se ha modificado correctamente',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(error => {
          console.error(error)

          this.$swal({
            icon: 'danger',
            title: '¡Error!',
            text: 'Ha ocurrido un error al modificar el usuario',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
        .finally(() => {
          this.enable()
        })
    },
    onBack() {
      this.$router.go(-1)
    },
    afterFindUser() {
      this.populateFormWithUser()
    },
    populateFormWithUser() {
      this.form.username = this.user.username
      this.form.email = this.$sdkUtils.extract(this.user, 'emails.0.email')
      this.form.name = this.user.name
      this.form.last_name = this.user.last_name
      this.form.roles_ids = this.$sdkUtils.arrayColumn(this.user.roles, 'id')
    },
    populateSaveObjectWithForm() {
      return {
        username: this.form.username,
        emails: [
          {
            email: this.form.email,
          },
        ],
        name: this.form.name,
        last_name: this.form.last_name,
        roles_ids: this.form.roles_ids,
      }
    },
  },
}
</script>
