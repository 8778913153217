<template>
  <div
    id="place-map-view"
    class="place-map-view"
  />
</template>

<script>
import 'mapbox-gl/dist/mapbox-gl.css'

const mapboxgl = require('mapbox-gl')

export default {
  props: {
    coordinates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mapDataSourceId: 'coord-location',
      zoom: 10,
    }
  },
  watch: {
    coordinates() {
      if (this.map) {
        this.resetMapState()
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.createMap()
    }, 200)
  },
  destroyed() {
    this.destroyMap()
  },
  methods: {
    createMap() {
      mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN

      this.map = new mapboxgl.Map({
        container: 'place-map-view',
        style: process.env.VUE_APP_MAPBOX_STYLE,
        center: this.coordinates,
        zoom: this.zoom,
      })

      this.map.boxZoom.disable()
      this.map.dragRotate.disable()
      this.map.touchZoomRotate.disable()

      this.addMapEvents()
    },
    addMapEvents() {
      this.map.off('load').on('load', () => {
        this.preparePlaceMarker()
      })
    },
    preparePlaceMarker() {
      this.map.addSource(this.mapDataSourceId, {
        type: 'geojson',
        data: this.getPlaceGeoJSON(),
      })

      this.map.addLayer({
        id: 'unclustered-point',
        type: 'symbol',
        source: this.mapDataSourceId,
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': 'marker',
          'icon-size': 1,
          'icon-anchor': 'bottom',
        },
      })
    },
    resetMapState() {
      this.setMapMarker()
      this.setMapPlace()
    },
    setMapPlace() {
      this.map.flyTo({
        center: this.coordinates,
        zoom: this.zoom,
      })
    },
    setMapMarker() {
      this.map.getSource(this.mapDataSourceId).setData(this.getPlaceGeoJSON())
    },
    getPlaceGeoJSON() {
      const collection = []

      collection.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: this.coordinates,
        },
      })

      return {
        type: 'FeatureCollection',
        features: collection,
      }
    },
    destroyMap() {
      if (this.map) {
        this.map.remove()
        this.map = null
      }
    },
  },
}
</script>

<style lang="scss">
.place-map-view {
  width: 100%;
  height: 100%;
}
</style>
