/* eslint-disable */

import DocumentApiModel from '../base/DocumentApiModel';

class PlaceImage extends DocumentApiModel {

  static get className() {
    return 'PlaceImage';
  }

  static createUrl(fileableId) {
    return ['postPlaceImage', { fileableId }]
  }

  static deleteUrl(fileableId, id) {
    return ['deletePlaceImage', { fileableId, id }]
  }

  getDownLoadUrl() {
    return this.constructor.api().getUrl(['getPlaceImageDownload', {
      fileableId: this.fileableId,
      id: this.id
    }], true);
  }
}

export default PlaceImage;
