import userStateMixin from './userStateMixin'

export default {
  mixins: [userStateMixin],
  data() {
    return {
      userId: this.$route.params.userId,
    }
  },
  mounted() {
    this.loadUser(this.userId)
  },
}
