export default [
  {
    title: 'Blog',
    route: 'access-control',
    icon: 'BookOpenIcon',
    children: [
      {
        title: 'Entradas',
        route: null,
      },
      {
        title: 'Crear',
        route: null,
      },
    ],
  },
]
