<template>
  <div v-if="user">
    <card-model-list-table
      ref="resultsTable"
      :model-fields="modelFields"
      :default-fields="defaultFields"
      :data-provider="dataProvider"
      :query-composer="queryComposer"
      title="Valoraciones de mosaicos"
      @row-clicked="onRowClicked"
    >
      <template #cell(place)="data">
        <place-link
          :place="data.value"
        />
      </template>

      <template #cell(value)="data">
        <star-rating
          v-model="data.value"
          read-only
          :increment="0.01"
          :round-start-rating="false"
          :star-size="18"
          :show-rating="data.value > 0"
          :padding="2"
          class="mr-lg-2"
        />
      </template>

      <template #cell(status)="data">
        <b-badge :variant="statusBadges[data.value.code]">
          {{ data.value.name }}
        </b-badge>
      </template>
    </card-model-list-table>
  </div>
</template>

<script>
import CardModelListTable from '@/components/CardModelListTable.vue'
import {
  BBadge,
} from 'bootstrap-vue'
import StarRating from 'vue-star-rating'
import Rating from '@nollaSdk/models/Rating'
import placeLink from '@/views/places/placeLink.vue'
import User from '@nollaSdk/models/User'

export default {
  components: {
    BBadge,
    CardModelListTable,
    StarRating,
    placeLink,
  },
  props: {
    user: {
      type: User,
      required: true,
    },
  },
  data() {
    return {
      modes: ['multi', 'single', 'range'],
      modelFields: [
        {
          key: 'place',
          label: 'Mosaico',
          sortable: true,
          sortKey: 'place.name',
        },
        {
          key: 'value',
          label: 'Valoración',
          sortable: true,
        },
        {
          key: 'comment',
          label: 'Comentario',
        },
        {
          key: 'status',
          label: 'Estado',
          sortable: true,
          sortKey: 'status.code',
        },
      ],
      defaultFields: [
        'place',
        'value',
        'comment',
        'status',
      ],
      queryComposer: () => this.composeQuery(),
      statusBadges: {
        pendiente: 'light-warning', publicado: 'light-success', eliminado: 'light-danger',
      },
      dataProvider: Rating.getDataProvider({
        pagination: {
          pageSize: 5,
        },
        preserve: false,
      }),
    }
  },
  methods: {
    onRowClicked(item) {
      this.$router.push({
        name: 'places-ratings-view',
        params: {
          ratingId: item.id,
        },
      })
    },
    composeQuery() {
      const data = {
        include: 'place',
        user_id: this.user.id,
      }

      return data
    },
    doSearch() {
      this.$refs.resultsTable.refreshTable()
    },
    onSearchFormInput() {
      clearTimeout(this.searchFormTimeout)

      this.searchFormTimeout = setTimeout(() => {
        this.doSearch()
      }, 500)
    },
  },
}
</script>

<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}

</style>
