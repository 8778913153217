<template>
  <section class="places-update-content">
    <b-row
      v-if="place"
      class="place-update"
    >
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-overlay
          :show="isOverlayActive"
          rounded="sm"
        >
          <b-card
            class="rating-view-map-card"
            title="Datos"
          >
            <rating-form :form="form" />
          </b-card>
        </b-overlay>
      </b-col>

      <b-col
        cols="12"
        xl="3"
        md="4"
        class="rating-update-actions"
      >
        <b-card>
          <b-button
            variant="primary"
            class="mb-75"
            block
            @click="onSave"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            variant="outline-secondary"
            class="mb-75"
            block
            @click="onBack"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              class="mr-50"
            />
            Volver
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BButton,
} from 'bootstrap-vue'
import overlayDisablerMixin from '@/mixins/overlayDisablerMixin'
import placeModelMixin from './mixins/placeModelMixin'
import ratingForm from './ratingForm.vue'
import ratingActions from './mixins/ratingActions'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    ratingForm,
  },
  mixins: [placeModelMixin, ratingActions, overlayDisablerMixin],
  data() {
    return {
      form: {
        place: {},
        user: {},
        value: 0,
        comment: '',
      },
    }
  },
  methods: {
    onSave() {
      if (this.isAppBusy()) {
        return
      }

      this.disable()

      this.doCreateRating(this.populateSaveObjectWithForm())
        .then(model => {
          this.$router.push({
            name: 'places-ratings-view',
            params: {
              ratingId: model.id,
            },
          })

          this.$swal({
            icon: 'success',
            title: '¡Valoración creada!',
            text: 'La valoración se ha creado correctamente',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.enable()
        })
    },
    onBack() {
      this.$router.go(-1)
    },
    afterFindPlace() {
      this.populateFormWithPlace()
    },
    populateFormWithPlace() {
      this.form.place = this.place
    },
    populateSaveObjectWithForm() {
      return {
        place_id: this.form.place.id,
        user_id: this.form.user.id,
        value: this.form.value,
        comment: this.form.comment,
      }
    },
  },
}
</script>
