/* eslint-disable */

import ApiModel from '../base/ApiModel';

import Category from './Category';
import Location from './Location';
import PlaceImage from './PlaceImage';
import User from './User';

class Place extends ApiModel {

  static get className() {
    return 'Place';
  }

  constructor(data = null) {
    super(data);

    this.id = '';
    this.author_id = '';
    this.chronology_id = '';
    this.description = '';
    this.editor_comments = '';
    this.is_favourite = '';
    this.location_id = '';
    this.name = '';
    this.rating_average = '';
    this.rating_number = '';
    this.status_id = '';
    this.typology_id = '';

    this.images = [];

    if (data !== null) {
      this.populate(data);
    }
  }

  static get propertiesLabels() {
    return {
    };
  }

  static deleteUrl(id) {
    return ['deletePlace', { id }];
  }

  static getSearchUrl() {
    return 'getPlaces';
  }

  static getFindUrl(id) {
    return ['getPlace', { id }];
  }

  static createUrl() {
    return 'postPlace';
  }

  static putUrl(id) {
    return ['putPlace', { id }];
  }

  static updateUrl(id) {
    return ['putPlace', { id }];
  }

  static get relatedProps() {
    return {
      author: User,
      chronology: Category,
      location: Location,
      mainImage: PlaceImage,
      images: PlaceImage,
      status: Category,
      typology: Category,
    };
  }

  getImageUrl(image) {
    return image
      ? this.constructor.api().getUrl(['getPlaceImageDownload', {
          fileableId: this.id, id: image.id
        }], true)
      : '';
  }

  populateRelated(prop, data) {
    super.populateRelated(prop, data)

    if (prop === 'images') {
      this.images.forEach(image => {
        image.fileableId = this.id
      })
    }

    return this
  }

  hasStatus(code) {
    return this.status.hasCode(code)
  }

  setUserFavourite(userId) {
    return this.constructor.postUserFavourite(this.id, {
      user_id: userId,
    })
    .then((model) => {
      this.populate(model);
      return this;
    });
  }

  unsetUserFavourite(userId) {
    return this.constructor.deleteUserFavourite(this.id, {
      user_id: userId,
    })
    .then((model) => {
      this.populate(model);
      return this;
    });
  }

  static deleteUserFavourite(id, params) {
    return new Promise((resolve, reject) => {
      this.api()
      .call(['deletePlaceUserFavourite', { id, ...params }])
      .then((response) => {
        const model = this._getModelPopulatedFromResponse(response.data, this);
        resolve(model);
        return model;
      });
    });
  };

  static postUserFavourite(id, params) {
    return new Promise((resolve, reject) => {
      this.api()
      .call(['postPlaceUserFavourite', { id }], params)
      .then((response) => {
        const model = this._getModelPopulatedFromResponse(response.data, this);
        resolve(model);
        return model;
      })
      .catch((error) => {
        reject(error);
      });
    });
  };

  static putPublish(id, params) {
    return new Promise((resolve, reject) => {
      this.api()
      .call(['putPlacePublish', { id }], params)
      .then((response) => {
        const model = this._getModelPopulatedFromResponse(response.data, this);
        resolve(model);
        return model;
      })
      .catch((error) => {
        reject(error);
      });
    });
  };

  static putRemove(id, params) {
    return new Promise((resolve, reject) => {
      this.api()
      .call(['putPlaceRemove', { id }], params)
      .then((response) => {
        const model = this._getModelPopulatedFromResponse(response.data, this);
        resolve(model);
        return model;
      })
      .catch((error) => {
        reject(error);
      });
    });
  };
}

export default Place;
