import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions({
      doCreateRating: 'apiModels/rating/post',
      doDeleteRating: 'apiModels/rating/delete',
      doPublishRating: 'apiModels/rating/putPublish',
      doRemoveRating: 'apiModels/rating/putRemove',
      doUpdateRating: 'apiModels/rating/put',
    }),
    publishRating(rating) {
      this.$swal({
        title: '¿Estás segur@?',
        text: 'Publicarás la valoración y será visible en las vista del mosaico',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, ¡adelante!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.doPublishRating({
              id: rating.id,
            })
              .then(() => {
                this.$swal({
                  icon: 'success',
                  title: '¡Publicada!',
                  text: 'La valoración ya está disponible en la app y la web',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          }
        })
    },
    removeRating(rating) {
      this.$swal({
        title: '¿Estás segur@?',
        text: 'La valoración se archivará y no estará disponible ni en la app, ni en la web.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, ¡adelante!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.doRemoveRating({
              id: rating.id,
            })
              .then(() => {
                this.$swal({
                  icon: 'success',
                  title: '¡Archivada!',
                  text: 'La valoración ha sido archivado',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          }
        })
    },
    deleteRating(rating) {
      this.$swal({
        title: '¿Estás segur@?',
        text: 'La valoración será borrada definitivamente del sistema',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, ¡adelante!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.doDeleteRating(rating.id)
              .then(() => {
                this.$router.go(-1)
                this.$swal({
                  icon: 'success',
                  title: '¡Eliminada!',
                  text: 'La valoración ha sido eliminado',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          }
        })
    },
  },
}
