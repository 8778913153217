export default [
  {
    path: '/contacts/list',
    name: 'contacts-list',
    component: () => import('@/views/contacts/List.vue'),
    meta: {
      pageTitle: 'Inbox',
      breadcrumb: [
        {
          text: 'Inbox',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contacts/:contactId/view',
    name: 'contacts-view',
    component: () => import('@/views/contacts/View.vue'),
    meta: {
      pageTitle: 'Mensaje',
      breadcrumb: [
        {
          text: 'Inbox',
          to: { name: 'contacts-list' },
        },
        {
          text: 'Mensaje',
          active: true,
        },
      ],
    },
  },
]
