/* eslint-disable */

import moment from 'moment-timezone';

class Formatter {
  getCountry() {
    return this.country || {
      code: 'ES',
      lang: 'es',
      currency_code: 'EUR',
    };
  }

  setCountry(country) {
    this.country = country;
  }

  getMoment(value) {
    let m;
    if (typeof value === 'object') {
      if (typeof value.isValid === 'function' && value.isValid()) {
        m = moment(value);
      } else {
        m = moment.tz(value.date, value.timezone).tz(moment.tz.guess());
      }
    } else {
      m = moment(value);
    }
    return m;
  }

  formatDate(value, format) {
    if (!value) {
      return '';
    }
    return this.getMoment(value).format(format);
  }

  asAge(value) {
    return moment().diff(this.getMoment(value), 'years');
  }

  asBoolean(value) {
    return value ? 'Si' : 'No';
  }

  asCurrency(value) {
    const country = this.getCountry();
    return new Intl.NumberFormat(`${country.lang}-${country.code}`, {style: "currency", currency: country.currency_code}).format(parseFloat(value));
  }

  asDate(value) {
    return this.formatDate(value, 'DD/MM/YYYY');
  }

  asHumanDateTime(value) {
    return this.formatDate(value, 'DD/MM/YYYY HH:mm');
  };

  asDateISO(value) {
    return this.formatDate(value, 'YYYY-MM-DD');
  }

  asDateTimeISO(value) {
    return this.formatDate(value, 'YYYY-MM-DD HH:mm:ss');
  }

  asDateTime(value) {
    return this.formatDate(value, 'DD/MM/YYYY HH:mm:ss');
  }

  asDateFromSplitted(value) {
    let result = '', sep = '';
    const keys = ['year', 'month', 'day'];

    for (const key of keys) {
      if (!value[key]) {
        break;
      }
      result = `${this.padLeft(value[key], 2, '0')}${sep}${result}`;
      sep = '/';
    }

    return result;
  }

  asDay(value) {
    return this.formatDate(value, 'DD');
  }

  asMonthName(value) {
    return this.formatDate(value, 'MMMM');
  }

  asMonthNameFromNumber(value) {
    return moment.months()[value - 1];
  }

  asTime(value) {
    return this.formatDate(value, 'HH:mm');
  }

  asCounter(value) {
    let remainder;
    // const hours = Math.floor(value / 3600).toString();
    // remainder = value % 3600;
    const minutes =  Math.floor(value / 60).toString();
    remainder = (value % 60).toString();
    return `${(minutes.length === 1 ? `0${minutes}` : minutes)}:${(remainder.length === 1 ? `0${remainder}` : remainder)}`;
  }

  asDateDiff(valueA, valueB, unit = 'milliseconds') {
    if (!valueA || !valueB) {
      return null;
    }
    return Math.abs(this.getMoment(valueA).diff(this.getMoment(valueB), unit));
  }

  asName(value) {
    const name = value.trim().split(/\s+/);
    const returnValue = [];
    let i;
    let word;
    let firstLetter;

    if (value.length === 0) {
      return '';
    }

    for (i = 0; i < name.length; i += 1) {
      word = name[i];
      if (word.length <= 3) {
        firstLetter = word[0];
        if (firstLetter.toUpperCase() === word[0]) {
          word = word[0].toUpperCase() + word.substring(1).toLowerCase();
        } else {
          word = word.toLowerCase();
        }
      } else {
        word = word[0].toUpperCase() + word.substring(1).toLowerCase();
      }
      returnValue.push(word);
    }
    return returnValue.join(' ');
  }

  asAlpha(value) {
    return value.replace(/[^A-Z]/ig, '');
  }

  asAlphaNum(value) {
    return value.replace(/[^A-Z0-9]/ig, '');
  }

  asAlphaNumDash(value) {
    return value.replace(/[^A-Z0-9-]/ig, '');
  }

  asString(value) {
    if (typeof value === 'string') {
      return value;
    } else if (typeof value === 'undefined') {
      return '';
    } else if (typeof value.toString === 'function') {
      return value.toString();
    } else {
      return '';
    }
  }

  stripTags(value) {
    return value.replace(/(<([^>]+)>)/ig, '');
  }

  stripTagsSpacing(value) {
    return this.stripTags(value.replace(/(<\/p><p>)/ig, ' '));
  }

  padLeft(value, length, char) {
    const sValue = this.asString(value);
    const padLength = length - sValue.length;
    if (padLength > 0) {
      return char.repeat(padLength) + sValue;
    } else {
      return sValue;
    }
  }

  padRight(value, length, char) {
    const sValue = this.asString(value);
    const padLength = length - sValue.length;
    if (padLength > 0) {
      return sValue + char.repeat(padLength);
    } else {
      return sValue;
    }
  }

  toLowerCase(value) {
    return value.toLowerCase();
  }

  toUpperCase(value) {
    return value.toUpperCase();
  }

  objectConcatProps(object, props, separator = ' ') {
    let value = '';
    let sep = '';
    let i;
    let l;

    for (i = 0, l = props.length; i < l; i += 1) {
      if (object[props[i]]) {
        value += sep + object[props[i]];
        sep = separator;
      }
    }
    return value;
  }

  trim(value) {
    return value.trim();
  }

  format(formatter, value) {
    let i;
    if (Array.isArray(formatter) === false) {
      formatter = [formatter];
    }
    for (i = 0; i < formatter.length; i += 1) {
      value = this[formatter[i]](value);
    }
    return value;
  }
}

export default Formatter;
