/* eslint-disable */

import ApiModel from './ApiModel';
import moment from 'moment-timezone';

class DocumentApiModel extends ApiModel {

  constructor(data) {
    super(data);
    this.id = '';
    this.name = '';
    this.real_name = '';
    this.extension = '';
    this.mime_type = '';
    this.size = '';
    this.binaries = '';
    this.fileableId = '';
    this.dataUri = '';
    this.url = '';

    if (data) {
      this.populate(data);
    }
  }

  populate(data, exclude = []) {
    super.populate(data, exclude);
    if (this.real_name !== "") {
      this.extension = this.constructor.extractExtension(this.real_name);
    }
  }

  setFromDataUri(dataUri) {
    let parts = dataUri.split(',');

    this.dataUri = dataUri;
    this.mime_type = parts[0].split(':')[1].split(';')[0];
    this.binaries = new File([this.constructor.getBufferFromByteString(atob(parts[1]))], this.real_name, {type: this.mime_type});
  }

  getSaveObject() {
    let _data = new FormData();
    _data.append('real_name', this.real_name);
    _data.append('binaries', this.binaries);
    _data.append('_ucu', moment().format('x'));
    this.binaries = '';
    return _data;
  }

  refresh() {
    return this.constructor.find(this.fileableId, this.id);
  }

  static find(id) {
    return new Promise((resolve, reject) => {
      let endpoint = this.getFindUrl(id);
      this.api()
      .call(endpoint)
      .then((response) => {
        resolve(this._getModelPopulatedFromResponse(response.data, this));
      })
      .catch((error) => {
        reject(error);
      })
    });
  }

  save() {
    return new Promise((resolve, reject) => {
      this.constructor.api()
      .call(this.constructor.createUrl(this.fileableId), this.getSaveObject(), true, false, true)
      .then((response) => {
        this.clearErrors();
        this.id = response.data.id;
        resolve(response);
      })
      .catch((error) => {
        let errors = $app.utils.extract(error, 'response.data.errors');
        if (errors !== undefined) {
          this._errors = errors;
          reject(false);
        } else {
          reject(error);
        }
      });
    });
  }

  delete() {
    return new Promise((resolve, reject) => {
      this.constructor.api()
      .call(this.constructor.deleteUrl(this.fileableId, this.id))
      .then((response) => {
        this.clearErrors();
        resolve(response);
      })
      .catch((error) => {
        let errors = $app.utils.extract(error, 'response.data.errors');
        if (errors !== undefined) {
          this._errors = errors;
          reject(false);
        } else {
          reject(error);
        }
      });
    });
  }

  download() {
    return this.constructor.download(this.constructor.downloadUrl(this.id));
  }

  static download(endpoint) {
    return new Promise((resolve, reject) => {
      this.api()
      .call(endpoint)
      .then((response) => {
        debug.log('DocumentApiModel.log: Opening - ' + JSON.stringify(this, null, 2));
        this.prepareFile(response.data)
        .then((result) => {
          resolve(result);
        });
      })
      .catch((error) => {
        debug.log('DocumentApiModel.log: refresh model process ERROR - ' + JSON.stringify(error, null, 2));
        reject(error);
      });
    });
  }

  static prepareFile(model) {
    return new Promise((resolve, reject) => {
      let blob = this.b64toBlob(model.binaries, model.mime_type);
      window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, (fs) => {
        debug.log('DocumentApiModel.log: file system open - ' + fs.name);
        fs.root.getFile(model.real_name + "." + model.extension, {create: true, exclusive: false}, (fileEntry) => {
          debug.log('DocumentApiModel.log: file entry retrieved');
          fileEntry.createWriter((fileWriter) => {
            fileWriter.onwriteend = () => {
              debug.log('DocumentApiModel.log.filepath: ' +  JSON.stringify(fileEntry, null, 2));
              cordova.plugins.fileOpener2.open(
                fileEntry.nativeURL,
                model.mime_type, {
                  error : function(err) {
                    debug.log('DocumentApiModel.log: fileOpener2 ERROR - ' + JSON.stringify(err, null, 2));
                    reject(false);
                  },
                  success : function() {
                    debug.log('DocumentApiModel.log: fileOpener2 success');
                    resolve(true);
                  },
                },
              );
            };
            fileWriter.onerror = function(error) {
              debug.log('DocumentApiModel.log: fileWriter.onerror ERROR - ' + JSON.stringify(error, null, 2));
              reject(error);
            };
            fileWriter.write(blob);
          }, (error) => {
            debug.log('DocumentApiModel.log: createWriter ERROR - ' + JSON.stringify(error, null, 2));
            reject(error);
          });
        }, (error) => {
          debug.log('DocumentApiModel.log: getFile ERROR - ' + JSON.stringify(error, null, 2));
          reject(error);
        });
      }, (error) => {
        debug.log('DocumentApiModel.log: requestFileSystem ERROR - ' + JSON.stringify(error, null, 2));
        reject(error);
      });
    });
  }

  openFile() {
    cordova.plugins.fileOpener2.open(
      filePath,
      fileMIMEType,
      {
        error : function(){ },
        success : function(){ },
      },
    );
  }

  static get propertiesLabels() {
    return {
      id: 'Id',
      real_name: 'Nombre',
      extension: 'Extensión'
    };
  }

  static b64toBlob(b64Data, contentType, sliceSize) {
    let input = b64Data.replace(/\s/g, ''),
        byteCharacters = atob(input),
        byteArrays = [],
        offset, slice, byteNumbers, i, byteArray, blob;
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    for (offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      slice = byteCharacters.slice(offset, offset + sliceSize);
      byteNumbers = new Array(slice.length);
      for (i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    try {
      blob = new Blob(byteArrays, { type: contentType });
    } catch (e) {
      // TypeError old chrome, FF and Android browser
      window.BlobBuilder = window.BlobBuilder ||
                           window.WebKitBlobBuilder ||
                           window.MozBlobBuilder ||
                           window.MSBlobBuilder;
      if (e.name == 'TypeError' && window.BlobBuilder) {
        let bb = new BlobBuilder();
        for (offset = 0; offset < byteArrays.length; offset += 1) {
          bb.append(byteArrays[offset].buffer);
        }
        blob = bb.getBlob(contentType);
      } else if (e.name == "InvalidStateError") {
        blob = new Blob(byteArrays, {
          type: contentType,
        });
      } else {
        return null;
      }
    }

    return blob;
  }

  static getBufferFromByteString(byteString) {
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return ab;
  }

  static getBlobFromBase64(base64, mime) {
    return new Blob(this.getBufferFromByteString(atob(base64)), {type: mime});
  }

  static getBlobFromDataUri(dataURI) {
    let byteString, mimestring;
    let parts = dataURI.split(',');
    if (parts[0].indexOf('base64') !== -1) {
      byteString = atob(parts[1]);
    } else {
      byteString = decodeURI(parts[1]);
    }
    mimestring = parts[0].split(':')[1].split(';')[0];
    return new Blob(this.getBufferFromByteString(byteString), {type: mime});
  }

  static extractExtension(name) {
    let parts = name.split('.');
    return parts[parts.length - 1];
  }

  static getFindUrl(id) {
    return ['getFile', { id }];
  }

  static downloadUrl(id) {
    return ['getFileDownload', { id }];
  }
}

export default DocumentApiModel;
