/* eslint-disable */

import Component from '../base/Component';

/**
 * Pagination implements a Pagination object to use in a data provider.
 *
 * @author Jose Lorente <jose.lorente.martin@gmail.com>
 */
class Pagination extends Component {
  /**
  * Create a ApiDataProvider object.
  * @param {array} data - populates the object with the provided data.
  */
  constructor(data = null) {
    super();

    /**
     * @type {number}
     * @private Use totalCount instead
     */
    this.page = 1;

    /**
     * @type {number}
     * @private Use totalCount instead
     */
    this.pageSize = 50;

    /**
     * @type {number}
     * @private Use pageCount instead
     */
    this._pageCount = 0;

    /**
     * @type {number}
     * @private Use totalCount instead
     */
    this._totalCount = 0;

    /**
     * @type {number}
     * @private Use totalPages instead
     */
    this._totalPages = 0;

    if (data) {
      this.populate(data);
    }
  };

  /**
   * Total number of models in the current page.
   * @type {number}
   * @readonly
   */
  get pageCount() {
    return this._totalCount;
  }

  /**
   * Total number of possible data models. This property is read-only.
   * @type {number}
   * @readonly
   */
  get totalCount() {
    return this._totalCount;
  }

  /**
   * Total number of possible pages. This property is read-only.
   * @type {number}
   * @readonly
   */
  get totalPages() {
    return this._totalPages;
  }

  /**
   * Clears the pagination model.
   *
   * @returns {this} The current Pagination object.
   */
  clear() {
    this._pageCount = 0;
    this._totalCount = 0;
    this._totalPages = 0;
    this.page = 1;

    return this;
  }

  /**
   * Populates the Pagination object with the given metadada.
   *
   * @type {Object} metaData
   * @returns {this} The current Pagination object.
   */
  populatePagination(metaData) {
    this.page = metaData.current_page;
    this._pageCount = metaData.count;
    this.pageSize = metaData.per_page;
    this._totalCount = metaData.total;
    this._totalPages = metaData.total_pages;

    return this;
  }
};

export default Pagination;
