<template>
  <b-button v-bind="{ type, variant, block, disabled }">
    <b-spinner
      v-if="loading"
      small
      type="grow"
    />
    <slot />
  </b-button>
</template>

<script>
/* eslint-disable vue/require-prop-types */

import { BButton, BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BSpinner,
  },
  props: ['block', 'disabled', 'loading', 'type', 'variant'],
}
</script>
