export default [
  {
    title: 'Usuarios',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Listado',
        route: 'users-list',
      },
      {
        title: 'Crear',
        route: 'users-create',
      },
    ],
  },
]
