import Country from '@nollaSdk/models/Country'
import {
  ADD_ITEM_TO_LIST, SET_ITEM, ADD_LIST,
} from '../core/mutation-types'
import mutations from '../core/mutations'

const state = {
  initialState() {
    return {
      list: [],
      item: null,
    }
  },
  list: [],
  item: null,
}

// actions
const actions = {
  get({ commit }, id) {
    return Country.find(id, true)
      .then(model => {
        commit(SET_ITEM, model)
        if (model) {
          commit(ADD_ITEM_TO_LIST, model)
        }
        return model
      })
  },
  getList({ commit }, params) {
    return Country.search(params, false)
      .then(models => {
        commit(ADD_LIST, models)
        return models
      })
  },
}

export default {
  actions,
  mutations,
  namespaced: true,
  state,
}
