/* eslint-disable */

import ApiModel from '../base/ApiModel'

let pool = {}

class Category extends ApiModel {

  constructor(data = null) {
    super(data)

    this.id = ''
    this.code = ''
    this.name = ''
    this.extra = ''

    if (data !== null) {
      this.populate(data)
    }
  }

  static get className() {
    return 'Category';
  }

  static findAll(parent = null, refresh = false) {
    let className = parent === null ? 'root' : parent
    if (pool[className] === undefined) {
      pool[className] = []
    }
    return new Promise((resolve, reject) => {
      if (pool[className].length > 0 && refresh === false) {
        resolve(pool[className])
      } else {
        pool[className] = []
        this.api()
        .call(['getCategories', {parent_path: parent}])
        .then((response) => {
          let i
          for (i = 0; i < response.data.length; ++i) {
            pool[className][i] = new this(response.data[i])
          }
          resolve(pool[className])
        })
        .catch((error) => {
          reject(error)
        })
      }
    })
  };

  hasCode(code) {
    if (Array.isArray(code)) {
      return code.includes(this.code);
    } else {
      return this.code === code;
    }
  };
};

export default Category;
