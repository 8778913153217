<template>
  <layout-vertical>

    <template v-slot:navbar>
      <app-navbar-vertical-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <template v-slot:breadcrumb>
      <app-breadcrumb />
    </template>

    <router-view />

    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />

    <template v-slot:footer>
      <app-footer />
    </template>
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppNavbarVerticalLayout from '@/layouts/components/app-navbar/AppNavbarVerticalLayout.vue'
import AppFooter from '@/layouts/components/AppFooter.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import AppBreadcrumb from '@/layouts/components/AppBreadcrumb.vue'
import { $themeConfig } from '@themeConfig'
import useAppConfig from '@core/app-config/useAppConfig'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'

export default {
  components: {
    AppBreadcrumb,
    AppCustomizer,
    AppNavbarVerticalLayout,
    AppFooter,
    LayoutVertical,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
  setup() {
    const {
      navbarType, footerType,
    } = useAppConfig()

    const {
      toggleVerticalMenuActive,
    } = useVerticalLayout(navbarType, footerType)

    return {
      toggleVerticalMenuActive,
    }
  },
}
</script>
