import Rating from '@nollaSdk/models/Rating'
import {
  ADD_ITEM_TO_LIST, DELETE_ITEM_FROM_LIST, SET_ITEM, ADD_LIST,
} from '../core/mutation-types'
import mutations from '../core/mutations'

const state = {
  initialState() {
    return {
      list: [],
      item: null,
    }
  },
  list: [],
  item: null,
}

// actions
const actions = {
  delete({ commit }, id) {
    return Rating.delete(id)
      .then(() => {
        commit(SET_ITEM, null)
        commit(DELETE_ITEM_FROM_LIST, id)
        return true
      })
  },
  get({ commit }, params) {
    return Rating.find(params.id, params)
      .then(model => {
        commit(SET_ITEM, model)
        if (model) {
          commit(ADD_ITEM_TO_LIST, model)
        }
        return model
      })
  },
  getList({ commit }, params) {
    return Rating.search(params)
      .then(models => {
        commit(ADD_LIST, models)
        return models
      })
  },
  post({ commit }, params) {
    return Rating.post(params)
      .then(model => {
        commit(SET_ITEM, model)
        if (model) {
          commit(ADD_ITEM_TO_LIST, model)
        }
        return model
      })
  },
  put({ commit }, payload) {
    return Rating.put(payload.id, payload.params)
      .then(model => {
        commit(SET_ITEM, model)
        if (model) {
          commit(ADD_ITEM_TO_LIST, model)
        }
        return model
      })
  },
  putPublish({ commit }, payload) {
    return Rating.putPublish(payload.id, payload.params)
      .then(model => {
        commit(SET_ITEM, model)
        if (model) {
          commit(ADD_ITEM_TO_LIST, model)
        }
        return model
      })
  },
  putRemove({ commit }, payload) {
    return Rating.putRemove(payload.id, payload.params)
      .then(model => {
        commit(SET_ITEM, model)
        if (model) {
          commit(ADD_ITEM_TO_LIST, model)
        }
        return model
      })
  },
}

export default {
  actions,
  mutations,
  namespaced: true,
  state,
}
