/* eslint-disable */

import ApiModel from '../base/ApiModel';

class Role extends ApiModel {

  constructor(data = null) {
    super(data)

    this.id = '';
    this.name = '';
    this.description = '';

    if (data !== null) {
      this.populate(data);
    }
  }

  static get className() {
    return 'Role';
  }

  static get propertiesLabels() {
    return {
      id: 'Id',
      tag: 'Tag',
      is_main: 'Es principal',
      email: 'Role',
    }
  }

  static findAll() {
    return this.api()
    .call(['getRoles'])
    .then((response) => {
      return Role.populateCollectionFromResponse(response.data);
    })
  }
}

export default Role;
