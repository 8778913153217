/* eslint-disable */

import ApiModel from '../base/ApiModel'

class Region extends ApiModel {

  constructor(data = null) {
    super(data)

    this.id = ''
    this.name = ''
    this.code = ''

    if (data !== null) {
      this.populate(data)
    }
  }

  static get className() {
    return 'Region';
  }

  static get propertiesLabels() {
    return {
      id: 'Id',
      name: 'Nombre',
      code: 'Código',
    }
  }
}

export default Region
