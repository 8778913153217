export default [
  {
    path: '/places/list',
    name: 'places-list',
    component: () => import('@/views/places/List.vue'),
    meta: {
      pageTitle: 'Mosaicos',
      breadcrumb: [
        {
          text: 'Mosaicos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/places/create',
    name: 'places-create',
    component: () => import('@/views/places/Create.vue'),
    meta: {
      pageTitle: 'Mosaicos',
      breadcrumb: [
        {
          text: 'Mosaicos',
          to: { name: 'places-list' },
        },
        {
          text: 'Crear',
          active: true,
        },
      ],
    },
  },
  {
    path: '/places/:placeId/view',
    name: 'places-view',
    component: () => import('@/views/places/View.vue'),
    meta: {
      pageTitle: 'Mosaicos',
      breadcrumb: [
        {
          text: 'Mosaicos',
          to: { name: 'places-list' },
        },
        {
          text: 'Detalles',
          active: true,
        },
      ],
    },
  },
  {
    path: '/places/:placeId/update',
    name: 'places-update',
    component: () => import('@/views/places/Update.vue'),
    meta: {
      pageTitle: 'Mosaicos',
      breadcrumb: [
        {
          text: 'Mosaicos',
          to: { name: 'places-list' },
        },
        {
          text: 'Modificar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/places-ratings/list',
    name: 'places-ratings-list',
    component: () => import('@/views/places/RatingList.vue'),
    meta: {
      pageTitle: 'Valoraciones de Mosaicos',
      breadcrumb: [
        {
          text: 'Valoraciones de Mosaicos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/places-ratings/:ratingId/view',
    name: 'places-ratings-view',
    component: () => import('@/views/places/RatingView.vue'),
    meta: {
      pageTitle: 'Valoraciones de Mosaicos',
      breadcrumb: [
        {
          text: 'Valoraciones de Mosaicos',
          to: { name: 'places-ratings-list' },
        },
        {
          text: 'Detalles',
          active: true,
        },
      ],
    },
  },
  {
    path: '/places-ratings/:ratingId/update',
    name: 'places-ratings-update',
    component: () => import('@/views/places/RatingUpdate.vue'),
    meta: {
      pageTitle: 'Valoraciones de Mosaicos',
      breadcrumb: [
        {
          text: 'Valoraciones de Mosaicos',
          to: { name: 'places-ratings-list' },
        },
        {
          text: 'Modificar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/places/:placeId/create-rating',
    name: 'places-ratings-create',
    component: () => import('@/views/places/RatingCreate.vue'),
    meta: {
      pageTitle: 'Mosaicos',
      breadcrumb: [
        {
          text: 'Mosaicos',
          to: { name: 'places-list' },
        },
        {
          text: 'Crear valoración',
          active: true,
        },
      ],
    },
  },
]
