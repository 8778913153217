/* eslint-disable */

import axios from '@axios';
import qs from 'qs';
import endpoints from './endpoints';
import moment from 'moment-timezone';
import Utils from '../data/Utils';

export default {
  doRequest(request, fullResponse = false) {
    return new Promise((resolve, reject) => {
      return axios(request)
        .then((response) => {
          if (response.status >= 400) {
            reject(response);
          } else {
            resolve(fullResponse === true ? response : response.data);
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  callUrl(url, method, data = {}, needAuth = true, fullResponse = false, upload = false) {
    const request = {
      url,
      method,
      data,
    }

    if (upload) {
      request.headers = {
        'Content-Type': 'multipart/form-data',
      }
    }

    return this.doRequest(request, fullResponse)
  },
  call(endpoint, data = {}, needAuth = true, fullResponse = false, upload = false) {
    return this.callUrl(this.getUrl(endpoint), this.getMethod(endpoint), data , needAuth, fullResponse, upload);
  },
  getUrl(endpoint, absolute = false) {
    let key = endpoint
    let _url
    let serializedUrl;
    if (Array.isArray(endpoint) === true) {
      let url = endpoints[endpoint[0]].url
      let sep = (url.indexOf("?") === -1 ? "?" : "&")
      let queryParams = []
      let hasQuery = false
      for (key in endpoint[1]) {
        if (endpoint[1].hasOwnProperty(key)) {
          let eKey = ':' + key
          if (url.indexOf(eKey) !== -1) {
            url = url.replace(eKey, endpoint[1][key])
          } else {
            queryParams[key] = endpoint[1][key]
            hasQuery = true
          }
        }
      }
      if (hasQuery) {
        serializedUrl = Utils.instance().serializeToUrl(queryParams);
        if (typeof serializedUrl === 'string' && serializedUrl.length > 0) {
          url += sep + serializedUrl;
        }
      }
      _url = url
    } else {
      _url = endpoints[endpoint].url
    }

    if (process.env.VUE_APP_DEBUG === true) {
      _url += (_url.indexOf("?") === -1 ? "?" : "&") + "XDEBUG_SESSION_START=netbeans-xdebug"
    }

    return absolute ? `${process.env.VUE_APP_API_LOCATION}/${_url}` : _url;
  },
  getMethod(endpoint) {
    let key = endpoint
    if (Array.isArray(endpoint) === true) {
      key = endpoint[0]
    }
    return endpoints[key].method
  },
}
