/* eslint-disable */

import Poll from './Poll';
import ApiModel from '../base/ApiModel';
import Question from './Question';

class Pollable extends ApiModel {

  constructor(data = null) {
    super(data)

    this.id = ''
    this.type = ''
    this.poll_id = ''
    this.pollable_id = ''
    this.pollable_type = ''
    this.begin_at = ''
    this.end_at = ''

    this.questions = []

    if (data !== null) {
      this.populate(data)
    }
  };

  static get className() {
    return 'Pollable';
  }

  init() {
    for (let question of this.questions) {
      question.init();
    }
    return this;
  }

  getQuestion(code) {
    let q = null;
    for (let question of this.questions) {
      if (question.code === code) {
        q = question
        break;
      }
    }
    return q
  }

  saveQuestion(code) {
    let question = this.getQuestion(code)
    return new Promise((resolve, reject) => {
      this.constructor.api()
      .call(['postAnswerQuestion', {id: question.id}], {
        pollable_id: this.id
        , data: question.getNormalizedData()
      })
      .then((response) => {
        resolve(true)
      })
      .catch((error) => {
        reject(error)
      })
    })
  }

  static getFindUrl(id) {
    return ['getPollable', {id: id}]
  };

  static get relatedProps() {
    return {
      poll: Poll,
      questions: Question,
    };
  };
}

export default Pollable
