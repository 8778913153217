import {
  mapActions,
  mapMutations,
  mapState,
} from 'vuex'

import { SET_ITEM } from '@/store/api-models/core/mutation-types'

export default {
  computed: {
    ...mapState({
      countries: state => state.apiModels.country.list,
      countryDefault: state => state.apiModels.country.item,
    }),
  },
  mounted() {
    if (this.countries.length <= 0) {
      this.findCountries({
        order: [
          {
            field: 'name',
            sort: 'ASC',
          },
        ],
      })
    }
  },
  methods: {
    ...mapActions({
      findCountries: 'apiModels/country/getList',
    }),
    ...mapMutations({
      setDefaultCountry: `apiModels/country/${SET_ITEM}`,
    }),
  },
}
