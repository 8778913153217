import DoublyLinkedList from './DoublyLinkedList';

class Stack extends DoublyLinkedList {
  stack(o) {
    return this.push(o);
  };

  destack() {
    return this.pop();
  };

  peek() {
    if (this.isEmpty()) {
      throw {
        name: "EmptyStackException",
        message: "You can't peek at an empty stack",
      };
    }
    return this.l.prev.value;
  };
};

export default Stack;
