<template>
  <div class="image-selector form-component">
    <b-form-group
      :label="label"
      label-for="place-search-form-typology"
    >
      <button-input-image
        title="Añadir Imágenes"
        :file-input-id="fileInputId"
        @input="onInputFile"
      />
    </b-form-group>

    <div class="image-selector-items mt-3">
      <template v-if="items.length > 0">
        <div
          v-for="(file, index) in items"
          :key="file.id || `tmp-image-${index}`"
          class="image-selector-item"
        >
          <div
            class="image-selector-item-image"
            :style="`background-image: url(${file.dataUri || file.getDownLoadUrl()})`"
          />
          <div class="image-selector-item-content">
            {{ file.name }}
          </div>
          <div
            class="image-selector-item-icon-remove"
            @click="onDeleteFile(file.id)"
          >
            <feather-icon
              size="20"
              icon="XIcon"
            />
          </div>
        </div>
      </template>
      <div
        v-else
        class="image-selector-item"
      >
        <div class="image-selector-item-content">
          No hay ninguna imagen asociada
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlaceImage from '@nollaSdk/models/PlaceImage'
import {
  BFormGroup,
} from 'bootstrap-vue'
import ButtonInputImage from '@/components/ButtonInputImage.vue'

export default {
  components: {
    BFormGroup,
    ButtonInputImage,
  },
  $_veeValidate: {
    name() {
      return this.name
    },
    value() {
      return this.value
    },
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileInputId: `document-api-model-${this.componentID}`,
      items: [],
    }
  },
  watch: {
    value(val) {
      this.items = val
    },
  },
  mounted() {
    this.items = this.value
  },
  methods: {
    onInputFile() {
      const { files } = document.getElementById(this.fileInputId)

      if (!files || this.validateFiles(files) === false) {
        return
      }

      const readers = []
      for (let i = 0; i < files.length; i += 1) {
        readers.push(this.processFile(files.item(i)))
      }

      Promise.all(readers)
        .then(() => {
          document.getElementById(this.fileInputId).value = ''
          this.$emit('input', this.items)
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          document.getElementById(this.fileInputId).value = ''
          this.$emit('input', this.items)
        })
    },
    processFile(file) {
      return new Promise(resolve => {
        const reader = new FileReader()

        const fileModel = new PlaceImage({
          name: file.name,
          real_name: file.name,
          mime_type: file.type,
          size: file.size,
        })

        reader.onloadend = () => {
          const img = new Image()
          img.onload = () => {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0)

            const MAX_WIDTH = 1920
            const MAX_HEIGHT = 1920

            let { width, height } = img

            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width
                width = MAX_WIDTH
              }
            } else if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height
              height = MAX_HEIGHT
            }

            canvas.width = width
            canvas.height = height

            const resizingCtx = canvas.getContext('2d')
            resizingCtx.drawImage(img, 0, 0, width, height)

            fileModel.setFromDataUri(canvas.toDataURL(file.type))

            this.items.push(fileModel)

            resolve(fileModel)
          }

          img.src = reader.result
        }

        reader.onerror = error => {
          console.error(error)
        }

        reader.readAsDataURL(file)
      })
    },
    validateFiles(files) {
      if (files.length + this.items.length > 10) {
        this.$swal({
          icon: 'error',
          title: '¡Límite superado!',
          text: 'Solo se pueden añadir 10 imágenes como máximo',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })

        return false
      }

      for (let i = 0; i < files.length; i += 1) {
        if (
          ['image/jpeg', 'image/pjpeg', 'image/png'].includes(
            files.item(i).type,
          ) === false
        ) {
          this.$swal({
            icon: 'error',
            title: '¡Formato inválido!',
            text: 'Solo se pueden añadir imágenes jpg y png',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })

          return false
        }
      }

      return true
    },
    onDeleteFile(id) {
      for (let i = 0; i < this.items.length; i += 1) {
        if (this.items[i].id === id) {
          this.items.splice(i, 1)
          this.$emit('input', this.items)
          return
        }
      }
    },
  },
}
</script>

<style lang="scss">
.image-selector {
  label {
    width: 100%;
    font-size: var(--form-label-font-size);
  }

  .image-selector-items {
    .image-selector-item {
      border-radius: 10px;
      background-color: var(--app-item-layout-gray-color);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 68px;
      margin-bottom: 15px;

      .image-selector-item-image {
        width: 80px;
        overflow: hidden;
        height: 68px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        img {
          border-radius: 10px;
          height: 100%;
          position: absolute;
        }
      }

      .image-selector-item-content {
        flex: 1;
        width: 100%;
        height: calc(68px - 10px);
        padding: 0 5px 0 15px;
        overflow: hidden;
        margin: 5px 0;
        display: flex;
        align-items: center;
      }

      .image-selector-item-icon-remove {
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 68px;
        cursor: pointer;

        img {
          width: 40px;
        }
      }
    }
  }
}
</style>
