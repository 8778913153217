<template>
  <span :class="!email.is_verified ? 'text-danger' : 'text-secondary'">
    <a :href="`mailto:${email.email}`">
      {{ email.email }}
    </a>

    <feather-icon
      v-if="!email.is_verified"
      v-b-tooltip.hover.v-danger
      variant="light-danger"
      icon="InfoIcon"
      size="18"
      title="El email no ha sido verificado"
    />
  </span>
</template>

<script>
import Email from '@nollaSdk/models/Email'
import { VBTooltip } from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    email: {
      type: Email,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.text-secondary {
  a {
    color: #82868b !important;
  }
}
</style>
