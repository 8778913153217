import ratingStateMixin from './ratingStateMixin'

export default {
  mixins: [ratingStateMixin],
  data() {
    return {
      ratingId: this.$route.params.ratingId,
    }
  },
  mounted() {
    this.loadRating(this.ratingId)
  },
}
