<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="user.profile_image ? user.profile_image.getDataUrl() : null"
            variant="light-primary"
            :text="user.username"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ user.username }}
              </h4>
              <span class="card-text">
                <emailLink :email="$sdkUtils.extract(user, 'emails.0')" />
              </span>
            </div>
            <div class="d-flex flex-wrap">
              <b-badge :variant="statusBadges[user.status.code]">
                {{ user.status.name }}
              </b-badge>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center flex-wrap mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="MapPinIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ user.user_stat.places_published }}
              </h5>
              <small>Publicaciones</small>
            </div>
          </div>

          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="HeartIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ user.user_stat.places_favourites }}
              </h5>
              <small>Favoritos</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar
              variant="light-warning"
              rounded
            >
              <feather-icon
                icon="StarIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ user.user_stat.ratings_published }}
              </h5>
              <small>Valoraciones</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Usuario</span>
            </th>
            <td class="pb-50">
              {{ user.username }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Nombre</span>
            </th>
            <td class="pb-50">
              {{ user.name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UsersIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Apellidos</span>
            </th>
            <td class="pb-50">
              {{ user.last_name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserCheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Rol</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ $sdkUtils.arrayColumn(user.roles, 'name').join(', ') }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="SmartphoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Dispositivo</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ user.main_user_device ? user.main_user_device.getResume() : '---' }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BBadge, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import User from '@nollaSdk/models/User'
import emailLink from './emailLink.vue'

export default {
  components: {
    BCard, BBadge, BRow, BCol, BAvatar, emailLink,
  },
  props: {
    user: {
      type: User,
      required: true,
    },
  },
  setup() {
    return {
      avatarText,
      statusBadges: {
        locked: 'light-warning', active: 'light-success', unsubscribed: 'light-danger',
      },
    }
  },
}
</script>

<style>

</style>
