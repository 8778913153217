<template>
  <div>
    <card-model-list-table
      ref="resultsTable"
      :model-fields="modelFields"
      :default-fields="defaultFields"
      :data-provider="dataProvider"
      :query-composer="queryComposer"
      @row-clicked="onRowClicked"
    >
      <template slot="searchform">
        <search-form
          v-model="searchForm"
          @input="onSearchFormInput"
        />
      </template>

      <template #cell(profile_image)="data">
        <b-avatar
          v-if="data.value"
          :src="data.value.getDataUrl()"
          size="75px"
          rounded
        />
        <b-avatar
          v-else
          size="75px"
          rounded
        />
      </template>

      <template #cell(emails.0)="data">
        <emailLink :email="data.value" />
      </template>

      <template #cell(status)="data">
        <b-badge :variant="statusBadges[data.value.code]">
          {{ data.value.name }}
        </b-badge>
      </template>
    </card-model-list-table>
  </div>
</template>

<script>
import CardModelListTable from '@/components/CardModelListTable.vue'
import {
  BAvatar, BBadge,
} from 'bootstrap-vue'
import User from '@nollaSdk/models/User'
import emailLink from './emailLink.vue'
import searchForm from './searchForm.vue'
import userStateMixin from './mixins/userStateMixin'

export default {
  components: {
    BAvatar,
    BBadge,
    CardModelListTable,
    emailLink,
    searchForm,
  },
  mixins: [userStateMixin],
  data() {
    return {
      selectMode: 'multi',
      modes: ['multi', 'single', 'range'],
      modelFields: [
        { key: 'profile_image', label: 'Imagen' },
        { key: 'username', label: 'Usuario', sortable: true },
        { key: 'emails.0', label: 'Email' },
        {
          key: 'name',
          label: 'Nombre',
          sortable: true,
        },
        {
          key: 'last_name',
          label: 'Apellidos',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Estado',
          sortable: true,
          sortKey: 'status.code',
        },
      ],
      defaultFields: [
        'profile_image',
        'username',
        'emails.0',
        'name',
        'last_name',
        'status',
      ],
      queryComposer: () => this.composeQuery(),
      statusBadges: {
        locked: 'light-warning', active: 'light-success', unsubscribed: 'light-danger',
      },
      dataProvider: User.getDataProvider({
        pagination: {
          pageSize: 15,
        },
        preserve: false,
      }),
      searchForm: {
        fullname: '',
        status: {
          ids: [],
        },
      },
    }
  },
  methods: {
    onRowClicked(item) {
      this.commitUser(null)

      this.$router.push({
        name: 'users-view',
        params: {
          userId: item.id,
        },
      })
    },
    composeQuery() {
      const data = {
        include: 'profile_image',
      }

      if (this.searchForm.fullname) {
        data.fullname = `%${this.searchForm.fullname}%`
      }

      if (this.searchForm.status.ids.length > 0) {
        data.status = {
          ids: this.searchForm.status.ids,
        }
      }

      return data
    },
    doSearch() {
      this.$refs.resultsTable.refreshTable()
    },
    onSearchFormInput() {
      clearTimeout(this.searchFormTimeout)

      this.searchFormTimeout = setTimeout(() => {
        this.doSearch()
      }, 500)
    },
  },
}
</script>

<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}

</style>
