<template>
  <b-form autocomplete="chrome-off">
    <b-row>
      <b-col
        cols="12"
        xl="8"
        lg="6"
      >
        <b-form-group
          label="Nombre"
          label-for="place-form-name"
        >
          <b-form-input
            id="place-form-name"
            v-model="form.name"
            name="place-form-name"
            placeholder="Escribe el nombre del mosaico"
            autocomplete="chrome-off"
            required
            @input="onInput"
          />
        </b-form-group>

        <b-form-group
          label="Autor"
          label-for="place-search-form-author"
        >
          <user-selector
            id="place-search-form-author"
            v-model="form.author"
            :multiple="false"
            placeholder="Selecciona al autor"
            @input="onInput"
          />
        </b-form-group>

        <b-form-group
          label="Descripción"
          label-for="place-form-description"
        >
          <b-form-textarea
            id="place-form-description"
            v-model="form.description"
            placeholder="Describe el mosaico"
            rows="4"
            autocomplete="chrome-off"
            no-resize
            @input="onInput"
          />
        </b-form-group>

        <b-form-group
          label="Cronología"
          label-for="place-form-chronology"
        >
          <chronology-selector
            id="place-form-chronology"
            v-model="form.chronology_id"
            :multiple="false"
            placeholder="Selecciona cronología"
            @input="onInput"
          />
        </b-form-group>

        <b-form-group
          label="Tipología"
          label-for="place-search-form-typology"
        >
          <typology-selector
            id="place-search-form-typology"
            v-model="form.typology_id"
            :multiple="false"
            placeholder="Selecciona tipología"
            @input="onInput"
          />
        </b-form-group>

        <b-form-group
          label="Comentarios del editor"
          label-for="place-form-editor-comments"
        >
          <b-form-textarea
            id="place-form-editor-comments"
            v-model="form.editor_comments"
            placeholder="Deja algún comentario para el resto de editores"
            rows="4"
            autocomplete="chrome-off"
            no-resize
            @input="onInput"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        xl="4"
        lg="6"
        class="d-flex flex-column"
      >
        <place-location-selector
          id="place-form-place-selector"
          v-model="form.location"
          name="place-form-place-selector"
          label="Localización"
        />

        <div
          v-if="form.location"
          class="place-create-location-selected"
        >
          <b-form-group
            label="Detalles extra de localización"
            label-for="place-search-form-author"
          >
            <b-form-input
              id="place-form-address"
              v-model="form.address"
              name="place-form-address"
              placeholder="Añade detalles de la localización"
              autocomplete="chrome-off"
              @input="onInput"
            />
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
      >
        <image-selector
          v-model="form.images"
          label="Imágenes"
        />
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BFormTextarea, BRow, BCol,
} from 'bootstrap-vue'
import userSelector from '@/views/users/userSelector.vue'
import chronologySelector from './chronologySelector.vue'
import typologySelector from './typologySelector.vue'
import placeLocationSelector from './placeLocationSelector.vue'
import imageSelector from './imageSelector.vue'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    chronologySelector,
    typologySelector,
    userSelector,
    placeLocationSelector,
    imageSelector,
  },
  props: {
    form: {
      type: Object,
      default: () => ({
        author: {},
        name: '',
        description: '',
        chronology_id: '',
        typology_id: '',
        editor_comments: '',
        location: null,
        address: '',
        images: [],
      }),
    },
  },
  methods: {
    onInput() {
      this.$emit('input', this.form)
    },
  },
}
</script>

<style lang="scss">
.place-selector {
  flex: 1;
  display: flex;

  > div:first-child {
    flex: 1;
    display: flex;
    flex-direction: column;

    > div {
      flex: 1;
      display: flex;
    }
  }
}
</style>
