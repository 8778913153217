import Stack from '@nollaSdk/utils/Stack'
import {
  ADD_BACK_PAGE,
  POLICY_CHECK_SET_TIMESTAMP,
  USER_REFRESH_SET_TIMESTAMP,
} from '../core/mutation-types'

import mutations from '../core/mutations'

const globalState = {
  initialState() {
    return {
      policyCheckTimestamp: null,
      userRefreshTimestamp: null,
    }
  },
  backPageStack: new Stack(),
  pinChangeActive: null,
  policyCheckTimestamp: null,
  userRefreshTimestamp: null,
}

// getters
const getters = {}

// actions
const actions = {
  addBackPage({ commit }, page) {
    commit(ADD_BACK_PAGE, page)
  },
  setPolicyCheckTimestamp({ commit }, timestamp) {
    commit(POLICY_CHECK_SET_TIMESTAMP, timestamp)
  },
  setUserRefreshTimestamp({ commit }, timestamp) {
    commit(USER_REFRESH_SET_TIMESTAMP, timestamp)
  },
}

// mutations
mutations[ADD_BACK_PAGE] = function addBackPage(state, page) {
  state.backPageStack.stack(page)
}
mutations[POLICY_CHECK_SET_TIMESTAMP] = function policyCheckSetTimestamp(state, timestamp) {
  state.policyCheckTimestamp = timestamp
}
mutations[USER_REFRESH_SET_TIMESTAMP] = function userRefreshSetTimestamp(state, timestamp) {
  state.userRefreshTimestamp = timestamp
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state: globalState,
}
