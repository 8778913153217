/* eslint-disable */

import ApiModel from '../base/ApiModel'

let modelsPool = null;

class Country extends ApiModel {

  constructor(data = null) {
    super(data);

    this.id = '';
    this.name = '';
    this.code = '';
    this.currency_code = '';
    this.lang = '';
    this.phone_prefix = '';
    this.phone_validator = '';

    if (data !== null) {
      this.populate(data);
    }
  }

  static get className() {
    return 'Country';
  }

  static getSearchUrl(params) {
    return 'getCountries';
  };

  static getFindUrl(id) {
    return ['getCountry', { id }];
  };

  static findAll(refresh = false) {
    return new Promise((resolve, reject) => {
      if (modelsPool && refresh === false) {
        resolve(modelsPool);
      } else {
        this.api()
        .call(['getCountries'], {}, false)
        .then((response) => {
          modelsPool = response.data;
          resolve(modelsPool);
        })
        .catch((error) => {
          reject(error);
        });
      }
    });
  }

  static get propertiesLabels() {
    return {
      id: 'Id',
      name: 'Nombre',
      code: 'Código',
    }
  }
}

export default Country
