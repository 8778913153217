<template>
  <router-link
    :to="{ name: 'places-view', params: { placeId: place.id } }"
    @click="onClick"
  >
    {{ place.name }}
  </router-link>
</template>

<script>
import {
  mapMutations,
} from 'vuex'

import { SET_ITEM } from '@/store/api-models/core/mutation-types'

import Place from '@nollaSdk/models/Place'

export default {
  props: {
    place: Place,
  },
  methods: {
    ...mapMutations({
      commitPlace: `apiModels/place/${SET_ITEM}`,
    }),
    onClick() {
      this.commitPlace(null)
    },
  },
}
</script>
