import { mapActions, mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      account: state => state.account.item,
    }),
  },
  methods: {
    ...mapActions({
      findAccount: 'apiModels/account/get',
      findAccountForAuth: 'apiModels/account/getForAuth',
    }),
  },
}
