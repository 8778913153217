<template>
  <section class="places-update-content">
    <b-row
      v-if="place"
      class="place-update"
    >
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-overlay
          :show="isOverlayActive"
          rounded="sm"
        >
          <b-card
            class="place-view-map-card"
            title="Datos"
          >
            <place-form :form="form" />
          </b-card>
        </b-overlay>
      </b-col>

      <b-col
        cols="12"
        xl="3"
        md="4"
        class="place-update-actions"
      >
        <b-card>
          <b-button
            variant="primary"
            class="mb-75"
            block
            @click="onSave"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            variant="outline-secondary"
            class="mb-75"
            block
            @click="onBack"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              class="mr-50"
            />
            Volver
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BButton,
} from 'bootstrap-vue'
import overlayDisablerMixin from '@/mixins/overlayDisablerMixin'
import placeModelMixin from './mixins/placeModelMixin'
import placeForm from './placeForm.vue'
import placeActions from './mixins/placeActions'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    placeForm,
  },
  mixins: [placeModelMixin, placeActions, overlayDisablerMixin],
  data() {
    return {
      form: {
        author: {},
        name: '',
        description: '',
        chronology_id: '',
        typology_id: '',
        editor_comments: '',
        location: null,
        images: [],
      },
    }
  },
  methods: {
    onSave() {
      if (this.isAppBusy()) {
        return
      }

      this.disable()

      this.doUpdateImages()
        .then(() => (this.doUpdatePlace({
          id: this.place.id,
          params: this.populateSaveObjectWithForm(),
        })
          .then(() => {
            this.$router.go(-1)

            this.$swal({
              icon: 'success',
              title: '¡Modificado!',
              text: 'El mosaico se ha modificado correctamente',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        ))
        .catch(error => {
          console.error(error)

          this.$swal({
            icon: 'error',
            title: '¡Error!',
            text: 'Ha ocurrido un error al modificar el mosaico',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
        .finally(() => {
          this.enable()
        })
    },
    doUpdateImages() {
      const { images } = this.form
      const imagesToCreate = images.filter(image => image.isNew())
      const imagesToRemove = this.place.images.filter(image => !images.find(img => img.id === image.id))

      const promises = []

      imagesToCreate.forEach(file => {
        const uploadFile = file
        uploadFile.fileableId = this.place.id
        promises.push(file.save())
      })

      imagesToRemove.forEach(file => {
        promises.push(file.delete())
      })

      this.place.images = [...images]

      return Promise.all(promises)
    },
    onBack() {
      this.$router.go(-1)
    },
    afterFindPlace() {
      this.populateFormWithPlace()
    },
    populateFormWithPlace() {
      this.form.author = this.place.author
      this.form.name = this.place.name
      this.form.description = this.place.description
      this.form.chronology_id = this.place.chronology_id
      this.form.typology_id = this.place.typology_id
      this.form.editor_comments = this.place.editor_comments
      this.form.address = this.place.location.extra
      this.form.location = {
        full_address: this.place.location.address,
        coordinates: {
          lat: this.place.location.latitude,
          lng: this.place.location.longitude,
        },
      }
      this.form.images = [...this.place.images]
    },
    populateSaveObjectWithForm() {
      return {
        author_id: this.form.author.id,
        name: this.form.name,
        description: this.form.description,
        chronology_id: this.form.chronology_id,
        typology_id: this.form.typology_id,
        editor_comments: this.form.editor_comments,
        location: {
          address: this.form.location.full_address,
          country: this.form.location.country,
          extra: this.form.address,
          latitude: this.form.location.coordinates.lat,
          longitude: this.form.location.coordinates.lng,
          postal_code: this.form.location.postal_code,
          region: this.form.location.region,
          town: this.form.location.town,
        },
        include: 'images',
      }
    },
  },
}
</script>
