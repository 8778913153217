<template>
  <router-link
    :to="{ name: 'users-view', params: { userId: user.id } }"
    @click="onClick"
  >
    {{ user.fullname || user.username }}
  </router-link>
</template>

<script>
import {
  mapMutations,
} from 'vuex'

import { SET_ITEM } from '@/store/api-models/core/mutation-types'

import User from '@nollaSdk/models/User'

export default {
  props: {
    user: User,
  },
  methods: {
    ...mapMutations({
      commitUser: `apiModels/user/${SET_ITEM}`,
    }),
    onClick() {
      this.commitUser(null)
    },
  },
}
</script>
