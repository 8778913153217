<template>
  <section class="contacts-view-content">
    <b-row
      v-if="contact"
      class="contact-view"
    >
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-row>
          <b-col
            cols="12"
          >
            <b-card
              class="contact-view-images-card"
            >
              <h1 class="text-capitalize mb-3">
                {{ contact.name }}
              </h1>

              <h5 class="text-capitalize mb-75">
                Email:
              </h5>
              <b-card-text>
                <emailLink :email="contact.email" />
              </b-card-text>

              <h5 class="text-capitalize mb-75">
                Mensaje:
              </h5>
              <b-card-text>
                {{ contact.message }}
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <b-col
        cols="12"
        md="4"
        xl="3"
        class="contact-actions"
      >
        <b-card>
          <b-button
            variant="outline-danger"
            class="mb-75"
            block
            @click="onDelete"
          >
            <feather-icon
              icon="Trash2Icon"
              class="mr-50"
            />
            Eliminar
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BButton,
} from 'bootstrap-vue'
import emailLink from './emailLink.vue'
import contactActions from './mixins/contactActions'
import contactModelMixin from './mixins/contactModelMixin'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    emailLink,
  },
  mixins: [contactModelMixin, contactActions],
  methods: {
    onDelete() {
      this.deleteContact(this.contact)
    },
  },
}
</script>
