import Vue from 'vue'
import Utils from '../data/Utils'

Vue.use({
  install(vue) {
    const instance = new Utils()

    Object.defineProperty(vue.prototype, '$sdkUtils', {
      get() {
        return instance
      },
    })
  },
})
