import {
  mapActions,
  mapMutations,
  mapState,
} from 'vuex'

import { SET_ITEM, ADD_ITEM_TO_LIST } from '@/store/api-models/core/mutation-types'

export default {
  computed: {
    ...mapState({
      users: state => state.apiModels.user.list,
      user: state => state.apiModels.user.item,
    }),
  },
  methods: {
    ...mapActions({
      findUser: 'apiModels/user/get',
    }),
    ...mapMutations({
      commitUser: `apiModels/user/${SET_ITEM}`,
      adduser: `apiModels/user/${ADD_ITEM_TO_LIST}`,
    }),
    pushuserToStore(model) {
      this.commitUser(model)

      if (model) {
        this.adduser(model)
      }
    },
    loadUser(id) {
      this.commitUser(null)

      const params = {
        id,
        params: {
          include: 'profile_image,user_stat,roles',
        },
      }

      this.findUser(params)
        .then(user => {
          this.afterFindUser(user)
        })
    },
    afterFindUser() {
    },
  },
}
