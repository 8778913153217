<template>
  <b-form autocomplete="chrome-off">
    <b-row>
      <b-col
        md="6"
        xl="6"
      >
        <b-form-group
          label="Usuario, Nombre, Apellidos"
          label-for="user-search-form-name"
        >
          <b-form-input
            id="user-search-form-name"
            v-model="form.fullname"
            name="user-search-form-fullname"
            placeholder="(e.g. angela)"
            autocomplete="chrome-off"
            @input="onInput"
          />
        </b-form-group>
      </b-col>

      <b-col
        md="6"
        xl="6"
        class="mb-1"
      >
        <b-form-group
          label="Estado"
          label-for="user-search-form-status"
        >
          <status-selector
            id="user-search-form-status"
            v-model="form.status.ids"
            placeholder="Selecciona estado"
            @input="onInput"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BForm, BFormInput, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import statusSelector from './statusSelector.vue'

export default {
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    statusSelector,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        fullname: '',
        status: {
          ids: [],
        },
      }),
    },
  },
  data() {
    return {
      form: {
        fullname: '',
        status: {
          ids: [],
        },
      },
    }
  },
  methods: {
    onSearch() {
      this.$emit('search', this.form)
    },
    onInput() {
      this.$emit('input', this.form)
    },
  },
}
</script>
