/* eslint-disable */

import ApiModel from '../base/ApiModel';
import Country from './Country';
import Region from './Region';
import Town from './Town';
import Via from './Via';

class Location extends ApiModel {

  constructor(data = null) {
    super(data)

    this.id = '';
    this.address = '';
    this.country_id = '';
    this.door_number = '';
    this.doorway = '';
    this.extra = '';
    this.floor = '';
    this.number = '';
    this.postal_code = '';
    this.town_id = '';
    this.via_id = '';
    this.latitude = '';
    this.longitude = '';

    if (data !== null) {
      this.populate(data);
    }
  }

  static get className() {
    return 'Location';
  }

  static get propertiesLabels() {
    return {
      id: 'Id',
      via_id: 'Tipo de via',
      address: 'Nombre de vía',
      number: 'Número',
      doorway: 'Portal',
      floor: 'Piso',
      door_number: 'Puerta',
      extra: 'Resto',
      town_id: 'Localidad',
      dropdown_via_id: 'Tipo de via',
      town_id: 'Ciudad',
      postal_code: 'Código postal'
    };
  }

  static get relatedProps() {
    return {
      country: Country,
      region: Region,
      town: Town,
      via: Via
    };
  }
}

export default Location
