import {
  mapActions,
  mapMutations,
  mapState,
} from 'vuex'

import { SET_ITEM, ADD_ITEM_TO_LIST } from '@/store/api-models/core/mutation-types'

export default {
  computed: {
    ...mapState({
      contacts: state => state.apiModels.contact.list,
      contact: state => state.apiModels.contact.item,
    }),
  },
  methods: {
    ...mapActions({
      findContact: 'apiModels/contact/get',
    }),
    ...mapMutations({
      commitContact: `apiModels/contact/${SET_ITEM}`,
      addcontact: `apiModels/contact/${ADD_ITEM_TO_LIST}`,
    }),
    pushContactToStore(model) {
      this.commitContact(model)

      if (model) {
        this.addcontact(model)
      }
    },
    loadContact(id) {
      const params = {
        id,
      }

      this.findContact(params)
        .then(contact => {
          this.afterFindContact(contact)
        })
    },
    afterFindContact() {
    },
  },
}
