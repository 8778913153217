export default [
  {
    title: 'Mosaicos',
    icon: 'MapPinIcon',
    children: [
      {
        title: 'Listado',
        route: 'places-list',
      },
      {
        title: 'Crear',
        route: 'places-create',
      },
      {
        title: 'Comentarios y Valoraciones',
        route: 'places-ratings-list',
      },
    ],
  },
]
