/* eslint-disable */

import Category from './Category';
import ApiModel from '../base/ApiModel'

let CURRENT_POLICY_ID = null;

class Policy extends ApiModel {

  constructor(data = null) {
    super(data)

    this.id = ''
    this.code = ''
    this.type_id = ''
    this.is_active = ''
    this.content = ''

    this.type;

    if (data !== null) {
      this.populate(data)
    }
  }

  hasType(code) {
    return this.type.code === code;
  };

  static get className() {
    return 'Policy';
  }

  static get CURRENT_POLICY_ID() {
    return CURRENT_POLICY_ID;
  }

  static findCurrents() {
    return new Promise((resolve, reject) => {
      this.api()
      .call(['getPolicies', {
        is_active: 1,
        type: {
          has: 1,
        },
        client_id: process.env.API_CLIENT_ID,
        order: [
          {
            field: 'type_id',
            sort: 'ASC',
          }
        ],
      }], null, false)
      .then((response) => {
        const models = this.populateCollectionFromResponse(response.data);
        resolve(models);
        return models;
      })
      .catch((error) => {
        reject(error);
      });
    });
  };

  static findUnsigneds(userId) {
    return this.search({
      is_active: 1,
      users: {
        has: 0,
        id: userId,
      },
      type: {
        has: 1,
      },
      client_id: config.API_CLIENT_ID,
      order: [
        {
          field: 'type_id',
          sort: 'ASC',
        }
      ],
    });
  };

  static putSign(id, userId) {
    const policy = new this({ id });
    return policy.sign(userId);
  };

  sign(userId) {
    return new Promise((resolve, reject) => {
      this.constructor.api()
      .call(['putPolicySign', {
        id: this.id
      }], {
        user_id: userId,
      })
      .then((response) => {
        this.populateFromResponse(response.data);
        resolve(this);
        return this;
      })
      .catch((error) => {
        reject(error);
      });
    });
  };

  static getSearchUrl(params) {
    return 'getPolicies';
  };

  static get relatedProps() {
    return {
      type: Category,
    };
  };
}

export default Policy
