/* eslint-disable no-unused-vars */

import Policy from '@nollaSdk/models/Policy'
import Queue from '@nollaSdk/utils/Queue'
import {
  ADD_ITEM_TO_LIST, ADD_LIST, SET_ITEM, SET_QUEUE,
} from '../core/mutation-types'
import mutations from '../core/mutations'

const globalState = {
  initialState() {
    return {
      list: [],
      queue: new Queue(),
      item: null,
    }
  },
  list: [],
  queue: new Queue(),
  item: null,
}

// actions
const actions = {
  get({ commit }, id) {
    return Policy.find(id, true)
      .then(model => {
        commit(SET_ITEM, model)
        if (model) {
          commit(ADD_ITEM_TO_LIST, model)
        }
        return model
      })
  },
  findCurrents({ commit }) {
    return Policy.findCurrents()
      .then(models => {
        commit(ADD_LIST, models)
        return models
      })
  },
  findUnsigneds({ commit }, userId) {
    return Policy.findUnsigneds(userId)
      .then(models => {
        commit(ADD_LIST, models)
        return models
      })
  },
  getList({ commit }, params) {
    return Policy.search(params)
      .then(models => {
        commit(ADD_LIST, models)
        return models
      })
  },
  putSign({ commit }, payload) {
    return Policy.putSign(payload.id, payload.userId)
  },
}

mutations[ADD_LIST] = function mutationAddList(state, models) {
  let i
  let index

  for (i = 0; i < models.length; i += 1) {
    const model = models[i]
    index = state.list.findIndex(item => item.id === model.id)
    if (index >= 0) {
      state.list[index] = model
    } else {
      state.list.push(model)
    }
  }
  state.queue = new Queue(state.list)
}

mutations[SET_QUEUE] = function mutationSetQueue(state, models) {
  state.queue = new Queue(models)
}

export default {
  actions,
  mutations,
  namespaced: true,
  state: globalState,
}
