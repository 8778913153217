export default [
  {
    path: '/users/list',
    name: 'users-list',
    component: () => import('@/views/users/List.vue'),
    meta: {
      pageTitle: 'Usuarios',
      breadcrumb: [
        {
          text: 'Usuarios',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/:userId/view',
    name: 'users-view',
    component: () => import('@/views/users/View.vue'),
    meta: {
      pageTitle: 'Usuarios - Detalle',
      breadcrumb: [
        {
          text: 'Usuarios',
          to: { name: 'users-list' },
        },
        {
          text: 'Usuario',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/:userId/update',
    name: 'users-update',
    component: () => import('@/views/users/Update.vue'),
    meta: {
      pageTitle: 'Usuarios - Modificar',
      breadcrumb: [
        {
          text: 'Usuarios',
          to: { name: 'users-list' },
        },
        {
          text: 'Modificar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/create',
    name: 'users-create',
    component: () => import('@/views/users/Create.vue'),
    meta: {
      pageTitle: 'Usuarios - Crear',
      breadcrumb: [
        {
          text: 'Usuarios',
          to: { name: 'users-list' },
        },
        {
          text: 'Crear',
          active: true,
        },
      ],
    },
  },
]
