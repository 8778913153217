<template>
  <b-form autocomplete="chrome-off">
    <b-row>
      <b-col
        md="6"
        xl="4"
        class="mb-1"
      >
        <b-form-group
          label="Cronología"
          label-for="place-search-form-chronology"
        >
          <chronology-selector
            id="place-search-form-chronology"
            v-model="form.chronology.ids"
            placeholder="Selecciona cronología"
            @input="onInput"
          />
        </b-form-group>
      </b-col>

      <b-col
        md="6"
        xl="4"
        class="mb-1"
      >
        <b-form-group
          label="Tipología"
          label-for="place-search-form-typology"
        >
          <typology-selector
            id="place-search-form-typology"
            v-model="form.typology.ids"
            placeholder="Selecciona tipología"
            @input="onInput"
          />
        </b-form-group>
      </b-col>

      <b-col
        md="6"
        xl="4"
        class="mb-1"
      >
        <b-form-group
          label="Estado"
          label-for="place-search-form-status"
        >
          <status-selector
            id="place-search-form-status"
            v-model="form.status.ids"
            placeholder="Selecciona estado"
            @input="onInput"
          />
        </b-form-group>
      </b-col>

      <b-col
        md="6"
        xl="4"
      >
        <b-form-group
          label="Nombre"
          label-for="place-search-form-name"
        >
          <b-form-input
            id="place-search-form-name"
            v-model="form.name"
            name="place-search-form-name"
            placeholder="(e.g. Palacio)"
            autocomplete="chrome-off"
            @input="onInput"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="4"
      >
        <b-form-group
          label="Autor"
          label-for="place-search-form-author-fullname"
        >
          <b-form-input
            id="place-search-form-author-fullname"
            v-model="form.author.fullname"
            name="place-search-form-author-fullname"
            placeholder="(e.g. angela)"
            autocomplete="chrome-off"
            @input="onInput"
          />
        </b-form-group>
      </b-col>

      <b-col
        md="6"
        xl="4"
      >
        <!-- static text -->
        <b-form-group
          label="País"
          label-for="place-search-form-country"
        >
          <country-selector
            id="place-search-form-country"
            v-model="form.location.country_id"
            placeholder="Selecciona País"
            @input="onInput"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BForm, BFormInput, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import chronologySelector from './chronologySelector.vue'
import countrySelector from './countrySelector.vue'
import statusSelector from './statusSelector.vue'
import typologySelector from './typologySelector.vue'

export default {
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    countrySelector,
    chronologySelector,
    statusSelector,
    typologySelector,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        chronology: {
          ids: [],
        },
        typology: {
          ids: [],
        },
        status: {
          ids: [],
        },
        name: '',
        author: {
          fullname: '',
        },
        location: {
          country_id: '',
        },
      }),
    },
  },
  data() {
    return {
      form: {
        chronology: {
          ids: [],
        },
        typology: {
          ids: [],
        },
        status: {
          ids: [],
        },
        name: '',
        author: {
          fullname: '',
        },
        location: {
          country_id: '',
        },
      },
    }
  },
  methods: {
    onSearch() {
      this.$emit('search', this.form)
    },
    onInput() {
      this.$emit('input', this.form)
    },
  },
}
</script>
