/* eslint-disable */

import Answer from './Answer'
import Category from './Category'
import Option from './Option'
import ApiModel from '../base/ApiModel'

class Question extends ApiModel {

  constructor(data = null) {
    super(data)

    this.id = ''
    this.code = ''
    this.statement = ''

    this.type = new Category()
    this.options
    this.answer = new Answer()

    this._strategy = null;

    if (data !== null) {
      this.populate(data)
    }
  }

  static get className() {
    return 'Question';
  }

  init() {
      return this.strategy(this).init()
  }

  strategy(refresh = false) {
    if (refresh === true || this._strategy === null) {
      this._strategy = this.constructor.strategies(this)[this.type.code]
    }
    return this._strategy
  }

  save() {
    return new Promise((resolve, reject) => {
      resolve(true)
    })
  }

  static strategies(_this) {
    return {
      "one-choice": {
        setAnswer(id) {
          for (let option of _this.options) {
            if (option.id === id) {
              option.select()
            } else {
              option.unselect()
            }
          }
        },
        getOptionByCode(code) {
          let opt = null;
          let option;
          for (option of _this.options) {
            if (option.code === code) {
              opt = option;
              break;
            }
          }
          return opt;
        },
        getAnswer() {
          let answer = null
          for (let option of _this.options) {
            if (option.isSelected()) {
              answer = option
              break
            }
          }
          return answer
        },
        getNormalizedData() {
          return {
            option_id: this.getAnswer().id
          }
        },
        init() {
          for (let option of _this.options) {
            option.init()
          }
          return _this
        }
      },
      "multiple-choice": {
        setAnswer(id) {
          for (let option of _this.options) {
            if (option.id === id) {
              if (option.isSelected()) {
                option.unselect()
              } else {
                option.select()
              }
            }
          }
        },
        getAnswer() {
          let answer = []
          for (let option of _this.options) {
            if (option.isSelected()) {
              answer.push(option)
            }
          }
          return answer
        },
        getOptionByCode(code) {
          let opt = null;
          let option;
          for (option of _this.options) {
            if (option.code === code) {
              opt = option;
              break;
            }
          }
          return opt;
        },
        getNormalizedData() {
          let option_ids = []
          for (let option of _this.options) {
            if (option.isSelected()) {
              option_ids.push(option.id)
            }
          }
          return {
            option_ids: option_ids
          }
        },
        init() {
          for (let option of _this.options) {
            option.init()
          }
          return _this
        }
      },
      "free-text": {
        setAnswer(answer) {
          if (_this.answer === undefined) {
            _this.answer = new Answer()
          }
          _this.answer.answer = answer
        },
        getAnswer() {
          return _this.answer
        },
        getOptionByCode() {
          return null;
        },
        getNormalizedData() {
          return {
            answer: this.getAnswer().answer
          }
        },
        init() {
          _this.answer = new Answer()
          return _this
        }
      }
    }
  }

  static findOptions(refresh = false) {
    return findRelateds('options', Option, [
      'getOptions'
      , {
          question_id: this.id
      }
    ], refresh);
  }

  static findPollableAnswers(pollable, refresh = false) {
    return findRelateds('answers', Answer, [
      'getAnswers'
      , {
          question_id: this.id
          , pollable_id: pollable.id
          , pollable_type: pollable.type
        ,
      }
    ], refresh);
  }

  static get relatedProps() {
    return {
      answers: Answer
      , options: Option
      , type: Category
    }
  }

  setAnswer(data) {
    this.strategy(this).setAnswer(data)
    return this
  }

  getAnswer() {
    return this.strategy(this).getAnswer()
  }

  getOptionByCode(code) {
    return this.strategy(this).getOptionByCode(code)
  }

  getNormalizedData() {
    return this.strategy(this).getNormalizedData()
  }
}

export default Question
