<template>
  <div>
    <card-model-list-table
      ref="resultsTable"
      :model-fields="modelFields"
      :default-fields="defaultFields"
      :data-provider="dataProvider"
      :query-composer="queryComposer"
      @row-clicked="onRowClicked"
    >
      <template slot="searchform">
        <search-form
          v-model="searchForm"
          @input="onSearchFormInput"
        />
      </template>

      <template #cell(avatar)="data">
        <b-avatar
          :src="data.item.getImageUrl(data.item.mainImage)"
          size="75px"
          rounded
        />
      </template>

      <template #cell(author)="data">
        <user-link
          :user="data.value"
        />
      </template>

      <template #cell(status)="data">
        <b-badge :variant="statusBadges[data.value.code]">
          {{ data.value.name }}
        </b-badge>
      </template>
    </card-model-list-table>
  </div>
</template>

<script>
import CardModelListTable from '@/components/CardModelListTable.vue'
import {
  BAvatar, BBadge,
} from 'bootstrap-vue'
import Place from '@nollaSdk/models/Place'
import userLink from '@/views/users/userLink.vue'
import searchForm from './searchForm.vue'
import placeStateMixin from './mixins/placeStateMixin'

export default {
  components: {
    BAvatar,
    BBadge,
    CardModelListTable,
    searchForm,
    userLink,
  },
  mixins: [placeStateMixin],
  data() {
    return {
      modelFields: [
        { key: 'avatar', label: 'Imagen' },
        { key: 'name', label: 'Nombre', sortable: true },
        {
          key: 'chronology.name',
          label: 'Cronología',
          sortable: true,
          sortKey: 'chronology.code',
        },
        {
          key: 'typology.name',
          label: 'Tipología',
          sortable: true,
          sortKey: 'typology.code',
        },
        {
          key: 'author',
          label: 'Usuario',
          sortable: true,
          sortKey: 'author.username',
        },
        {
          key: 'status',
          label: 'Estado',
          sortable: true,
          sortKey: 'status.code',
        },
        { key: 'editor_comments', label: 'Comentarios del Editor' },
        { key: 'rating_average', label: 'ø Valoración', sortable: true },
        { key: 'rating_number', label: 'Nº Valoraciones', sortable: true },
        { key: 'location.country.name', label: 'País', sortable: true },
      ],
      defaultFields: [
        'avatar',
        'name',
        'chronology.name',
        'typology.name',
        'author',
        'status',
      ],
      queryComposer: () => this.composeQuery(),
      statusBadges: {
        pendiente: 'light-warning', publicado: 'light-success', eliminado: 'light-danger',
      },
      dataProvider: Place.getDataProvider({
        pagination: {
          pageSize: 15,
        },
        preserve: false,
      }),
      searchForm: {
        chronology: {
          ids: [],
        },
        typology: {
          ids: [],
        },
        status: {
          ids: [],
        },
        name: '',
        author: {
          fullname: '',
        },
        location: {
          country_id: '',
        },
      },
    }
  },
  methods: {
    onRowClicked(item) {
      this.commitPlace(null)

      this.$router.push({
        name: 'places-view',
        params: {
          placeId: item.id,
        },
      })
    },
    composeQuery() {
      const data = {
        include: 'mainImage,location.country',
      }

      if (this.searchForm.name) {
        data.name = `%${this.searchForm.name}%`
      }

      if (this.searchForm.chronology.ids.length > 0) {
        data.chronology = {
          ids: this.searchForm.chronology.ids,
        }
      }

      if (this.searchForm.typology.ids.length > 0) {
        data.typology = {
          ids: this.searchForm.typology.ids,
        }
      }

      if (this.searchForm.status.ids.length > 0) {
        data.status = {
          ids: this.searchForm.status.ids,
        }
      }

      if (this.searchForm.location.country_id) {
        data.location = {
          country_id: this.searchForm.location.country_id,
        }
      }

      if (this.searchForm.author.fullname) {
        data.author = {
          fullname: `%${this.searchForm.author.fullname}%`,
        }
      }

      return data
    },
    doSearch() {
      this.$refs.resultsTable.refreshTable()
    },
    onSearchFormInput() {
      clearTimeout(this.searchFormTimeout)

      this.searchFormTimeout = setTimeout(() => {
        this.doSearch()
      }, 500)
    },
  },
}
</script>

<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}

</style>
