<template>
  <b-button
    variant="primary"
    class="mb-75 button-add-image"
    block
  >
    <feather-icon
      icon="ImageIcon"
      class="mr-50"
    />
    Añadir Imágenes

    <input
      :id="fileInputId"
      :name="fileInputId"
      type="file"
      accept="image/*"
      @change="onInputFile"
      :disabled="disabled"
      multiple
    />
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  props: {
    fileInputId: String,
    title: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onInputFile(e) {
      this.$emit('input', e)
    },
  },
}
</script>

<style lang="scss">
.button-add-image {
  position: relative;

  input[type='file'] {
    cursor: pointer;
    display: block;
    filter: alpha(opacity=0);
    height: 100%;
    width: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
    z-index: 10;
  }
}
</style>
