<template>
  <div>
    <card-model-list-table
      ref="resultsTable"
      :model-fields="modelFields"
      :default-fields="defaultFields"
      :data-provider="dataProvider"
      :query-composer="queryComposer"
      @row-clicked="onRowClicked"
    >
      <template slot="searchform">
        <search-form
          v-model="searchForm"
          @input="onSearchFormInput"
        />
      </template>

      <template #cell(email)="data">
        <emailLink :email="data.value" />
      </template>
    </card-model-list-table>
  </div>
</template>

<script>
import CardModelListTable from '@/components/CardModelListTable.vue'
import Contact from '@nollaSdk/models/Contact'
import emailLink from './emailLink.vue'
import searchForm from './searchForm.vue'
import contactStateMixin from './mixins/contactStateMixin'

export default {
  components: {
    CardModelListTable,
    emailLink,
    searchForm,
  },
  mixins: [contactStateMixin],
  data() {
    return {
      modes: ['multi', 'single', 'range'],
      modelFields: [
        {
          key: 'name',
          label: 'Nombre',
          sortable: true,
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
        },
        {
          key: 'message',
          label: 'Mensaje',
          sortable: true,
        },
      ],
      defaultFields: [
        'name',
        'email',
        'message',
      ],
      queryComposer: () => this.composeQuery(),
      dataProvider: Contact.getDataProvider({
        pagination: {
          pageSize: 15,
        },
        preserve: false,
      }),
      searchForm: {
        email: '',
        name: '',
      },
    }
  },
  methods: {
    onRowClicked(item) {
      this.commitContact(null)

      this.$router.push({
        name: 'contacts-view',
        params: {
          contactId: item.id,
        },
      })
    },
    composeQuery() {
      const data = {}

      if (this.searchForm.email) {
        data.email = `%${this.searchForm.email}%`
      }

      if (this.searchForm.name) {
        data.name = `%${this.searchForm.name}%`
      }

      return data
    },
    doSearch() {
      this.$refs.resultsTable.refreshTable()
    },
    onSearchFormInput() {
      clearTimeout(this.searchFormTimeout)

      this.searchFormTimeout = setTimeout(() => {
        this.doSearch()
      }, 500)
    },
  },
}
</script>

<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}

</style>
