/* eslint-disable */

import ApiModel from '../base/ApiModel';

class UserStat extends ApiModel {
  constructor(data = null) {
    super(data);

    this.places_favourites = '';
    this.places_published = '';
    this.ratings_published = '';

    if (data !== null) {
      this.populate(data);
    }
  }
}

export default UserStat;
