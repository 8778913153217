/* eslint-disable */

import Component from './Component';

class ActivableComponent extends Component {

  static get className() {
    return 'ActivableComponent';
  }

  static get EVENT_ACTIVATED() {
    return 'activated';
  };

  static get EVENT_DEACTIVATED() {
    return 'deactivated';
  };

  static get EVENT_BEFORE_ACTIVATED() {
    return 'before_activated';
  };

  static get EVENT_BEFORE_DEACTIVATED() {
    return 'before_deactivated';
  };

  constructor(active = true) {
    super();
    this.active = active;
    this.shouldActivateTimeout = null;
  };

  activate() {
    const wasActive = this.active === true;

    this.active = true;
    this.shouldNotActivate();

    if (wasActive === false) {
      this.trigger(this.constructor.EVENT_ACTIVATED);
    }
    return this;
  };

  deactivate() {
    const wasActive = this.active === true;

    this.active = false;
    this.shouldNotActivate();

    if (wasActive === true) {
      this.trigger(this.constructor.EVENT_DEACTIVATED);
    }
    return this;
  };

  isActive() {
    return this.active;
  };

  shouldNotActivate() {
    clearTimeout(this.shouldActivateTimeout);
    this.shouldActivateTimeout = null;
    return this;
  };

  shouldActivate(miliseconds = 1000) {
    this.shouldNotActivate();
    this.shouldActivateTimeout = setTimeout(() => {
      this.trigger(this.constructor.EVENT_BEFORE_ACTIVATED);
      this.activate();
    }, miliseconds);
    return this;
  };

  shouldDeactivate(miliseconds = 1000) {
    this.shouldNotActivate();
    this.shouldActivateTimeout = setTimeout(() => {
      this.trigger(this.constructor.EVENT_BEFORE_DEACTIVATED);
      this.deactivate();
    }, miliseconds);
    return this;
  };
};

export default ActivableComponent;
