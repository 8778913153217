import {
  ADD_ITEM_TO_LIST,
  ADD_LIST,
  CLEAR_STATE,
  DELETE_ITEM_FROM_LIST,
  SET_ITEM,
  SET_LIST,
} from './mutation-types'

export default {
  [ADD_ITEM_TO_LIST](state, model) {
    const index = state.list.findIndex(item => item.id === model.id)
    if (index >= 0) {
      state.list[index] = model
    } else {
      state.list.push(model)
    }
  },
  [ADD_LIST](state, models) {
    let i
    let index

    for (i = 0; i < models.length; i += 1) {
      const model = models[i]
      index = state.list.findIndex(item => item.id === model.id)
      if (index >= 0) {
        state.list[index] = model
      } else {
        state.list.push(model)
      }
    }
  },
  [CLEAR_STATE](state) {
    if (Object.prototype.hasOwnProperty.call(state, 'initialState')) {
      const initialState = state.initialState()
      Object.keys(initialState).forEach(key => {
        state[key] = initialState[key]
      })
    }
  },
  [DELETE_ITEM_FROM_LIST](state, id) {
    const index = state.list.findIndex(item => item.id === id)
    if (index !== -1) {
      state.list.splice(index, 1)
    }
  },
  [SET_ITEM](state, model) {
    state.item = model
  },
  [SET_LIST](state, models) {
    state.list = models
  },
}
