/* eslint-disable */

import ApiModel from '../base/ApiModel';

class Email extends ApiModel {

  constructor(data = null) {
    super(data)

    this.id = '';
    this.tag = '';
    this.is_main = '';
    this.is_verified = '';
    this.email = '';

    if (data !== null) {
      this.populate(data);
    }
  }

  static get className() {
    return 'Email';
  }

  static get propertiesLabels() {
    return {
      id: 'Id',
      tag: 'Tag',
      is_main: 'Es principal',
      email: 'Email',
    }
  }
}

export default Email;
