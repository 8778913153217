/* eslint-disable */

import ApiModel from '../base/ApiModel'
import Question from './Question'
import Queue from '../utils/Queue'

class Poll extends ApiModel {

  constructor(data = null) {
    super(data)

    this.id = ''
    this.code = ''
    this.name = ''

    this.questions = []
    this._symptons

    if (data !== null) {
      this.populate(data)
    }
  }

  static get className() {
    return 'Poll';
  }

  init() {
      for (let question of this.questions) {
        question.init()
      }
      return this
  }

  getQuestion(code) {
    let q = null;
    for (let question of this.questions) {
      if (question.code === code) {
        q = question
        break;
      }
    }
    return q
  }

  saveQuestion(code) {
    let question = this.getQuestion(code)
    return new Promise((resolve, reject) => {
      this.constructor.api()
      .call(['postAnswerQuestion', {id: question.id}], {
        pollable_id: this.pollable.id
        , pollable_type: this.pollable.type
        , data: question.getNormalizedData()
      })
      .then((response) => {
        resolve(true)
      })
      .catch((error) => {
        reject(error)
      })
    })
  }

  setSymptoms(data) {
    this._symptons = new Queue()
    for (let a of data) {
      this._symptons.enqueue(a)
    }
    return this
  }

  getSympton() {
    return this._symptons.dequeue()
  }

  static findByCode(code) {
    return new Promise((resolve, reject) => {
      this.api()
      .call(['getPollByCode', {code: code}])
      .then((response) => {
        resolve(this._getModelPopulatedFromResponse(response.data, this))
      })
      .catch((error) => {
        reject(error)
      })
    })
  }

  static findQuestions(refresh = false) {
    return findRelateds('questions', Question, ['getQuestions', {poll_id: this.id}], refresh);
  }

  static getFindUrl(id) {
    return ['getPoll', {id: id}]
  }

  static get relatedProps() {
    return {
      questions: Question
    }
  }
}

export default Poll
