import Disabler from '@nollaSdk/base/Disabler'
import {
  BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BOverlay,
  },
  data() {
    return {
      isOverlayActive: false,
    }
  },
  mounted() {
    this.$disabler
      .off(Disabler.EVENT_DISABLE, this.activateOverlay)
      .on(Disabler.EVENT_DISABLE, this.activateOverlay)
      .off(Disabler.EVENT_ENABLE, this.deactivateOverlay)
      .on(Disabler.EVENT_ENABLE, this.deactivateOverlay)
  },
  destroyed() {
    this.$disabler
      .off(Disabler.EVENT_DISABLE, this.activateOverlay)
      .off(Disabler.EVENT_ENABLE, this.deactivateOverlay)
  },
  methods: {
    activateOverlay() {
      this.isOverlayActive = true
    },
    deactivateOverlay() {
      this.isOverlayActive = false
    },
  },
}
