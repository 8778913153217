/* eslint-disable */

import ApiModel from '../base/ApiModel';

import Category from './Category';

class Signature extends ApiModel {

  static get className() {
    return 'Signature';
  };

  constructor(data = null) {
    super(data);

    this.id = '';
    this.sign_at = '';
    this.sign_channel_id = '';
    this.sign_ip = '';
    this.status_id = '';
    this.text = '';
    this.user_id = '';

    this.status;

    if (data !== null) {
      this.populate(data);
    }
  };

  static putSign(id) {
    return this.api()
    .call(['putSignSignature', { id }])
    .then((response) => {
      const model = this._getModelPopulatedFromResponse(response.data, this);
      return model;
    });
  }

  static getFindUrl(id) {
    return ['getSignature', { id }];
  }

  static get relatedProps() {
    return {
      status: Category,
    };
  }
};

export default Signature;
