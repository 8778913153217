import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions({
      doDeleteContact: 'apiModels/contact/delete',
    }),
    deleteContact(contact) {
      this.$swal({
        title: '¿Estás segur@?',
        text: 'El mensaje será borrado definitivamente del sistema',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, ¡adelante!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.doDeleteContact(contact.id)
              .then(() => {
                this.$router.go(-1)
                this.$swal({
                  icon: 'success',
                  title: '¡Eliminado!',
                  text: 'El mensaje ha sido eliminado',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          }
        })
    },
  },
}
