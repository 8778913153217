<template>
  <b-form autocomplete="chrome-off">
    <b-row>
      <b-col
        cols="12"
        xl="8"
        lg="6"
      >
        <b-form-group
          label="Mosaico"
          label-for="rating-form-place"
        >
          <place-selector
            id="rating-form-place"
            v-model="form.place"
            :multiple="false"
            placeholder="Selecciona el mosaico"
            @input="onInput"
          />
        </b-form-group>

        <b-form-group
          label="Usuario"
          label-for="rating-form-user"
        >
          <user-selector
            id="rating-form-user"
            v-model="form.user"
            :multiple="false"
            placeholder="Selecciona al usuario"
            @input="onInput"
          />
        </b-form-group>

        <b-form-group
          label="Valoración"
          label-for="rating-form-value"
        >
          <star-rating
            id="rating-form-value"
            v-model="form.value"
            :increment="1"
            :round-start-rating="false"
            :star-size="18"
            show-rating
            :padding="2"
            class="mr-lg-2"
          />
        </b-form-group>

        <b-form-group
          label="Comentario"
          label-for="rating-form-comment"
        >
          <b-form-textarea
            id="rating-form-comment"
            v-model="form.comment"
            placeholder="Deja algún comentario"
            rows="4"
            autocomplete="chrome-off"
            no-resize
            @input="onInput"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BForm, BFormGroup, BFormTextarea, BRow, BCol,
} from 'bootstrap-vue'
import StarRating from 'vue-star-rating'
import userSelector from '@/views/users/userSelector.vue'
import placeSelector from './placeSelector.vue'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormTextarea,
    BRow,
    BCol,
    placeSelector,
    userSelector,
    StarRating,
  },
  props: {
    form: {
      type: Object,
      default: () => ({
        place: {},
        user: {},
        value: 0,
        comment: '',
      }),
    },
  },
  methods: {
    onInput() {
      this.$emit('input', this.form)
    },
  },
}
</script>
