/* eslint-disable */

import ApiModel from '../base/ApiModel'
import Country from './Country'

class Phone extends ApiModel {

  constructor(data = null) {
    super(data)

    this.id = ''
    this.tag = ''
    this.is_main = ''
    this.country_id = ''
    this.number = ''

    if (data !== null) {
      this.populate(data)
    }
  }

  static get className() {
    return 'Phone';
  }

  static get propertiesLabels() {
    return {
      id: 'Id',
      tag: 'Tag',
      is_main: 'Es principal',
      country_id: 'País',
      number: 'Número',
    }
  }

  static get relatedProps() {
    return {
      country: Country,
    };
  };
}

export default Phone
