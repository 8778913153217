/* eslint-disable */

import Component from './Component'

class Model extends Component {
  constructor(data = null) {
    super()
    this._errors = {}
  }

  populate(data, exclude = []) {
    let prop
    let excludedProps = this.constructor._excludedProps
    for (prop in this) {
      if (this.hasOwnProperty(prop) && data.hasOwnProperty(prop) && excludedProps.indexOf(prop) === -1 && exclude.indexOf(prop) === -1) {
        this[prop] = data[prop]
      }
    }
  }

  addError(field, message) {
    if (this._errors[field] === undefined) {
      this._errors[field] = []
    }
    this._errors[field].push(message)
  }

  hasErrors() {
    return this._errors.length > 0
  }

  hasError(field) {
      return $app.utils.extract(this._errors, field) !== undefined
  }

  clearErrors() {
    this._errors = {}
  }

  get errors() {
    return this._errors
  }

  getValue(prop) {
    return this[prop]
  }

  getLabel(prop) {
    return this.constructor.getLabel(prop)
  }

  getPlaceholder(prop) {
    return this.constructor.getLabel(prop)
  }

  getIterator(collection) {
    return (() => {
      let index = -1;
      let data = [];
      for (let i = 0; i < collection.length; i += 1) {
        data.push(collection[i]);
      }
      return {
        current() {
          return data[index];
        },
        next() {
          return {
            value: data[++index],
            done: !(index in data),
          };
        },
        reset() {
          index = -1;
        },
        back() {
          return {
            value: data[--index],
            done: !(index in data),
          };
        },
      };
    })();
  }

  static getLabel(prop) {
    return this.propertiesLabels[prop] ? this.propertiesLabels[prop] : prop;
  }

  static get _excludedProps() {
    return this.excludedProps
  }

  static get excludedProps() {
    return ['_errors']
  }

  static get propertiesLabels() {
    return {}
  }
}

export default Model
