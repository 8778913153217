/* eslint-disable */

import ApiModel from '../base/ApiModel';

class UserDevice extends ApiModel {

  constructor(data = null) {
    super(data)

    this.id = '';
    this.user_id = '';
    this.uuid = '';
    this.platform = '';
    this.version = '';
    this.manufacturer = '';
    this.model = '';
    this.app_version = '';
    this.app_core_version = '';
    this.client_id = '';
    this.token = '';
    this.token_extra = '';
    this.tracking_id = '';
    this.attribution_data = '';

    if (data !== null) {
      this.populate(data);
    }
  }

  getResume() {
    return `${this.manufacturer} ${this.model} - ${this.platform} ${this.version} (${this.app_version})`;
  }

  static get className() {
    return 'UserDevice';
  }

  static createUrl() {
    return 'postUserDevice';
  }

  static getSearchUrl() {
    return 'getUserDevices';
  };

  static getFindUrl(id) {
    return ['getUserDevice', { id }];
  };

  static get propertiesLabels() {
    return {
    }
  }

  static get relatedProps() {
    return {
    }
  }
}

export default UserDevice;
