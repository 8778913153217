<template>
  <b-dropdown
    variant="link"
    no-caret
    toggle-class="p-0"
    right
  >
    <template #button-content>
      <b-button
        variant="primary"
        class="btn-icon"
      >
        <feather-icon icon="SettingsIcon" />
      </b-button>
    </template>

    <b-form-checkbox-group
      :id="`table-fields-configurator-${componentID}`"
      v-model="currentSelection"
      :name="`table-fields-configurator-${componentID}`"
      class="p-1"
      @change="onFieldsChange"
    >
      <b-form-checkbox
        v-for="(item, index) in fields"
        :key="`tfc-checkbox-item-${index}`"
        :value="item[optionValueKey]"
        class="my-1"
      >
        {{ item[optionLabelKey] }}
      </b-form-checkbox>
    </b-form-checkbox-group>
  </b-dropdown>
</template>

<script>
import {
  BDropdown, BFormCheckbox, BFormCheckboxGroup, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BDropdown,
    BButton,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    optionValueKey: {
      type: String,
      default: 'key',
    },
    optionLabelKey: {
      type: String,
      default: 'label',
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentSelection: [],
    }
  },
  mounted() {
    this.currentSelection = this.value
  },
  methods: {
    onFieldsChange() {
      this.$emit('input', this.computeSelectedFields())
    },
    computeSelectedFields() {
      const currentFields = []
      this.fields.forEach(field => {
        if (this.currentSelection.includes(field.key)) {
          currentFields.push(field.key)
        }
      })

      return currentFields
    },
  },
}
</script>
