/* eslint-disable */

import ApiModel from '../base/ApiModel';

import Category from './Category';
import Location from './Location';
import User from './User';

class UserUnsubscription extends ApiModel {

  static get className() {
    return 'UserUnsubscription';
  }

  constructor(data = null) {
    super(data);

    this.id = '';
    this.completed_at = '';
    this.comments = '';
    this.confirmed_at = '';
    this.data = '';
    this.reason = '';
    this.status_id = '';
    this.user_id = '';

    if (data !== null) {
      this.populate(data);
    }
  }

  static get propertiesLabels() {
    return {};
  }

  static deleteUrl(id) {
    return ['deletUserUnsubscription', { id }];
  }

  static getSearchUrl() {
    return 'getUserUnsubscriptions';
  }

  static getFindUrl(id) {
    return ['getUserUnsubscription', { id }];
  }

  static createUrl() {
    return 'postUserUnsubscription';
  }

  static putUrl(id) {
    return ['putUserUnsubscription', { id }];
  }

  static updateUrl(id) {
    return ['putUserUnsubscription', { id }];
  }

  static get relatedProps() {
    return {
      user: User,
    };
  }
}

export default UserUnsubscription;
