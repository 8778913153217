/* eslint-disable */

export default {
  getServerTime: {
    url: 'info/server-time',
    method: 'GET',
  },
  getClientInfo: {
    url: 'clients/:id/config',
    method: 'GET'
  },
  getDomain: {
    url: 'domains/current?include=country',
    method: 'GET',
  },
  getCities: {
    url: 'localizacion/codigo-postal/:cp/localidades',
    method: 'GET'
  },
  sessionUserInfo: {
    url: 'userinfo?include=roles,location,location.region,location.town,location.via,user_stat,profile_image',
    method: 'GET'
  },
  userInfo: {
    url: 'users/:id?include=roles,main_user_device,location,location.region,location.town,location.via,user_stat,profile_image',
    method: 'GET'
  },
  postRegisterUser: {
    url: 'users/register?include=roles,main_user_device,location,location.region,location.town,location.via,user_stat,profile_image',
    method: 'POST',
  },
  postUser: {
    url: 'users?include=roles,location,main_user_device,location.region,location.town,location.via,user_stat,profile_image',
    method: 'POST',
  },
  getUserProfileImage: {
    url: 'users/:id/profile-image/download',
    method: 'GET',
  },
  postUserDevice: {
    url: 'users-devices',
    method: 'POST'
  },
  getUserPlaces: {
    url: 'users/:id/places',
    method: 'GET',
  },
  getUserRatings: {
    url: 'users/:id/ratings',
    method: 'GET',
  },
  deleteInbox: {
    url: 'inbox/:id',
    method: 'DELETE',
  },
  getInboxs: {
    url: 'inbox?include=message',
    method: 'GET',
  },
  getInbox: {
    url: 'inbox/:id?include=message',
    method: 'GET',
  },
  putInboxReaded: {
    url: 'inbox/:id/readed?include=message',
    method: 'PUT',
  },
  putInbox: {
    url: 'inbox/:id?include=message',
    method: 'PUT',
  },
  getPollable: {
    url: 'pollables/:id?include=questions.options',
    method: 'GET',
  },
  getPollByCode: {
    url: 'polls/:code?include=questions.options',
    method: 'GET',
  },
  postAnswerQuestion: {
    url: 'questions/:id/answer',
    method: 'POST',
  },
  getCategories: {
    url: 'categories',
    method: 'GET',
  },
  getCountry: {
    url: 'localizacion/paises/:id',
    method: 'GET',
  },
  getCountries: {
    url: 'localizacion/paises',
    method: 'GET',
  },
  deleteContact: {
    url: 'contacts/:id',
    method: 'DELETE',
  },
  getContact: {
    url: 'contacts/:id',
    method: 'GET',
  },
  getContacts: {
    url: 'contacts',
    method: 'GET',
  },
  postContact: {
    url: 'contacts',
    method: 'POST',
  },
  getPolicies: {
    url: 'policies',
    method: 'GET',
  },
  putPolicySign: {
    url: 'policies/:id/sign',
    method: 'POST',
  },
  getUsers: {
    url: 'users?include=location,location.region,location.town,location.via',
    method: 'GET',
  },
  patchUser: {
    url: 'users/:id?include=location,location.region,location.town,location.via',
    method: 'PATCH',
  },
  putUser: {
    url: 'users/:id?include=roles,main_user_device,location,location.region,location.town,location.via,user_stat,profile_image',
    method: 'PUT',
  },
  putUserLock: {
    url: 'users/:id/lock?include=roles,main_user_device,location,location.region,location.town,location.via,user_stat,profile_image',
    method: 'PUT',
  },
  putUserUnlock: {
    url: 'users/:id/unlock?include=roles,main_user_device,location,location.region,location.town,location.via,user_stat,profile_image',
    method: 'PUT',
  },
  putUserPassword: {
    url: 'users/:id/password',
    method: 'PUT',
  },
  getSignature: {
    url: 'signatures/:id',
    method: 'GET',
  },
  putSignSignature: {
    url: 'signatures/:id/sign',
    method: 'PUT',
  },
  // Places
  getPlace: {
    url: 'places/:id?include=images',
    method: 'GET',
  },
  getPlaces: {
    url: 'places',
    method: 'GET',
  },
  deletePlace: {
    url: 'places/:id?include=images',
    method: 'DELETE',
  },
  postPlace: {
    url: 'places',
    method: 'POST',
  },
  deletePlaceUserFavourite: {
    url: 'places/:id/users-favourites',
    method: 'DELETE',
  },
  postPlaceUserFavourite: {
    url: 'places/:id/users-favourites',
    method: 'POST',
  },
  putPlace: {
    url: 'places/:id?include=images',
    method: 'PUT',
  },
  putPlacePublish: {
    url: 'places/:id/publish?include=images',
    method: 'PUT',
  },
  putPlaceRemove: {
    url: 'places/:id/remove?include=images',
    method: 'PUT',
  },
  deletePlaceImage: {
    url: 'places/:fileableId/files/:id',
    method: 'DELETE',
  },
  getPlaceImageDownload: {
    url: 'places/:fileableId/files/:id/download-binaries',
    method: 'POST',
  },
  postPlaceImage: {
    url: 'places/:fileableId/files',
    method: 'POST',
  },
  getRating: {
    url: 'ratings/:id?include=place,user',
    method: 'GET',
  },
  getRatings: {
    url: 'ratings',
    method: 'GET',
  },
  postRating: {
    url: 'ratings?include=user.profileImage',
    method: 'POST',
  },
  deleteRating: {
    url: 'ratings/:id',
    method: 'DELETE',
  },
  putRating: {
    url: 'ratings/:id?include=place,user',
    method: 'PUT',
  },
  putRatingPublish: {
    url: 'ratings/:id/publish?include=place,user',
    method: 'PUT',
  },
  putRatingRemove: {
    url: 'ratings/:id/remove?include=place,user',
    method: 'PUT',
  },
  getFile: {
    url: 'files/:id',
    method: 'GET',
  },
  getFileDownload: {
    url: 'files/:id/download',
    method: 'GET',
  },
  postUserUnsubscription: {
    url: 'user-unsubscriptions',
    method: 'POST',
  },
  postPasswordForgot: {
    url: 'password/forgot',
    method: 'POST',
  },
  postPasswordReset: {
    url: 'password/reset',
    method: 'POST',
  },
  postEmailVerify: {
    url: 'users/:id/email-verify',
    method: 'POST',
  },
  postEmailVerifyRequest: {
    url: 'users/:id/email-verify-request',
    method: 'POST',
  },
  getUserProfileImage: {
    url: 'users/:id/profile-image',
    method: 'GET',
  },
  postUserProfileImage: {
    url: 'users/:id/profile-image?include=profile_image',
    method: 'POST',
  },
  getUserProfileImageDownload: {
    url: 'users/:id/profile-image/download',
    method: 'GET',
  },
  getRoles: {
    url: 'roles',
    method: 'GET',
  },
};
