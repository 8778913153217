
class Node {
  constructor(v) {
    this.prev = null;
    this.next = null;
    this.value = v;
  };
};

export default Node;
