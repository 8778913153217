/* eslint-disable */

import Component from './Component';

class VueComponent extends Component {

  static get className() {
    return 'VueComponent';
  }

  constructor(vue) {
    super();
    this.setVue(vue);
  };

  setVue(vue) {
    this.vue = vue;
    return this;
  }
};

export default VueComponent;
