import Model from '@nollaSdk/models/Contact'
import { ADD_ITEM_TO_LIST, DELETE_ITEM_FROM_LIST, SET_ITEM } from '../core/mutation-types'
import mutations from '../core/mutations'

const state = {
  initialState() {
    return {
      item: null,
      list: [],
    }
  },
  item: null,
  list: [],
}

// getters
const getters = {}

// actions
const actions = {
  delete({ commit }, id) {
    return Model.delete(id)
      .then(() => {
        commit(SET_ITEM, null)
        commit(DELETE_ITEM_FROM_LIST, id)
        return true
      })
  },
  get({ commit }, params) {
    return Model.find(params.id, params)
      .then(model => {
        commit(SET_ITEM, model)
        if (model) {
          commit(ADD_ITEM_TO_LIST, model)
        }
        return model
      })
  },
  post({ commit }, params) {
    return Model.post(params)
      .then(model => {
        commit(SET_ITEM, model)
        return model
      })
  },
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
