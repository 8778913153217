/* eslint-disable */

import Queue from '../utils/Queue';
import Formatter from '../data/Formatter';

class Component {
  /**
  * Create a Component object.
  */
  constructor() {
    this._events = {};
    this._eventsOnce = {};
  };

  /**
  * Populates the current object with another object by matching its properties.
  * @param {object} data - Object used to populate the current object.
  * @param {array} exclude - Array with properties that must be excluded from the population.
  */
  populate(data, exclude = []) {
    let prop;
    for (prop in this) {
      if (this.hasOwnProperty(prop) && data.hasOwnProperty(prop) && exclude.indexOf(prop) === -1) {
        this[prop] = data[prop];
      }
    }
  };

  /**
   * Same as "once" with the exception that events are ran only if the event
   * is not registered yet.
   *
   * @param {string} name The event name
   * @param {componentEvent} handler The event handler
   * @return {this}
   */
  hasEvent(name) {
    return (this._events[name] !== undefined && this._events[name].length > 0)
    || (this._eventsOnce[name] !== undefined && this._eventsOnce[name].isEmpty() === false);
  };

  /**
   * Component event callback.
   * @callback componentEvent
   * @param {Event} event
   */

  /**
   * Attaches an event handler to an event.
   *
   * The event handler must be a function that will be called when event is
   * triggered.
   *
   * The event handler must be defined with the following signature,
   *
   * ```js
   * function (event) {
   * }
   * ```
   *
   * where `event` is an [[Event]] object which includes parameters associated with the event.
   *
   * @param {string} name The event name
   * @param {componentEvent} handler The event handler
   * @return {this}
   * @see off()
   */
  on(name, handler, data = null) {
    if (this._events[name] === undefined) {
      this._events[name] = [];
    }
    this._events[name].push([handler, data]);
    return this;
  };

  /**
   * Same as "on" with the exception that events are ran only once.
   *
   * @param {string} name The event name
   * @param {componentEvent} handler The event handler
   * @return {this}
   * @see off()
   */
  once(name, handler, data = null) {
    if (this._eventsOnce[name] === undefined) {
      this._eventsOnce[name] = new Queue();
    }
    this._eventsOnce[name].enqueue([handler, data]);
    return this;
  };

  /**
   * Same as "once" with the exception that events are ran only if the event
   * is not registered yet.
   *
   * @param {string} name The event name
   * @param {componentEvent} handler The event handler
   * @return {this}
   */
  only(name, handler, data = null) {
    if (this.hasEvent(name) === false) {
      this.once(name, handler, data);
    }
    return this;
  };

  /**
   * Detaches an existing event handler from this component.
   * This method is the opposite of [[on()]].
   * @param {string} name The event name
   * @param {componentEvent} handler The event handler. If it is null, all
   * handlers attached to the named event will be removed.
   * @return {this}
   * @see on()
   */
  off(name, handler = null) {
    let aux = [];
    let auxOnce = new Queue();
    let event;
    let i;
    if (this._events[name] !== undefined) {
      if (handler !== null) {
        for (i = 0; i < this._events[name].length; ++i) {
          event = this._events[name][i];
          if (event[0] !== handler) {
            aux.push(event);
          }
        }
      }
      this._events[name] = aux;
    }
    if (this._eventsOnce[name] !== undefined) {
      if (handler !== null) {
        while (this._eventsOnce[name].isEmpty() === false) {
          event = this._eventsOnce[name].dequeue();
          if (event[0] !== handler) {
            auxOnce.enqueue(event);
          }
        }
      }
      this._eventsOnce[name] = auxOnce;
    }
    return this;
  };

  /**
   * Triggers an event.
   * This method represents the happening of an event. It invokes
   * all attached handlers for the event including class-level handlers.
   * @param {string} name The event name
   * @param {Event} payload The event payload. If not set, a default [[Event]] object will be created.
   * @return {this}
   */
  trigger(name, payload) {
    return this.triggerOnceEvent(name, payload).triggerOnEvent(name, payload);
  };

  /**
   * Triggers only "on" events.
   * @param {string} name The event name
   * @param {Event} payload The event payload. If not set, a default [[Event]] object will be created.
   * @return {this}
   */
  triggerOnEvent(name, payload) {
    let i;
    let event;
    if (this._events[name] !== undefined) {
      for (i = 0; i < this._events[name].length; ++i) {
        event = this._events[name][i];
        event[0](payload);
      }
    }
    return this;
  }

  /**
   * Triggers only "once" events.
   * @param {string} name The event name
   * @param {Event} payload The event payload. If not set, a default [[Event]] object will be created.
   * @return {this}
   */
  triggerOnceEvent(name, payload) {
    let event;
    if (this._eventsOnce[name] !== undefined) {
      while (this._eventsOnce[name].isEmpty() === false) {
        event = this._eventsOnce[name].dequeue();
        event[0](payload);
      }
    }
    return this;
  }

  clear() {
    for (let prop in this) {
      if (this.hasOwnProperty(prop)) {
        this[prop] = ''
      }
    }
  };

  getFormatter() {
    return this.constructor.getAppFormatter();
  }

  static getAppFormatter() {
    return new Formatter();
  }

  static get _className() {
    let classNameRegEx = /(?:\S+\s+){1}([a-zA-Z_$][0-9a-zA-Z_$]*)/;
    // return classNameRegEx.exec( this.toString() )[1];
    return this.className ? this.className : null;
  };
}

export default Component
