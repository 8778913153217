<template>
  <div v-if="user">
    <card-model-list-table
      ref="resultsTable"
      :model-fields="modelFields"
      :default-fields="defaultFields"
      :data-provider="dataProvider"
      :query-composer="queryComposer"
      title="Mosaicos subidos"
      @row-clicked="onRowClicked"
    >
      <template #cell(avatar)="data">
        <b-avatar
          :src="data.item.getImageUrl(data.item.mainImage)"
          size="75px"
          rounded
        />
      </template>

      <template #cell(author)="data">
        <user-link
          :user="data.value"
        />
      </template>

      <template #cell(status)="data">
        <b-badge :variant="statusBadges[data.value.code]">
          {{ data.value.name }}
        </b-badge>
      </template>
    </card-model-list-table>
  </div>
</template>

<script>
import CardModelListTable from '@/components/CardModelListTable.vue'
import {
  BAvatar, BBadge,
} from 'bootstrap-vue'
import Place from '@nollaSdk/models/Place'
import User from '@nollaSdk/models/User'

export default {
  components: {
    BAvatar,
    BBadge,
    CardModelListTable,
  },
  props: {
    user: {
      type: User,
      required: true,
    },
  },
  data() {
    return {
      selectMode: 'multi',
      modes: ['multi', 'single', 'range'],
      modelFields: [
        { key: 'avatar', label: 'Imagen' },
        { key: 'name', label: 'Nombre', sortable: true },
        {
          key: 'chronology.name',
          label: 'Cronología',
          sortable: true,
          sortKey: 'chronology.code',
        },
        {
          key: 'typology.name',
          label: 'Tipología',
          sortable: true,
          sortKey: 'typology.code',
        },
        {
          key: 'status',
          label: 'Estado',
          sortable: true,
          sortKey: 'status.code',
        },
        { key: 'rating_average', label: 'ø Valoración', sortable: true },
        { key: 'rating_number', label: 'Nº Valoraciones', sortable: true },
        { key: 'location.country.name', label: 'País', sortable: true },
      ],
      defaultFields: [
        'avatar',
        'name',
        'chronology.name',
        'typology.name',
        'rating_average',
        'status',
      ],
      queryComposer: () => this.composeQuery(),
      statusBadges: {
        pendiente: 'light-warning', publicado: 'light-success', eliminado: 'light-danger',
      },
      dataProvider: Place.getDataProvider({
        pagination: {
          pageSize: 5,
        },
        preserve: false,
      }),
      searchForm: {
        chronology: {
          ids: [],
        },
        typology: {
          ids: [],
        },
        status: {
          ids: [],
        },
        name: '',
        author: {
          fullname: '',
        },
        location: {
          country_id: '',
        },
      },
    }
  },
  methods: {
    onRowClicked(item) {
      this.$router.push({
        name: 'places-view',
        params: {
          placeId: item.id,
        },
      })
    },
    composeQuery() {
      const data = {
        include: 'mainImage,location.country',
        author_id: this.user.id,
      }

      return data
    },
    doSearch() {
      this.$refs.resultsTable.refreshTable()
    },
    onSearchFormInput() {
      clearTimeout(this.searchFormTimeout)

      this.searchFormTimeout = setTimeout(() => {
        this.doSearch()
      }, 500)
    },
  },
}
</script>

<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}

</style>
