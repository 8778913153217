<template>
  <v-select
    id="place-select"
    v-model="innerValue"
    name="place-select"
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    :multiple="multiple"
    :get-option-label="model => model.name"
    :options="items"
    :placeholder="placeholder"
    autocomplete="chrome-off"
    :filterable="false"
    @search="onSearch"
  />
</template>

<script>
import vSelect from 'vue-select'
import { mapActions } from 'vuex'

export default {
  components: {
    vSelect,
  },
  $_veeValidate: {
    name() {
      return this.name
    },
    value() {
      return this.value
    },
  },
  props: {
    value: {
      type: [Array, Object],
      default: () => [],
    },
    error: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      innerValue: this.value,
      items: [],
      control: false,
    }
  },
  watch: {
    innerValue() {
      if (!this.control) {
        this.$emit('input', this.innerValue)
      }
      this.control = false
    },
    value() {
      this.control = true
      this.innerValue = this.value
    },
  },
  methods: {
    ...mapActions({
      doPlaceSearch: 'apiModels/place/getList',
    }),
    onSearch(search, loading) {
      clearTimeout(this.placeSearchTimeoutControl)

      this.placeSearchTimeoutControl = setTimeout(() => {
        loading(true)
        const data = search ? { name: `%${search}%` } : {}
        this.doPlaceSearch(data)
          .then(models => {
            this.items = models
            loading(false)
          })
      }, 500)
    },
  },
}
</script>
