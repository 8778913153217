<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ title }}</b-card-title>
      <table-fields-configurator
        v-model="currentFields"
        :fields="modelFields"
      />
    </b-card-header>

    <b-card-body>
      <slot name="searchform" />
    </b-card-body>

    <b-table
      ref="cardModelListTable"
      :hover="true"
      :items="provider"
      :fields="tableFields"
      responsive
      :busy.sync="isTableBusy"
      :current-page="currentPage"
      :per-page="perPage"
      no-local-sorting
      @row-clicked="onRowClicked"
    >
      <template
        v-for="(index, name) in scopedCellSlots()"
        v-slot:[name]="props"
      >
        <slot
          v-bind="props"
          :name="name"
        />
      </template>
    </b-table>

    <b-pagination
      v-if="withPagination"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      align="center"
      size="lg"
      class="my-2"
      first-number
      last-number
    />
  </b-card>
</template>

<script>
import TableFieldsConfigurator from '@/components/TableFieldsConfigurator.vue'
import {
  BCard, BCardHeader, BCardTitle, BTable, BCardBody, BPagination,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    TableFieldsConfigurator,
  },
  props: {
    modelFields: {
      type: Array,
      default: () => [],
    },
    dataProvider: {
      type: Object,
      required: true,
    },
    defaultFields: {
      type: Array,
      default: () => [],
    },
    queryComposer: {
      type: Function,
      default: () => () => {},
    },
    title: {
      type: String,
      default: 'Listado',
    },
    withPagination: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentFields: [],
      tableFields: [],
      isTableBusy: false,
      currentPage: 1,
      perPage: 15,
      totalRows: 1,
    }
  },
  watch: {
    currentFields() {
      this.computeTableFields()
    },
  },
  created() {
    this.currentFields = this.defaultFields
    this.computeTableFields()
  },
  methods: {
    onRowClicked(item) {
      this.$emit('row-clicked', item)
    },
    computeTableFields() {
      const tableFields = []

      this.modelFields.forEach(field => {
        if (this.currentFields.includes(field.key)) {
          tableFields.push(field)
        }
      })

      this.tableFields = tableFields
    },
    compoundSearchData(ctx) {
      this.dataProvider.pagination.page = ctx.currentPage
      this.dataProvider.pagination.pageSize = ctx.perPage

      const data = this.queryComposer(ctx)

      if (ctx.sortBy) {
        data.order = [
          {
            field: ctx.sortBy,
            sort: ctx.sortDesc ? 'DESC' : 'ASC',
          },
        ]
      }

      return data
    },
    provider(ctx) {
      this.isTableBusy = true
      return this.dataProvider
        .setQuery(this.compoundSearchData(ctx))
        .prepare()
        .then(provider => {
          this.totalRows = this.dataProvider.pagination.totalCount
          return provider.models
        })
        .finally(() => { this.isTableBusy = false })
    },
    refreshTable() {
      this.$refs.cardModelListTable.refresh()
    },
    toCellName(slot) {
      return `cell(${slot})`
    },
    scopedCellSlots() {
      const cellSlots = {}
      Object.keys(this.$scopedSlots).forEach(key => {
        if (key.match(/^cell\(.+\)$/)) {
          cellSlots[key] = this.$scopedSlots[key]
        }
      })

      return cellSlots
    },
  },
}
</script>
