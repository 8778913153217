<template>
  <section class="ratings-view-content">
    <b-row
      v-if="rating"
      class="rating-view"
    >
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-row>
          <b-col
            cols="12"
          >
            <b-card
              class="rating-view-images-card"
            >
              <h1 class="text-capitalize mb-3">
                Valoración de Mosaico

                <b-badge
                  xl
                  :variant="statusMap[rating.status.code]"
                  class="float-right"
                >
                  {{ rating.status.name }}
                </b-badge>
              </h1>

              <h5 class="text-capitalize mb-75">
                Mosaico:
              </h5>
              <b-card-text>
                <placeLink :place="rating.place" />
              </b-card-text>

              <h5 class="text-capitalize mb-75">
                Usuario:
              </h5>
              <b-card-text>
                <userLink :user="rating.user" />
              </b-card-text>

              <h5 class="text-capitalize mb-75">
                Valoración:
              </h5>
              <b-card-text>
                <star-rating
                  v-model="rating.value"
                  read-only
                  :increment="0.01"
                  :round-start-rating="false"
                  :star-size="18"
                  :show-rating="rating.value > 0"
                  :padding="2"
                  class="mr-lg-2"
                />
              </b-card-text>

              <h5 class="text-capitalize mb-75">
                Comentario:
              </h5>
              <b-card-text>
                {{ rating.comment || '---' }}
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <b-button
            v-if="rating.hasStatus(['pendiente', 'eliminado'])"
            variant="primary"
            class="mb-75"
            block
            @click="onPublish"
          >
            <feather-icon
              icon="ArrowUpCircleIcon"
              class="mr-50"
            />
            Publicar
          </b-button>

          <b-button
            v-if="rating.hasStatus(['pendiente', 'publicado'])"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="onRemove"
          >
            <feather-icon
              icon="ArrowDownCircleIcon"
              class="mr-50"
            />
            Archivar
          </b-button>

          <b-button
            variant="outline-secondary"
            class="mb-75"
            block
            @click="onEdit"
          >
            <feather-icon
              icon="Edit2Icon"
              class="mr-50"
            />
            Editar
          </b-button>

          <b-button
            variant="outline-danger"
            class="mb-75"
            block
            @click="onDelete"
          >
            <feather-icon
              icon="Trash2Icon"
              class="mr-50"
            />
            Eliminar
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BButton, BBadge,
} from 'bootstrap-vue'
import StarRating from 'vue-star-rating'
import userLink from '@/views/users/userLink.vue'
import ratingModelMixin from './mixins/ratingModelMixin'
import placeLink from './placeLink.vue'
import ratingActions from './mixins/ratingActions'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    BBadge,
    StarRating,
    userLink,
    placeLink,
  },
  mixins: [ratingModelMixin, ratingActions],
  data() {
    return {
      statusMap: {
        pendiente: 'light-warning',
        publicado: 'light-success',
        eliminado: 'light-danger',
      },
    }
  },
  methods: {
    onEdit() {
      this.$router.push({
        name: 'places-ratings-update',
        params: {
          ratingId: this.rating.id,
        },
      })
    },
    onPublish() {
      this.publishRating(this.rating)
    },
    onRemove() {
      this.removeRating(this.rating)
    },
    onDelete() {
      this.deleteRating(this.rating)
    },
  },
}
</script>

<style lang="scss">
.ratings-view-content {
  .rating-view-uplodaded {
    color: #353535;
  }

  .place-main-image {
    width: 100%;
    height: 45vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .rating-view-gallery {
    margin-top: 15px;
  }

  .place-description-container {
    margin-top: 50px;
  }

  .rating-view-map-card {
    height: calc(100% - 2rem);

    @media (max-width: 992px) {
      min-height: 350px;
    }

    .rating-view-map-card-container {
      flex: 1;
    }
  }

  .vue-star-rating {
    .vue-star-rating-rating-text {
      order: 1;
      margin-left: 0;
      margin-right: 7px;
    }

    .vue-star-rating-star {
      display: flex;
      order: 2;
    }
  }
}
</style>
