/* ============
 * Common Mutation types
 * ============
 *
 * The mutation types that are available for use in the whole store .
 */

const ADD_BACK_PAGE = 'ADD_BACK_PAGE'
const ADD_ITEM_TO_LIST = 'ADD_ITEM_TO_LIST'
const ADD_LIST = 'ADD_LIST'
const CLEAR_STATE = 'CLEAR_STATE'
const DELETE_ITEM_FROM_LIST = 'DELETE_ITEM_FROM_LIST'
const POLICY_CHECK_SET_TIMESTAMP = 'POLICY_CHECK_SET_TIMESTAMP'
const SET_CONNECTION = 'SET_CONNECTION'
const SET_LIST = 'SET_LIST'
const SET_ITEM = 'SET_ITEM'
const SET_QUEUE = 'SET_QUEUE'
const USER_REFRESH_SET_TIMESTAMP = 'USER_REFRESH_SET_TIMESTAMP'

export {
  ADD_BACK_PAGE,
  ADD_ITEM_TO_LIST,
  ADD_LIST,
  CLEAR_STATE,
  DELETE_ITEM_FROM_LIST,
  POLICY_CHECK_SET_TIMESTAMP,
  SET_CONNECTION,
  SET_LIST,
  SET_ITEM,
  SET_QUEUE,
  USER_REFRESH_SET_TIMESTAMP,
}
