/* eslint-disable */

import ApiModel from '../base/ApiModel'

class Answer extends ApiModel {

  constructor(data = null) {
    super(data)

    this.id = ''
    this.question_id
    this.pollable_id = ''
    this.pollable_type = ''
    this.answer = ''

    if (data !== null) {
      this.populate(data)
    }
  }

  static get className() {
    return 'Answer';
  }
}

export default Answer
