
import Node from './Node';

class DoublyLinkedList {
  constructor(array) {
    this.clear();
    if (array !== undefined) {
      this.setArray(array);
    }
  };

  setArray(array) {
    for (let i in array) {
      this.push(array[i]);
    }
  };

  getLength() {
    return this.b;
  };

  isEmpty() {
    return 0 === this.b;
  };

  unshift(o) {
    var n = new Node(o);
    n.prev = this.a;
    n.next = this.a.next;
    this.a.next.prev = n;
    this.a.next = n;
    this.b++;
    return this;
  };

  shift() {
    if (!this.isEmpty()) {
      var n;
      this.b--;
      n = this.a.next;
      this.a.next = n.next;
      n.next.prev = this.a;
      return n.value;
    } else {
      return null;
    }
  };

  push(o) {
    var n = new Node(o);
    n.next = this.l;
    n.prev = this.l.prev;
    this.l.prev.next = n;
    this.l.prev = n;
    this.b++;
    return this;
  };

  pop() {
    if (!this.isEmpty()) {
      var n;
      this.b--;
      n = this.l.prev;
      this.l.prev = n.prev;
      n.prev.next = this.l;
      return n.value;
    } else {
      return null;
    }
  };

  clear() {
    this.a = new Node(null);
    this.l = new Node(null);
    this.a.next = this.l;
    this.l.prev = this.a;
    this.b = 0;
  };
};

export default DoublyLinkedList;
