import {
  mapActions,
  mapMutations,
  mapState,
} from 'vuex'

import { SET_ITEM, ADD_ITEM_TO_LIST } from '@/store/api-models/core/mutation-types'

export default {
  computed: {
    ...mapState({
      ratings: state => state.apiModels.rating.list,
      rating: state => state.apiModels.rating.item,
    }),
  },
  methods: {
    ...mapActions({
      findRating: 'apiModels/rating/get',
    }),
    ...mapMutations({
      commitRating: `apiModels/rating/${SET_ITEM}`,
      addRating: `apiModels/rating/${ADD_ITEM_TO_LIST}`,
    }),
    pushRatingToStore(model) {
      this.commitRating(model)

      if (model) {
        this.addRating(model)
      }
    },
    loadRating(id) {
      const params = {
        id,
      }

      this.findRating(params)
        .then(rating => {
          this.afterFindRating(rating)
        })
    },
    afterFindRating() {
    },
  },
}
