import User from '@nollaSdk/models/User'
import { ADD_ITEM_TO_LIST, SET_ITEM } from '../core/mutation-types'
import mutations from '../core/mutations'

const state = {
  initialState() {
    return {
      list: [],
      item: null,
    }
  },
  list: [],
  item: null,
}

// getters
const getters = {}

// actions
const actions = {
  get({ commit }) {
    return User.find(User.SESSION_USER_ID, true)
      .then(model => {
        commit(SET_ITEM, model)
        commit(`apiModels/user/${SET_ITEM}`, model, { root: true })
        if (model) {
          commit(`apiModels/user/${ADD_ITEM_TO_LIST}`, model, { root: true })
        }
        return model
      })
  },
  getForAuth({ dispatch }) {
    return new Promise(resolve => {
      dispatch('get')
        .then(model => {
          resolve({
            id: model.id,
            fullName: model.fullName,
            username: model.username,
            avatar: `data:image/png;base64,${model.profile_image.binaries}`,
            email: model.emails[0].email,
            role: 'admin',
            ability: [
              {
                action: 'manage',
                subject: 'all',
              },
            ],
          })
        })
    })
  },
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
