/* eslint-disable */

import Component from '../base/Component';
import Pagination from './Pagination';
import api from '../core/api';

/**
 * ApiDataProvider implements a data provider based on a remote API.
 *
 * The following is an example of using ActiveDataProvider to provide Model instances:
 *
 * ```js
 *  let provider = new ApiDataProvider({
 *    'endpoint': 'get@v1/users',
 *    'pagination': {
 *      'per_page': 20
 *    },
 *    'sort': {
 *      'defaultOrder': [
 *        [name', Sort.SORT_ASC],
 *        [last_name', Sort.SORT_DESC]
 *      ]
 *    }
 * });
 *
 * // get the users in the current page
 * users = provider->getModels();
 * ```
 *
 * @author Jose Lorente <jose.lorente.martin@gmail.com>
 */
class DataProvider extends Component {
  /**
  * Create a ApiDataProvider object.
  * @param {array} data - populates the object with the provided data.
  */
  constructor(data = null) {
    super();

    /**
     * The endpoint that will be used to perform the queries.
     * @type {string}
     */
    this.endpoint = '';

    /**
     * The query that will be used to perform the queries.
     * @type {object}
     */
    this.query = {};

    /**
     * The pagination object. If this is null, it means the pagination is disabled.
     * @type {(Pagination|null)}
     */
    this.pagination = new Pagination();

    /**
     * A switch that changes when the data provider is loading the models.
     * @type {boolean}
     */
    this.loading = false;

    /**
     * The list of data models. This property should be read-only.
     * @type {array}
     */
    this.models = [];

    /**
     * Preserves the loaded data models instead of replacing them in every call.
     * @type {array}
     */
    this.preserve = true;
    
    if (data) {
      this.populate(data, ['pagination']);

      if (data.pagination) {
        this.pagination.populate(data.pagination)
      }
    }
  };

  /**
   * Clears the DataProvider model and restores its attributes.
   *
   * @returns {this} The current DataProvider.
   */
  clear() {
    this.models = [];
    this.pagination.clear();

    return this;
  }

  /**
   * Sets the next page of the pagination and prepares the models.
   *
   * @returns {Promise} The Api call promise
   */
  nextPage() {
    this.pagination.page += 1;
    return this.prepare();
  }

  /**
   * This function prepares the models with the current configuration.
   *
   * @returns {Promise} The Api call promise
   */
  prepare() {
    const query = JSON.parse(JSON.stringify(this.query));

    this.attachPagination(query);

    this.loading = true;
    return api.call([this.endpoint, query])
    .then((response) => {
      this.pagination.populatePagination(response.meta.pagination);
      this.populateModels(response.data);
      this.loading = false;
      return this;
    });
  }

  /**
   * Populates the data provider data models with the incoming results.
   *
   * @param {Array} results
   * @returns {this} The current DataProvider.
   */
  populateModels(results) {
    const models = this.prepareModels(results);

    if (!this.preserve) {
      this.models = models;
    } else {
      for (const model of models) {
        this.models.push(model);
      }
    }

    return this;
  }

  /**
   * Prepares the models Array with the incoming results.
   *
   * @param {Array} results
   * @returns {Array} models.
   */
  prepareModels(results) {
    const models = [];

    for (const result of results) {
      models.push(this.prepareModel(result));
    }

    return models;
  }

  /**
   * Prepares a single model with the incoming result.
   *
   * @param {Object} result
   * @returns {Object} model
   */
  prepareModel(result) {
    return result;
  }

  /**
   * Attaches the pagination params to the query object.
   *
   * @param {Object} query Query object
   * @returns {this} The current DataProvider.
   */
  attachPagination(query) {
    if (this.pagination) {
      if (this.pagination.page) {
        query.page = this.pagination.page;
      }

      if (this.pagination.pageSize) {
        query.limit = this.pagination.pageSize;
      }
    }

    return this;
  }

  /**
   * This function sets the pagination object.
   *
   * @param {Pagination} pagination A pagination object.
   * @returns {this} The current DataProvider.
   */
  setPagination(pagination) {
    this.pagination = pagination;
    return this;
  }

  /**
   * This function sets the query object.
   *
   * @param {Object} query A query object.
   * @returns {this} The current DataProvider.
   */
  setQuery(query) {
    this.query = query;
    return this;
  }
};

export default DataProvider;
