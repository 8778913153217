<template>
  <div :class="`place-selector form-component ${error ? 'has_error' : ''}`">
    <b-form-group
      :label="label"
      label-for="place-search-form-typology"
    >
      <div
        :class="`place-selector-map-container ${value ? 'selected' : ''}`"
        @click="openMap"
      >
        <div
          v-if="!value"
          class="place-selector-map-image"
        />
        <place-map-view
          v-else
          class="place-selector-minimap"
          :coordinates="[value.coordinates.lng, value.coordinates.lat]"
        />
      </div>
    </b-form-group>

    <div
      v-if="value"
      class="place-selector-address text-right"
    >
      {{ value.full_address }}
    </div>

    <b-modal
      id="place-selector-popup"
      centered
      modal-class="modal-xl"
      role="document"
      hide-header
      hide-footer
      @shown="onModalShown"
      @hidden="onModalHidden"
    >
      <div class="place-selector-popup-content">
        <div class="place-selector-popup-header">
          <h2>
            Selecciona un lugar en el mapa
          </h2>
        </div>

        <div class="place-selector-map-container">
          <div id="place-selector-map" />

          <img
            src="@/assets/images/nolla/marker_selected.png"
            class="place-selector-marker"
          >
        </div>

        <div class="place-selector-popup-footer">
          <b-button
            variant="outline-secondary"
            class="mb-2"
            @click="onUseLocation"
          >
            <feather-icon
              icon="MapPinIcon"
              class="mr-50"
            />
            Utilizar mi ubicación actual
          </b-button>

          <b-button
            variant="primary"
            class="mb-75"
            @click="onSelectLocation"
          >
            <feather-icon
              icon="MapPinIcon"
              class="mr-50"
            />
            Elegir esta ubicación
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import {
  BModal, BButton, BFormGroup,
} from 'bootstrap-vue'
import mapboxgl from 'mapbox-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import placeMapView from './placeMapView.vue'

import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'

export default {
  components: {
    BModal,
    BButton,
    BFormGroup,
    placeMapView,
  },
  $_veeValidate: {
    name() {
      return this.name
    },
    value() {
      return this.value
    },
  },
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      coordinates: [],
      map: null,
      geocoder: null,
    }
  },
  methods: {
    onModalShown() {
      this.createMap()
    },
    onModalHidden() {
      this.destroyMap()
    },
    openMap() {
      this.$bvModal.show('place-selector-popup')
    },
    closeMap() {
      this.$bvModal.hide('place-selector-popup')
    },
    createMap() {
      let center
      let zoom

      mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN

      if (this.value && this.value.coordinates) {
        center = this.value.coordinates
        zoom = 9
      } else {
        center = [-0.377065, 39.4697014]
        zoom = 5
      }

      this.map = new mapboxgl.Map({
        container: 'place-selector-map',
        style: process.env.VUE_APP_MAPBOX_STYLE,
        center,
        zoom,
      })

      this.addMapEvents()
    },
    addMapEvents() {
      this.map.off('load').on('load', () => {
        this.geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          placeholder: this.$t('common.search'),
          mapboxgl,
          marker: false,
        })

        this.map.addControl(this.geocoder)
      })
    },
    onSelectLocation() {
      if (this.isAppBusy()) {
        return
      }

      const location = this.map.getCenter()

      const request = {
        url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${location.lng},${location.lat}.json?language=es&access_token=${mapboxgl.accessToken}`,
        method: 'GET',
      }

      const data = {
        coordinates: location,
      }

      axios(request)
        .then(response => {
          const { features } = response.data

          data.full_address = features[0].place_name
          features.forEach(feature => {
            feature.place_type.forEach(type => {
              switch (type) {
                case 'address':
                  data.address = `${feature.text}${
                    feature.address ? ` ${feature.address}` : ''
                  }`
                  break
                case 'poi':
                  data.address = feature.text
                  break
                case 'postcode':
                  data.postal_code = feature.text
                  break
                case 'place':
                  data.town = feature.text
                  break
                case 'region':
                  data.region = feature.text
                  break
                case 'country':
                  data.country = feature.properties.short_code
                  break
                default:
              }
            })
          })

          this.$emit('input', data)
        })
        .catch(error => {
          console.error(error)
        })

      this.closeMap()
    },
    onUseLocation() {
      if (this.isAppBusy()) {
        return
      }

      this.disable()

      navigator.geolocation.getCurrentPosition(
        position => {
          this.map
            .flyTo({
              center: [position.coords.longitude, position.coords.latitude],
              zoom: 11,
            })
            .once('moveend', () => {
              this.enable()
            })
        },
        error => {
          console.error(error)
        },
        {
          enableHighAccuracy: true,
          maximumAge: 3000,
        },
      )
    },
    destroyMap() {
      if (this.geocoder) {
        this.map.removeControl(this.geocoder)
        this.geocoder = null
      }

      if (this.map) {
        this.map.remove()
        this.map = null
      }
    },
  },
}
</script>

<style lang="scss">
.place-selector {
  width: 100%;
  display: flex;
  flex-direction: column;

  .place-selector-label {
    font-size: var(--form-label-font-size);
  }

  .place-selector-map-container {
    text-align: center;
    position: relative;
    border: var(--form-input-border);
    flex: 1;
    min-height: 250px;
    cursor: pointer;
    display: flex;

    .place-selector-map-image {
      width: 100%;
      height: 100%;
      background: url('~@/assets/images/nolla/image_map_selector.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &.selected {
      img.place-selector-map-image {
        border: 1px solid var(--app-notification-bg-color);
        width: calc(100% - 1px * 2);
      }
    }

    .place-map-view {
      height: unset;
      flex: 1;
    }
  }

  &.has_error {
    .place-selector-label {
      color: var(--app-error-color) !important;
    }

    .place-selector-map-container {
      .place-selector-map-image {
        border: 8px solid rgba(var(--app-error-color-rgb), 0.3);
        width: calc(100% - 8px * 2);
      }
    }
  }

  .place-selector-address {
    padding: 8px;
    text-align: right;
    color: var(--app-notification-bg-color);

    &.form-component {
      & ~ .form-component {
        margin-bottom: 0;
      }
    }
  }
}

#place-selector-popup {
  padding-left: 0 !important;
  max-width: 100%;
  width: 100%;
  margin: 0;

  & ~ .modal-backdrop {
    opacity: unset;
    background-color: #fff;
  }

  .modal-dialog {
    width: 100%;
    max-width: 100%;
    margin: 0;

    .modal-header {
      border-bottom: unset;

      button {
        color: #000;
        opacity: unset;
      }
    }

    .modal-content {
      background-color: unset;
      border: 0;
      border-radius: 0;
      height: 100vh;
    }
  }

  .place-selector-popup-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .place-selector-popup-header {
      max-height: 100px;
      height: 10%;
      text-align: center;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      h2 {
        margin: 0;
      }
    }

    .place-selector-map-container {
      width: 100%;
      height: 100%;
      flex: 1;
      border-top: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;
      position: relative;

      #place-selector-map {
        width: 100%;
        height: 100%;
      }

      .place-selector-marker {
        width: 50px;
        height: 66px;
        position: absolute;
        top: calc(50% - 66px);
        right: calc(50% - 25px);
      }
    }

    .place-selector-popup-footer {
      height: 25%;
      max-height: 175px;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .button-use-location {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
