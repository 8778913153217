import placeStateMixin from './placeStateMixin'

export default {
  mixins: [placeStateMixin],
  data() {
    return {
      placeId: this.$route.params.placeId,
    }
  },
  mounted() {
    this.loadPlace(this.placeId)
  },
}
