<template>
  <section class="places-view-content">
    <b-row
      v-if="place"
      class="place-view"
    >
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-row>
          <b-col
            cols="12"
          >
            <b-card
              class="place-view-images-card"
            >
              <h1 class="text-capitalize mb-3">
                {{ place.name }}

                <b-badge
                  xl
                  :variant="statusMap[place.status.code]"
                  class="float-right"
                >
                  {{ place.status.name }}
                </b-badge>

                <star-rating
                  v-model="place.rating_average"
                  read-only
                  :increment="0.01"
                  :round-start-rating="false"
                  :star-size="18"
                  :show-rating="place.rating_average > 0"
                  :padding="2"
                  class="float-right mr-lg-2"
                />
              </h1>

              <div
                class="place-main-image"
                :style="`background-image: url(${place.getImageUrl(
                  place.images[0]
                )});`"
              />

              <image-gallery
                class="place-view-gallery"
                :place="place"
              />
            </b-card>
          </b-col>
          <b-col
            cols="12"
            lg="8"
          >
            <b-card
              class="place-view-data-card"
              title="Ficha"
            >
              <h5 class="text-capitalize mb-75">
                Autor:
              </h5>
              <b-card-text>
                <userLink :user="place.author" />
              </b-card-text>

              <h5 class="text-capitalize mb-75">
                Descripción:
              </h5>
              <b-card-text>
                {{ $sdkUtils.extract(place, 'description') || '---' }}
              </b-card-text>

              <h5 class="text-capitalize mb-75">
                Tipología:
              </h5>
              <b-card-text>
                {{ $sdkUtils.extract(place, 'typology.name', '---') }}
              </b-card-text>

              <h5 class="text-capitalize mb-75">
                Cronología:
              </h5>
              <b-card-text>
                {{ $sdkUtils.extract(place, 'chronology.name', '---') }}
              </b-card-text>

              <h5 class="text-capitalize mb-75">
                Comentarios del Editor:
              </h5>
              <b-card-text>
                {{ $sdkUtils.extract(place, 'editor_comments') || '---' }}
              </b-card-text>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            lg="4"
          >
            <b-card
              class="place-view-map-card"
              title="Mapa"
              no-body
            >
              <b-card-body class="d-flex flex-column">
                <h4 class="card-title">
                  Mapa
                </h4>
                <div class="place-view-map-card-container">
                  <place-map-view
                    :coordinates="[place.location.longitude, place.location.latitude]"
                  />
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <b-button
            v-if="place.hasStatus(['pendiente', 'eliminado'])"
            variant="primary"
            class="mb-75"
            block
            @click="onPublish"
          >
            <feather-icon
              icon="ArrowUpCircleIcon"
              class="mr-50"
            />
            Publicar
          </b-button>

          <b-button
            v-if="place.hasStatus(['pendiente', 'publicado'])"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="onRemove"
          >
            <feather-icon
              icon="ArrowDownCircleIcon"
              class="mr-50"
            />
            Archivar
          </b-button>

          <b-button
            variant="outline-secondary"
            class="mb-75"
            block
            @click="onEdit"
          >
            <feather-icon
              icon="Edit2Icon"
              class="mr-50"
            />
            Editar
          </b-button>

          <b-button
            variant="outline-secondary"
            class="mb-75"
            block
            @click="onClickRatings"
          >
            <feather-icon
              icon="StarIcon"
              class="mr-50"
            />
            Ver valoraciones
          </b-button>

          <b-button
            variant="outline-secondary"
            class="mb-75"
            block
            @click="onClickCreateRating"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            Crear valoración
          </b-button>

          <b-button
            variant="outline-danger"
            class="mb-75"
            block
            @click="onDelete"
          >
            <feather-icon
              icon="Trash2Icon"
              class="mr-50"
            />
            Eliminar
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BCardText, BButton, BBadge,
} from 'bootstrap-vue'
import StarRating from 'vue-star-rating'
import userLink from '@/views/users/userLink.vue'
import imageGallery from './imageGallery.vue'
import placeModelMixin from './mixins/placeModelMixin'
import placeMapView from './placeMapView.vue'
import placeActions from './mixins/placeActions'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BButton,
    BBadge,
    imageGallery,
    placeMapView,
    StarRating,
    userLink,
  },
  mixins: [placeModelMixin, placeActions],
  data() {
    return {
      statusMap: {
        pendiente: 'light-warning',
        publicado: 'light-success',
        eliminado: 'light-danger',
      },
    }
  },
  methods: {
    onEdit() {
      this.$router.push({
        name: 'places-update',
        params: {
          placeId: this.place.id,
        },
      })
    },
    onPublish() {
      this.publishPlace(this.place)
    },
    onRemove() {
      this.removePlace(this.place)
    },
    onDelete() {
      this.deletePlace(this.place)
    },
    onClickCreateRating() {
      this.$router.push({
        name: 'places-ratings-create',
        params: {
          placeId: this.place.id,
        },
      })
    },
    onClickRatings() {
      this.$router.push({
        name: 'places-ratings-list',
        query: {
          placeId: this.place.id,
        },
      })
    },
  },
}
</script>

<style lang="scss">
.places-view-content {
  .place-view-uplodaded {
    color: #353535;
  }

  .place-main-image {
    width: 100%;
    height: 45vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .place-view-gallery {
    margin-top: 15px;
  }

  .place-description-container {
    margin-top: 50px;
  }

  .place-view-map-card {
    height: calc(100% - 2rem);

    @media (max-width: 992px) {
      min-height: 350px;
    }

    .place-view-map-card-container {
      flex: 1;
    }
  }

  .vue-star-rating {
    .vue-star-rating-rating-text {
      order: 1;
      margin-left: 0;
      margin-right: 7px;
    }

    .vue-star-rating-star {
      display: flex;
      order: 2;
    }
  }
}
</style>
