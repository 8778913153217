/* eslint-disable */

import Component from '../base/Component';
import DataProvider from './DataProvider';

/**
 * ApiDataProvider implements a data provider based on a remote API.
 *
 * The following is an example of using ActiveDataProvider to provide Model instances:
 *
 * ```js
 *  let provider = new ModelDataProvider({
 *    'endpoint': 'get@v1/users',
 *    'pagination': {
 *      'per_page': 20
 *    },
 *    'sort': {
 *      'defaultOrder': [
 *        [name', Sort.SORT_ASC],
 *        [last_name', Sort.SORT_DESC]
 *      ]
 *    }
 * });
 *
 * // get the users in the current page
 * users = provider->getModels();
 * ```
 *
 * @author Jose Lorente <jose.lorente.martin@gmail.com>
 */
class ModelDataProvider extends DataProvider {
  /**
  * Create a ApiDataProvider object.
  * @param {array} data - populates the object with the provided data.
  */
  constructor(data = null) {
    super(data);

    /**
     * The model used to populate the results.
     * @type {string}
     */
    this.model = null;

    if (data) {
      this.populate(data, ['pagination']);
    }
  }

  /**
   * Prepares a single model with the incoming result.
   *
   * @param {Object} result
   * @returns {Object} model
   */
  prepareModel(result) {
    return this.model._getModelPopulatedFromResponse(result);
  }
};

export default ModelDataProvider;
