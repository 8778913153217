import Vue from 'vue'
import Formatter from '../data/Formatter'

Vue.use({
  install(vue) {
    const instance = new Formatter()

    Object.defineProperty(vue.prototype, '$formatter', {
      get() {
        return instance
      },
    })
  },
})
