/* eslint-disable */

import ApiModel from '../base/ApiModel';
import Country from './Country';

class Domain extends ApiModel {

  static get className() {
    return 'Domain';
  }

  constructor(data = null) {
    super(data);

    this.id = '';
    this.name = '';
    this.common_name = '';
    this.code = '';
    this.extra = '';
    this.is_public = '';

    if (data !== null) {
      this.populate(data);
    }
  }

  static getFindUrl(id) {
    return 'getDomain';
  }

  static get propertiesLabels() {
    return {
      id: 'Id',
      name: 'Nombre',
      code: 'Código',
    }
  }

  static get relatedProps() {
    return {
      country: Country,
    };
  }
}

export default Domain;
