<template>
  <span class="text-secondary">
    <a :href="`mailto:${email}`">
      {{ email }}
    </a>
  </span>
</template>

<script>
export default {
  props: {
    email: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.text-secondary {
  a {
    color: #82868b !important;
  }
}
</style>
