<template>
  <section class="ratings-update-content">
    <b-row
      v-if="rating"
      class="rating-update"
    >
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-overlay
          :show="isOverlayActive"
          rounded="sm"
        >
          <b-card
            class="rating-view-map-card"
            title="Datos"
          >
            <rating-form :form="form" />
          </b-card>
        </b-overlay>
      </b-col>

      <b-col
        cols="12"
        xl="3"
        md="4"
        class="rating-update-actions"
      >
        <b-card>
          <b-button
            variant="primary"
            class="mb-75"
            block
            @click="onSave"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            variant="outline-secondary"
            class="mb-75"
            block
            @click="onBack"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              class="mr-50"
            />
            Volver
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BButton,
} from 'bootstrap-vue'
import overlayDisablerMixin from '@/mixins/overlayDisablerMixin'
import ratingModelMixin from './mixins/ratingModelMixin'
import ratingForm from './ratingForm.vue'
import ratingActions from './mixins/ratingActions'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    ratingForm,
  },
  mixins: [ratingModelMixin, ratingActions, overlayDisablerMixin],
  data() {
    return {
      form: {
        place: {},
        user: {},
        value: 0,
        comment: '',
      },
    }
  },
  methods: {
    onSave() {
      if (this.isAppBusy()) {
        return
      }

      this.disable()

      this.doUpdateRating({
        id: this.rating.id,
        params: this.populateSaveObjectWithForm(),
      })
        .then(() => {
          this.$router.go(-1)

          this.$swal({
            icon: 'success',
            title: '¡Modificada!',
            text: 'La valoración se ha modificado correctamente',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.enable()
        })
    },
    onBack() {
      this.$router.go(-1)
    },
    afterFindRating() {
      this.populateFormWithRating()
    },
    populateFormWithRating() {
      this.form.place = this.rating.place
      this.form.user = this.rating.user
      this.form.value = this.rating.value
      this.form.comment = this.rating.comment
    },
    populateSaveObjectWithForm() {
      return {
        place_id: this.form.place.id,
        user_id: this.form.user.id,
        value: this.form.value,
        comment: this.form.comment,
      }
    },
  },
}
</script>
