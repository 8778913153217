<template>
  <div
    v-if="place"
    class="place-image-gallery"
  >
    <div
      v-for="(image, index) in place.images"
      :key="`image-thumbnail-${image.id}`"
      class="place-image-gallery-item"
      :style="`background-image: url(${place.getImageUrl(image)});`"
      @click="onClickImage(index)"
    />

    <b-modal
      id="place-image-gallery-showroom"
      centered
      modal-class="modal-xl"
      role="document"
      hide-header
      hide-footer
      @shown="onModalShown"
    >
      <swiper
        v-if="modalShown"
        ref="placeImageSwiper"
        :options="swiperOptions"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      >
        <swiper-slide
          v-for="image in place.images"
          :key="`image-full-${image.id}`"
          :style="`background-image: url(${place.getImageUrl(image)});`"
        />

        <div
          slot="button-next"
          class="swiper-button-next"
        />
        <div
          slot="button-prev"
          class="swiper-button-prev"
        />
      </swiper>

      <div
        class="modal-button-close bg-primary"
        @click="onClickClose"
      >
        <feather-icon
          size="25"
          icon="XIcon"
        />
      </div>

      <div
        class="modal-button-download bg-success"
        @click="onClickDownload"
      >
        <feather-icon
          size="25"
          icon="DownloadIcon"
        />
      </div>
    </b-modal>
  </div>
</template>
<script>
// Models
import Place from '@nollaSdk/models/Place'
import {
  BModal,
} from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  components: {
    BModal,
    Swiper,
    SwiperSlide,
  },
  props: {
    place: Place,
  },
  data() {
    return {
      modalShown: false,
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  methods: {
    onClickImage(index) {
      this.swiperOptions.initialSlide = index
      this.showModal()
    },
    onModalShown() {
      this.modalShown = true
    },
    showModal() {
      this.$bvModal.show('place-image-gallery-showroom')
    },
    hideModal() {
      this.$bvModal.hide('place-image-gallery-showroom')
      this.modalShown = false
    },
    onClickClose() {
      this.hideModal()
    },
    onClickDownload() {
      const currentindex = this.$refs.placeImageSwiper.swiperInstance.realIndex

      window.open(this.place.getImageUrl(this.place.images[currentindex]), '_blank')
    },
  },
}
</script>

<style lang="scss">
.place-image-gallery {
  width: 100%;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;

  .place-image-gallery-item {
    width: 100px;
    height: 100px;
    overflow: hidden;
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    float: left;
    background-position: center;
    margin: 5px 5px 0 5px;
    cursor: pointer;
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.1);
    }

    @media (max-width: 768px) {
      width: 70px;
      height: 70px;
    }
  }
}

#place-image-gallery-showroom {
  --swiper-navigation-color: #fff;

  padding-left: 0 !important;
  max-width: 100%;
  margin: 0;
  width: 100%;

  & ~ .modal-backdrop {
    opacity: unset;
  }

  .modal-button-close {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    position: absolute;
    right: 35px;
    top: 25px;
    background-color: var(--var-primary-color);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
  }

  .modal-button-download {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    position: absolute;
    right: 35px;
    bottom: 25px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
  }

  .modal-dialog {
    width: 100%;
    max-width: 100%;
    margin: 0;

    .modal-header {
      border-bottom: unset;

      button {
        color: #fff;
        opacity: unset;
      }
    }

    .modal-content {
      background-color: unset;
      border: 0;
      border-radius: 0;
      height: 100vh;
    }
  }

  .swiper-container {
    width: 100%;
    height: 100%;

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      background-color: #000;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      img {
        width: 100%;
      }
    }

    .swiper-button-prev {
      background-image: url('~@/assets/images/nolla/nolla_icon_right_white.png') !important;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      transform: scaleX(-1);

      &::after {
        content: unset;
      }
    }

    .swiper-button-next {
      background-image: url('~@/assets/images/nolla/nolla_icon_right_white.png') !important;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      &::after {
        content: unset;
      }
    }
  }

  .place-image-gallery-showroom-toolbar {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(
      black 0%,
      rgba(0, 0, 0, 0.65) 15%,
      rgba(0, 0, 0, 0) 100%
    );
    z-index: 100;

    .place-image-gallery-showroom-toolbar-content {
      height: 80px;
      display: flex;

      img {
        padding: 20px;
        width: 25px;
      }

      .place-image-gallery-showroom-back {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
