<template>
  <layout-horizontal>
    <template v-slot:navbar>
      <app-navbar-horizontal-layout-brand />
      <app-navbar-horizontal-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <template v-slot:breadcrumb>
      <app-breadcrumb />
    </template>

    <router-view />

    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />

    <template v-slot:footer>
      <app-footer />
    </template>
  </layout-horizontal>

</template>

<script>
import LayoutHorizontal from '@core/layouts/layout-horizontal/LayoutHorizontal.vue'
import AppNavbarHorizontalLayout from '@/layouts/components/app-navbar/AppNavbarHorizontalLayout.vue'
import AppNavbarHorizontalLayoutBrand from '@/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue'
import AppBreadcrumb from '@/layouts/components/AppBreadcrumb.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import AppFooter from '@/layouts/components/AppFooter.vue'
import { $themeConfig } from '@themeConfig'
import useAppConfig from '@core/app-config/useAppConfig'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'

export default {
  components: {
    LayoutHorizontal,
    AppBreadcrumb,
    AppCustomizer,
    AppFooter,
    AppNavbarHorizontalLayout,
    AppNavbarHorizontalLayoutBrand,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
  setup() {
    const {
      navbarType, footerType,
    } = useAppConfig()

    const {
      toggleVerticalMenuActive,
    } = useVerticalLayout(navbarType, footerType)

    return {
      toggleVerticalMenuActive,
    }
  },
}
</script>
