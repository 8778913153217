/* ============
 * Vuex Store
 * ============
 *
 * The store of the application.
 *
 * http://vuex.vuejs.org/en/index.html
 */

import account from './modules/account'
import common from './modules/common'
import contact from './modules/contact'
import country from './modules/country'
import domain from './modules/domain'
import place from './modules/place'
import policy from './modules/policy'
import pollable from './modules/pollable'
import rating from './modules/rating'
import signature from './modules/signature'
import user from './modules/user'
import userDevice from './modules/userDevice'
import userUnsubscription from './modules/userUnsubscription'

import { CLEAR_STATE } from './core/mutation-types'

const modules = {
  account,
  common,
  contact,
  country,
  domain,
  place,
  policy,
  pollable,
  rating,
  signature,
  user,
  userDevice,
  userUnsubscription,
}

export default {
  namespaced: true,
  strict: false,
  modules,
  actions: {
    clear({ commit }) {
      modules.forEach(module => {
        commit(`${module}/${CLEAR_STATE}`)
      })
    },
  },
}
