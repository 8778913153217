<template>
  <v-select
    id="place-search-form-typology"
    v-model="innerValue"
    name="place-search-form-typology"
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    :multiple="multiple"
    label="name"
    :options="items"
    :reduce="item => item.id"
    :placeholder="placeholder"
    autocomplete="chrome-off"
  />
</template>

<script>
import Category from '@nollaSdk/models/Category'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  $_veeValidate: {
    name() {
      return this.name
    },
    value() {
      return this.value
    },
  },
  props: {
    value: {
      type: [Array, String],
      default: () => [],
    },
    error: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      innerValue: '',
      items: [],
      control: false,
    }
  },
  watch: {
    innerValue() {
      if (!this.control) {
        this.$emit('input', this.innerValue)
      }
      this.control = false
    },
    value() {
      this.control = true
      this.innerValue = this.value
    },
  },
  mounted() {
    this.findItems()
    this.innerValue = this.value
  },
  methods: {
    findItems() {
      Category.findAll('/place-typology')
        .then(models => {
          this.items = models
        })
    },
  },
}
</script>
