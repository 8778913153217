/* eslint-disable */

import DocumentApiModel from '../base/DocumentApiModel';

class UserProfileImage extends DocumentApiModel {

  static get className() {
    return 'ReportDocument';
  }

  static createUrl(fileableId) {
    return ['postUserProfileImage', { id: fileableId }]
  }

  static getFindUrl(fileableId, id) {
    return ['getUserProfileImage', { id: fileableId }];
  }

  static downloadUrl(fileableId, id) {
    return ['getUserProfileImageDownload', { id: fileableId }];
  }

  getDataUrl() {
    return `data:${this.mime_type};base64,${this.binaries}`;
  }
}

export default UserProfileImage;
