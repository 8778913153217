/* eslint-disable */

import ActivableComponent from './ActivableComponent';
import VueComponent from './VueComponent';

class Disabler extends VueComponent {
  static get className() {
    return 'Disabler';
  }

  static get EVENT_DISABLE() {
    return 'disabled';
  }

  static get EVENT_ENABLE() {
    return 'enabled';
  }

  /**
   * Create a NetworkHandler object.
   *
   * @param {array} vue - Sets the vue instance that interacts with the component.
   */
  constructor(vue) {
    super(vue);

    this.activable = new ActivableComponent(false);
    this.disableCount = 0;
    this.spinner = true;
    this.disabled = false;

    this.activable.on(ActivableComponent.EVENT_ACTIVATED, () => {
      this.disabled = true;
      if (this.spinner === true) {
        // this.vue.$spinner.show();
      }
      this.trigger(this.constructor.EVENT_DISABLE);
    });

    this.activable.on(ActivableComponent.EVENT_DEACTIVATED, () => {
      this.disabled = false;
      this.disableCount = 0;
      // this.vue.$spinner.hide();
      this.trigger(this.constructor.EVENT_ENABLE);
    });
  }

  disable(n = 1, spinner = true) {
    this.disableCount += n;
    this.spinner = spinner;
    this.activate().shouldDeactivate(15000);

    return this;
  }

  enable(n = 1) {
    this.disableCount -= n;
    if (this.disableCount <= 0) {
      this.deactivate();
    }

    return this;
  }

  forceEnable() {
    return this.enable(this.disableCount);
  }

  activate() {
    this.activable.activate();
    return this;
  }

  deactivate() {
    this.activable.deactivate();
    return this;
  }

  isActive() {
    return this.activable.isActive();
  }

  shouldDeactivate(miliseconds = 1000) {
    this.activable.shouldDeactivate(miliseconds);
    return this;
  }
}

export default Disabler
